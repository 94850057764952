import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import styles from './TextArea.module.scss'

interface TextAreaProps {
  id: string
  label?: string
  name: string
  value?: string
  onChange: (value: string) => void
  placeholder: string
  disabled?: boolean
  rows?: number
  hasError?: boolean
  infoText?: string
}

const TextArea: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<TextAreaProps>>
  >
> = (props: TextAreaProps) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (props.value) {
      setValue(props?.value)
    }
  }, [props.value])

  const disabledClass = classNames({
    [styles.disabled]: props.disabled,
  })

  const inputClasses = classNames({
    [styles.hasError]: props.hasError,
  })

  return (
    <div className={styles.container}>
      <div className="df fd">
        {props.label && (
          <label htmlFor={props.id} className={disabledClass}>
            {props.label}
          </label>
        )}
        {props.infoText ? (
          <>
            <span
              data-tip
              data-for="registerTipTextarea"
              className={styles.infoIcon}
            >
              !
            </span>
            <ReactTooltip
              id="registerTipTextarea"
              place="top"
              effect="solid"
              className={styles.tooltip}
              arrowColor={'#a5d444'}
            >
              {props.infoText}
            </ReactTooltip>
          </>
        ) : (
          ''
        )}
      </div>

      <fieldset className={inputClasses}>
        <textarea
          id={props.id}
          onInput={(e) => props.onChange(e.currentTarget.value)}
          placeholder={props.placeholder}
          defaultValue={value}
          disabled={props.disabled}
          rows={props.rows}
        />
        <div className={styles.errorIcon}>!</div>
        <div className={styles.errorText}>გთხოვთ შეავსოთ ველი</div>
      </fieldset>
    </div>
  )
}

TextArea.defaultProps = {
  disabled: false,
  rows: 4,
}

export default TextArea
