import { Button, Modal } from '@components/ui'
import { Delivery, PickUpStore } from '@components/ui/Icon/Icon'
import { UseTranslate } from 'hooks/Translate'
import React, { FC, useContext, useEffect, useState } from 'react'
import { FiMapPin } from 'react-icons/fi'
import { AddAddressButton, AddressEditor, AddressList } from '..'

import styles from './AddressSelector.module.scss'
import AppContext from '../../contexts/AppContext'
import { isMobile } from 'react-device-detect'
import RequestHelper from '@utils/RequestHelper'
import UserContext from '../../contexts/UserContext'
import { useUser } from '@auth0/nextjs-auth0'
import tagManagerUtil from '@utils/tagManagerUtil'
import { UniCat, getCategoryLevels } from '@utils/categoryUtils'
import CategoryContext from 'contexts/CategoryContext'
import { useRouter } from 'next/router'

interface AddressSelectorProps {
  display?: string
  checkoutPage?: boolean
}

enum TitleType {
  HOME = 1,
  WORK = 2,
  OTHER = 3,
}

const AddressSelector: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<AddressSelectorProps>>
  >
> = (props: AddressSelectorProps) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  // const [address, setAddress] = useState<Record<string, combined>>({})
  const [addressForm, setAddressForm] = useState<Record<string, combined>>({})
  const [initialStateChange, setInitialStateChange] = useState(false)
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showAddressListModal, setShowAddressListModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { user } = useUser()
  const {
    selectedLanguage,
    cart,
    openAddressModal,
    setOpenAddressModal,
    address,
    setAddress,
    setAddressType,
    addressType,
    deliveryServiceConfig,
    isInactiveAddress,
  } = useContext(AppContext)
  const { display = 'flex' } = props

  const {
    addresses,
    loadAddresses,
    addressToEdit,
    showAddAddressModal,
    setShowAddAddressModal,
    selectedAddress,
    setSelectedAddress,
    setAddressToEdit,
  } = useContext(UserContext)

  const [showTabs, setShowTabs] = useState(false)
  const [fullContent, setFullContent] = useState('')

  const [translate] = UseTranslate()

  const { categoriesData } = useContext(CategoryContext)
  const [categories, setCategories] = useState<UniCat[]>([])
  const [isSelectedAddressInactive, setAddressInactive] =
    useState<boolean>(false)
  const [inactiveAddressMessage, setInactiveAddressMessage] =
    useState<string>('')

  useEffect(() => {
    setCategories(categoriesData as UniCat[])
  }, [categoriesData])

  const deliveryTypeIcon: Record<string, combined> = {
    pickup: <PickUpStore />,
    delivery: <Delivery />,
  }

  useEffect(() => {
    setAddress(selectedAddress)
    const { inactive, msg, details } = isInactiveAddress(
      selectedAddress,
      deliveryServiceConfig,
      false
    )
    setAddressInactive(inactive)

    if (inactive) {
      setInactiveAddressMessage(msg)
    }
  }, [selectedAddress])
  useEffect(() => {
    if (showAddAddressModal) {
      setShowTabs(true)
      setShowAddressListModal(false)
      setShowAddressModal(true)
    }
  }, [showAddAddressModal])

  useEffect(() => {
    if (addressToEdit) {
      setShowTabs(false)
      setAddressForm(addressToEdit)
      setShowAddressListModal(false)
      setShowAddressModal(true)
    }
  }, [addressToEdit])

  useEffect(() => {
    setShowAddressListModal(openAddressModal)
  }, [openAddressModal])

  useEffect(() => {
    if (showAddressListModal) {
      if (!addresses.find((a) => a.type === addressType)) {
        setShowAddressListModal(false)
        setShowAddressModal(true)
      }
    }
  }, [addressType])

  useEffect(() => {
    if (address) {
      if (address.comment && !isMobile) {
        setFullContent(address.address?.title + ', ' + address.comment)
      } else {
        setFullContent(address.address?.title)
      }
    }
  }, [address])

  const estimateDeliveryTime = () => {
    if (selectedAddress?.type) {
      const deliveryTime = selectedAddress?.warehouseProcessingTime || 0
      if (selectedAddress?.type === 'pickup') {
        return (
          deliveryServiceConfig?.pickup?.retail?.config?.processingTime || 0
        )
      } else {
        return deliveryTime + (selectedAddress?.deliveryTime || 0)
      }
    }
  }

  const addAddress = async () => {
    setErrorMessage('')
    //let exists = false
    // removing this check
    /*addresses.forEach((adr) => {
      if (
        adr &&
        adr.address &&
        adr.address?.id == addressForm.values.address.id
      ) {
        exists = true
        return
      }
    })

    if (exists) {
      setErrorMessage(translate('ecommerce.address.already.exists'))
      //return
    }*/
    const formValues = addressForm.values
    if (!formValues?.addressName) {
      formValues.addressName = translate('ecommerce.otherValue')
      formValues.address.titleType = TitleType.OTHER
    } else {
      switch (formValues.place) {
        case translate('ecommerce.homeValue'): {
          formValues.address.titleType = TitleType.HOME
          break
        }
        case translate('ecommerce.workValue'): {
          formValues.address.titleType = TitleType.WORK
          break
        }
        default: {
          formValues.address.titleType = TitleType.OTHER
          break
        }
      }
    }
    if (formValues && formValues.address && formValues.address.warehouseId) {
      formValues.warehouseId = formValues.address.warehouseId
    }
    if (formValues._id) {
      const res = await RequestHelper.catalog.put(
        `userAddresses/${formValues._id}`,
        {
          ...formValues,
        }
      )
      if (res.data.status === 'SUCCESS') {
        loadAddresses(user?.sub)
        setShowAddressModal(false)
        setOpenAddressModal(false)
        setShowAddAddressModal(false)
        setAddressToEdit(null)
      } else {
        setErrorMessage(res.data.message)
      }
    } else {
      const res = await RequestHelper.catalog.post(`userAddresses`, {
        ...formValues,
        userId: user?.sub,
      })

      if (res.data.status === 'SUCCESS') {
        const result: Address = res.data.data
        setSelectedAddress(result)
        setShowAddressModal(false)
        setOpenAddressModal(false)
        setShowAddAddressModal(false)
        const productsForTagManager = []
        for (const product of cart?.products) {
          const categoryLevels = getCategoryLevels(
            product.categoryId,
            categories,
            lang.toString()
          )
          product.categoryLevels = categoryLevels
          productsForTagManager.push(product)
        }
        tagManagerUtil.AddAdress({
          UserAdress: formValues.addressName,
          UserID: user?.sub,
          AdressName: formValues.address.title,
          CartItems: productsForTagManager,
        })
      } else {
        setErrorMessage(res.data.message)
      }
    }
  }

  const showAddressHelper = () => {
    if (addresses.length) {
      setShowAddressListModal(true)
    } else {
      setShowTabs(true)
      setShowAddressModal(true)
    }
  }

  const showAddress = async () => {
    if (!user) {
      window.open(
        `/api/auth/login?lang=${selectedLanguage?.key || 'ge'}`,
        '_self'
      )
    } else {
      showAddressHelper()
    }
  }

  return (
    <>
      <div
        style={{ display }}
        className={styles.container}
        onClick={() => showAddress()}
      >
        {!props.checkoutPage && (
          <div className={styles.icon}>
            {address ? deliveryTypeIcon[address.type] : <FiMapPin />}
          </div>
        )}
        <div className={styles.addressInfo}>
          <div
            className={[styles.addressName]
              .concat(props.checkoutPage ? [styles.fullAdressName] : [])
              .join(' ')}
          >
            {address ? (
              <>
                <span className="pSMBold">
                  {address.type === 'delivery'
                    ? `${address.addressName}, `
                    : ''}
                </span>

                <span>
                  {props.checkoutPage ? fullContent : address.address?.title}
                </span>
              </>
            ) : (
              <>{translate('ecommerce.address.addressName')}</>
            )}
          </div>
          {!props.checkoutPage && (
            <div className={styles.deliveryTime}>
              {!isMobile ? (
                <span>
                  {selectedAddress ? (
                    <>
                      {isSelectedAddressInactive ? (
                        <div className={styles.inactiveAddress}>
                          {translate(inactiveAddressMessage)}
                        </div>
                      ) : (
                        <>
                          {selectedAddress?.type === 'pickup'
                            ? translate('ecommerce.address.estimatePickupTime')
                            : translate('ecommerce.address.estimateDelivery')}
                          : {estimateDeliveryTime()}{' '}
                          {(selectedAddress &&
                            translate('ecommerce.address.minute')) ||
                            translate('ecommerce.address.time')}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              ) : (
                <>
                  {selectedAddress && (
                    <>
                      {translate('ecommerce.deliveryType')}:{' '}
                      {isSelectedAddressInactive ? (
                        <>
                          <span className={styles.deliveryType}>
                            {selectedAddress?.type === 'pickup'
                              ? translate('ecommerce.pickup')
                              : translate('ecommerce.delivery')}
                          </span>
                          <div className={styles.inactiveAddress}>
                            {translate(inactiveAddressMessage)}
                          </div>
                        </>
                      ) : (
                        <span className={styles.deliveryType}>
                          {selectedAddress?.type === 'pickup'
                            ? translate('ecommerce.pickup')
                            : translate('ecommerce.delivery')}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {!isSelectedAddressInactive ? (
          <div className={styles.mobileDeliveryTime}>
            {estimateDeliveryTime()}{' '}
            {selectedAddress ? translate('ecommerce.address.minute') : <></>}
          </div>
        ) : (
          <></>
        )}
      </div>

      <Modal
        visible={showAddressListModal}
        onClose={() => {
          setShowAddressListModal(false)
          setOpenAddressModal(false)
          setShowAddAddressModal(false)
        }}
        stretch={true}
        title={translate('ecommerce.chooseAddress')}
        content={
          <AddressList
            addresses={addresses}
            onEdit={(address) => {
              setShowTabs(false)
              setAddressForm(address)
              setShowAddressListModal(false)
              setShowAddressModal(true)
            }}
            visible={showAddressListModal}
            onChangeType={setAddressType}
            onChangeSelected={() => {
              setShowAddressListModal(false)
              setOpenAddressModal(false)
              setShowAddAddressModal(false)
            }}
          />
        }
        footer={
          <AddAddressButton
            onClick={() => {
              setErrorMessage('')
              setShowTabs(true)
              setShowAddressListModal(false)
              setShowAddressModal(true)
            }}
          />
        }
      />

      <Modal
        visible={showAddressModal}
        onClose={() => {
          setOpenAddressModal(false)
          setShowAddressModal(false)
          setShowAddAddressModal(false)
        }}
        stretch={true}
        onBack={
          addresses.length
            ? () => {
                setShowAddressModal(false)
                setShowAddressListModal(true)
              }
            : null
        }
        title={translate(
          addressForm.values?._id
            ? 'ecommerce.editAddress'
            : 'ecommerce.addAddress'
        )}
        content={
          <AddressEditor
            form={addressForm}
            onReady={() => {
              setInitialStateChange(false)
            }}
            statusChange={(status) => {
              setAddressForm(status)
              setInitialStateChange(true)
            }}
            showTabs={showTabs}
            errorText={errorMessage}
          />
        }
        footer={
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <div>
              <Button
                disabled={!addressForm?.valid || !initialStateChange}
                onClick={() => addAddress()}
                text={translate(
                  addressForm.values?._id
                    ? 'ecommerce.changeAddress'
                    : 'ecommerce.addAddress'
                )}
                type="primary"
              />
            </div>
          </div>
        }
      />
    </>
  )
}

export default AddressSelector
