import React, { useContext } from 'react'

import { UseTranslate } from 'hooks/Translate'

import styles from './OrderListEmpty.module.scss'
import { FiShoppingBag } from 'react-icons/fi'
import { Button } from '@components/ui'
import { useRouter } from 'next/router'
import AppContext from 'contexts/AppContext'

const OrderListEmpty = () => {
  const router = useRouter()
  const [translate] = UseTranslate()
  const { selectedLanguage } = useContext(AppContext);

  const navigateToHome = () => {
    router.push(`/${selectedLanguage.key}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <FiShoppingBag />
      </div>
      <div className={styles.title}>{translate('shop.orders.ordersEmpty')}</div>
      <div className={styles.description}>
        {translate('shop.orders.ordersEmptyDescription')}
      </div>
      <Button
        size={330}
        onClick={() => navigateToHome()}
        text={translate('shop.checkout.chooseProduct')}
      />
    </div>
  )
}

export default OrderListEmpty
