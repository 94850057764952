import React, { useContext, useEffect, useRef } from 'react'
import cn from 'classnames'
import { CgTrashEmpty } from 'react-icons/cg'

import styles from './Quantity.module.scss'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { useRouter } from 'next/router'
import AppContext from 'contexts/AppContext'
import UserContext from 'contexts/UserContext'
import ToastContext from 'contexts/ToastContext'
import { UseTranslate } from 'hooks/Translate'
import { useUser } from '@auth0/nextjs-auth0'
import { useState } from 'react'
import tagManagerUtil from '@utils/tagManagerUtil'
import { UniCat, getCategoryLevels } from '@utils/categoryUtils'
import CategoryContext from 'contexts/CategoryContext'

type Props = {
  shake?: HTMLDivElement | null
  productId: string
  hideDelete?: boolean
  id: string
  title: string
  cnt?: number
  readonly?: boolean
  countStep: number
  quantity?: number
  additional?: boolean
  price: number
  category?: string
}

export default function Quantity(props: Props) {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const { addToast } = useContext(ToastContext)
  const [translate] = UseTranslate()
  const { cartTotals, cart, addProductToCart, setCart, additionalSum, setAdditionalSum, isInactiveAddress, deliveryServiceConfig, setShowInactiveAddressModal } = useContext(AppContext)
  const { user } = useUser()
  const { selectedAddress, customerInfo } = useContext(UserContext)
  const { categoriesData } = useContext(CategoryContext)
  const [loadingCart, setLoadingCart] = useState(false)
  const [categories, setCategories] = useState<UniCat[]>([])

  const shakeRef = useRef<HTMLDivElement>(null)

  const quantityClassName = cn({
    [styles.quantity]: true,
    [styles.disable]: loadingCart,
  })

  useEffect(() => {
    setCategories(categoriesData as UniCat[])
  }, [categoriesData])

  async function decrease() {
    if (props.additional) {
      const prod = cart.products.find((product) => {
        return product.productId == props.productId;
      })
      if (prod.quantity == 1) return;
      prod.quantity -= 1;
      setAdditionalSum(additionalSum - prod.price)
      setCart(cart, customerInfo?.clubMembership?.current?.isMember);
    } else {
      setLoadingCart(true)
      const qnt = cartTotals.productToCountMap[props.productId]
      const result = await addProductToCart(
        cart?._id,
        props.productId,
        props.id,
        'REMOVE',
        lang,
        qnt,
        user?.sub,
        selectedAddress?.warehouseId,
        customerInfo?.clubMembership?.current?.isMember,
        props.countStep,
        cartTotals
      )
      setLoadingCart(false)
      const categoryLevels = getCategoryLevels(props.category, categories, lang.toString());
      tagManagerUtil.RemoveFromCart({
        ProductName: props.title,
        ProductID: props.productId,
        UserID: user?.sub,
        ProductPrice: props.price,
        ProductCategory: props.category,
        Quantity: props.countStep || 1,
        categoryLevels
      })
    }
  }

  async function increase() {
    const { inactive, msg, details } = isInactiveAddress(selectedAddress, deliveryServiceConfig, true);

    if (inactive) {
      setShowInactiveAddressModal(inactive, msg, details)
      return;
    }
    const prod = cart.products.find((product) => {
      return product.productId == props.productId || product.prodId == props.productId;
    })
    if (props.additional) {
      prod.quantity += 1;
      setAdditionalSum(additionalSum + prod.price)
      setCart(cart, customerInfo?.clubMembership?.current?.isMember);
    } else {
      const shake = props.shake || shakeRef.current
      setLoadingCart(true)
      const qnt = cartTotals.productToCountMap[props.productId]
      if (prod && typeof prod.maxAvailable !== 'undefined' && prod.maxAvailable != null && qnt + (props.countStep || 1) > prod.maxAvailable) {
        shake.classList.add('shake')
        addToast({
          id: cart?._id?.toString() || 'addproducttocart',
          description: translate('shop.notInStockMessage'),
          type: 'info',
        })
        window.setTimeout(function () {
          shake.classList.remove('shake')
        }, 820)
      } else {
        const result = await addProductToCart(
          cart?._id,
          props.productId,
          props.id,
          'ADD',
          lang,
          qnt,
          user?.sub,
          selectedAddress?.warehouseId,
          customerInfo?.clubMembership?.current?.isMember,
          props.countStep,
          cartTotals
        )

        if (result) {
          const categoryLevels = getCategoryLevels(props.category, categories, lang.toString());
          tagManagerUtil.AddtoCart({
            ProductName: props.title,
            ProductID: props.productId,
            UserID: user?.sub,
            ProductPrice: props.price,
            ProductCategory: props.category,
            Quantity: props.countStep,
            categoryLevels
          })
        } else if (shake) {
          shake.classList.add('shake')
          addToast({
            id: cart?._id?.toString() || 'addproducttocart',
            description: translate('shop.notInStockMessage'),
            type: 'info',
          })
          window.setTimeout(function () {
            shake.classList.remove('shake')
          }, 820)
        }
      }
      setLoadingCart(false)
    }
  }

  const quantity = props.quantity || cartTotals.productToCountMap[props.productId];

  return (
    <div ref={shakeRef}>
      <span className={quantityClassName} id={props.id}>
        {props.readonly ? (
          <></>
        ) : quantity && quantity < (props.countStep || 1) && quantity > 0 ? (
          props.hideDelete ? (
            <FiMinus
              size="22px"
              color="#a3a3a3"
              style={{ cursor: 'default' }}
            />
          ) : (
            <CgTrashEmpty
              size="22px"
              color="#007A3E"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                if (!loadingCart) {
                  decrease()
                }
              }}
            />
          )
        ) : quantity > 0 ? (
          <FiMinus size="22px" color="#007A3E" onClick={decrease} />
        ) : (
          <></>
        )}
        {quantity ? <span>{Math.round((props.cnt || quantity) * 100) / 100}</span> : <></>}

        {props.readonly ? (
          <></>
        ) : quantity > 0 ? (
          <FiPlus
            size="28px"
            color="#007A3E"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              if (!loadingCart) {
                increase()
              }
            }}
          />
        ) : (
          <>
            <span>
              <img
                src={`/svgs/add-to-basket.svg`}
                className={styles.quantityAddToCart}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  if (!loadingCart) {
                    increase()
                  }
                }}
                alt={translate('shop.addToCart')}
              />
            </span>
          </>
        )}
      </span>
    </div>
  )
}
