import { UserManager, WebStorageStateStore, User } from 'oidc-client'
import RequestHelper from '@utils/RequestHelper'

const NEXT_PUBLIC_OIDC_AUTHORITY = process.env.NEXT_PUBLIC_OIDC_AUTHORITY
const NEXT_PUBLIC_OIDC_REDIRECT_URI = process.env.NEXT_PUBLIC_OIDC_REDIRECT_URI
const url = typeof location !== 'undefined' ? location.origin : ''
const clientId = 'shop'

const screenHeightWidth = 500
let left = 50,
  top = 50
if (typeof screen !== 'undefined') {
  left = (screen.width - screenHeightWidth) / 2
  top = (screen.height - screenHeightWidth) / 4
}

const IDENTITY_CONFIG = {
  authority: NEXT_PUBLIC_OIDC_AUTHORITY,
  client_id: clientId,
  client_secret: 'eshbiy79',
  redirect_uri: NEXT_PUBLIC_OIDC_REDIRECT_URI,
  post_logout_redirect_uri: url,
  response_type: 'code',
  audience: clientId,
  scope: 'openid UserProfile profile scope',
  popup_redirect_uri: url,
  popup_post_logout_redirect_uri: url,
  silent_redirect_uri: url,
  automaticSilentRenew: false,
  validateSubOnSilentRenew: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  monitorSession: false,
  checkSessionInterval: 0,
  popupWindowFeatures: `location=no,toolbar=no,width=500,height=500,left=${left},top=${top},position: absolute`,
}

export default class AuthService {
  UserManager: UserManager | undefined

  constructor() {
    if (typeof localStorage === 'undefined') {
      return
    }
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: localStorage }),
    })

    this.UserManager ?.signinCallback().catch(function () { }) // eslint-disable-line
  }

  signinSilent = async (): Promise<User | undefined> => {
    let user
    try {
      user = await this.UserManager ?.signinSilent()
    } catch (err) {
      if (
        err &&
        err.toString() ===
        'ErrorResponse: client not authorized for End-User session yet'
      ) {
        return this.signinSilent()
      }
      if (err && err.toString() === 'Error: invalid_grant') {
        const sessionKey = `oidc.user:https://${process.env.NEXT_PUBLIC_CAS_API_ISSUER}:shop`
        localStorage.removeItem(sessionKey)
        return this.signinSilent()
      }
    } // eslint-disable-line

    return user
  }

  // callLogoutFromProvider(data: User): void {
  //   const url = new URL(
  //     `https://${process.env.NEXT_PUBLIC_CAS_API_ISSUER}/v2/logout`
  //   )
  //   url.searchParams.append('post_logout_redirect_uri', location.origin)
  //   url.searchParams.append('id_token_hint', data.id_token)
  //   url.searchParams.append('client_id', 'shop')
  //   this.UserManager?.signoutCallback().then(() => {
  //     this.UserManager?.removeUser().then(() => {
  //       window.location.href = url.href
  //     })
  //   })
  // }

  // signOut(): void {
  //   this.UserManager?.getUser().then((data) => {
  //     if (data) {
  //       axios
  //         .delete(
  //           `https://${process.env.NEXT_PUBLIC_CAS_API_ISSUER}/cas/api/users/session/invalidateSession?sessionUUID=${data.profile.sessionUUID}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${data.id_token}`,
  //             },
  //           }
  //         )
  //         .then(() => {
  //           this.callLogoutFromProvider(data)
  //         })
  //         .catch(() => {
  //           this.callLogoutFromProvider(data)
  //         })
  //     }
  //   })
  // }

  popupSignin = async (url?: string): Promise<User | undefined> => {
    try {
      RequestHelper.resetAxiosInstances()
      return await this.UserManager ?.signinPopup({
        state: url || location.href,
      })
    } catch (err) { } // eslint-disable-line
  }

  getUserInfo = async (): Promise<User | null | undefined> => {
    const data = await this.UserManager ?.getUser()
    return data
  }

  checkSession = async (): Promise<User | undefined> => {
    try {
      const data = await this.signinSilent()
      return data
    } catch (err) { } // eslint-disable-line

    return undefined
  }

  isUserAuth = async (): Promise<boolean> => {
    let authorized = false
    try {
      const checkSession = await RequestHelper.cas.get('/users/checkSession')
      authorized = checkSession.data.isActive
    } catch (err) {
      authorized = false
    }

    return authorized
  }
}
