import { ListNode } from "./listToTree"

export type UniCat = Category & ListNode

export function findParentCategories(category: UniCat, categories: UniCat[]): UniCat[] {
  const roots: UniCat[] = []
  while (category?.parent) {
    roots.unshift(category)
    const parent: UniCat[] = categories.filter((pr: Category) => {
      return pr._id === category.parent
    })
    category = parent[0]
  }
  roots.unshift(category)
  return roots
}

export function getCategoryLevels(categoryId: string, categories: UniCat[], lang: string): Record<string, string> {
  const category: UniCat[] = categories.filter((pr: UniCat) => {
    return pr._id === categoryId
  })

  const categoryLevels = {};
  if (category && category.length) {
    const breadcrumbs = findParentCategories(category[0], categories)
    for (let i = breadcrumbs.length - 1; i >= 0; i--) {
      const keyNum = breadcrumbs.length - i;
      const key = keyNum > 1 ? `item_category${keyNum}` : 'item_category';
      categoryLevels[key] = Object.keys(breadcrumbs[i]?.name || {}).length > 0 && breadcrumbs[i]?.name[lang] ? breadcrumbs[i]?.name[lang] : '';
    }
  }
  return categoryLevels;
}