import React, { useContext, useState } from 'react'
import { useRouter } from 'next/router'
import style from './MobileFooterMenu.module.scss'
import { UseTranslate } from 'hooks/Translate'
import AppContext from '../../../contexts/AppContext'
import { CartWithQuantity } from '@components/ui/Icon/Icon'

export default function MobileFooterMenu() {
  const router = useRouter()
  const [translate] = UseTranslate()
  const { selectedLanguage, cartTotals, setOpenCart } = useContext(AppContext)
  const [searchClass, setSearchClass] = useState('')

  function toggleCartOpen() {
    setOpenCart()
  }

  function openSearch() {
    setSearchClass(style.shopMobileFooterMenuSearch__open)
    window.setTimeout(function () {
      document?.getElementById('mobile-search')?.focus()
    })
  }

  function closeSearch() {
    setSearchClass('')
  }

  function navigateToHome(e: React.MouseEvent) {
    e.preventDefault()
    router.push(`/${selectedLanguage.key}`)
  }

  return (
    <>
      <div className={style.shopMobileFooterMenu}>
        <a href="#" onClick={navigateToHome}>
          <svg width="20px" height="20px" viewBox="0 0 20 20">
            <path d="M18,17.668 L18,6.8 L10,2 L2,6.8 L2,17.668 L6,17.668 L6,14.918 C6,12.708 7.79,10.918 10,10.918 C12.21,10.918 14,12.708 14,14.918 L14,17.668 L18,17.668 L18,17.668 Z M12,19.668 L12,14.918 C12,13.813 11.105,12.918 10,12.918 C8.895,12.918 8,13.813 8,14.918 L8,19.668 L2,19.668 C0.895,19.668 0,18.773 0,17.668 L0,6.8 C0,6.097 0.369,5.446 0.971,5.085 L8.971,0.285 C9.604,-0.095 10.396,-0.095 11.029,0.285 L19.029,5.085 C19.631,5.446 20,6.097 20,6.8 L20,17.668 C20,18.773 19.105,19.668 18,19.668 L12,19.668 L12,19.668 Z" />
          </svg>
        </a>
        <a href="#" onClick={openSearch}>
          <svg width="18px" height="18px" viewBox="0 0 18 18">
            <path d="M8,14.102 C10.144,14.102 12.125,12.959 13.197,11.102 C14.268,9.246 14.268,6.959 13.197,5.102 C12.125,3.247 10.144,2.102 8,2.102 C4.686,2.102 2,4.789 2,8.102 C2,11.416 4.686,14.102 8,14.102 L8,14.102 Z M14.32,13.009 L17.9,16.589 C18.16,16.839 18.264,17.212 18.173,17.561 C18.081,17.911 17.808,18.184 17.458,18.275 C17.108,18.366 16.736,18.262 16.486,18.002 L12.906,14.422 C9.569,17.012 4.794,16.562 2,13.394 C-0.794,10.226 -0.643,5.432 2.343,2.445 C5.33,-0.541 10.123,-0.692 13.292,2.102 C16.459,4.896 16.909,9.671 14.32,13.008 L14.32,13.009 Z" />
          </svg>
        </a>
        <a href="#" onClick={toggleCartOpen}>
          {cartTotals.quantity > 0 ? (
            <div className={style.shopMobileFooterMenuSearch__cartCont}>
              <div className={style.shopMobileFooterMenuSearch__cartWrapper}>
                <CartWithQuantity />
                <div className={style.shopMobileFooterMenuSearch__cartQuantity}>
                  {cartTotals.quantity}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <svg width="18px" height="16px" viewBox="0 0 18 16">
                <path d="M6.969,16 C5.864,16 4.969,15.105 4.969,14 C4.969,12.895 5.864,12 6.969,12 C8.074,12 8.969,12.895 8.969,14 C8.969,15.105 8.074,16 6.969,16 L6.969,16 Z M13.969,16 C12.864,16 11.969,15.105 11.969,14 C11.969,12.895 12.864,12 13.969,12 C15.074,12 15.969,12.895 15.969,14 C15.969,15.105 15.074,16 13.969,16 L13.969,16 Z M0.931,1.923 C0.412,1.906 0,1.481 0,0.961 C0,0.442 0.412,0.017 0.931,0 L2.082,0 C2.984,0 3.764,0.626 3.96,1.506 L5.213,7.148 C5.409,8.028 6.189,8.654 7.091,8.654 L14.603,8.654 L16.045,2.884 L6.7,2.884 C6.186,2.861 5.782,2.437 5.782,1.923 C5.782,1.409 6.186,0.985 6.7,0.962 L16.045,0.962 C16.637,0.962 17.197,1.235 17.561,1.702 C17.926,2.168 18.055,2.777 17.911,3.352 L16.469,9.12 C16.255,9.976 15.486,10.577 14.603,10.577 L7.091,10.577 C5.288,10.577 3.727,9.325 3.336,7.565 L2.082,1.923 L0.931,1.923 L0.931,1.923 Z" />
              </svg>
            </div>
          )}
        </a>
        <a href="#">
          <svg width="16px" height="17px" viewBox="0 0 16 17">
            <path d="M4.853,8.912 C4.03,8.27 3.43,7.39 3.14,6.399 C2.85,5.407 2.881,4.35 3.23,3.376 C3.579,2.402 4.228,1.559 5.088,0.963 C5.948,0.367 6.975,0.049 8.028,0.052 C9.08,0.055 10.105,0.379 10.961,0.98 C11.818,1.58 12.461,2.427 12.805,3.403 C13.148,4.379 13.173,5.436 12.877,6.426 C12.58,7.417 11.977,8.292 11.149,8.931 C11.195,8.954 11.235,8.976 11.276,8.994 C12.362,9.474 13.324,10.189 14.089,11.084 C14.855,11.979 15.405,13.032 15.699,14.164 C15.866,14.786 15.951,15.426 15.951,16.068 C15.961,16.178 15.948,16.29 15.912,16.396 C15.876,16.501 15.819,16.598 15.744,16.681 C15.669,16.764 15.577,16.831 15.474,16.878 C15.371,16.924 15.26,16.95 15.147,16.953 C15.032,16.955 14.917,16.935 14.809,16.893 C14.702,16.851 14.605,16.788 14.522,16.709 C14.44,16.629 14.375,16.534 14.332,16.429 C14.287,16.324 14.265,16.212 14.266,16.099 C14.264,15.105 14.014,14.127 13.538,13.25 C13.108,12.424 12.486,11.707 11.724,11.157 C10.962,10.607 10.08,10.239 9.148,10.082 C7.646,9.781 6.083,10.059 4.785,10.857 C4.008,11.307 3.343,11.919 2.836,12.65 C2.33,13.382 1.996,14.215 1.858,15.088 C1.792,15.465 1.788,15.855 1.741,16.236 C1.713,16.436 1.611,16.619 1.456,16.752 C1.3,16.884 1.1,16.956 0.894,16.954 C0.688,16.96 0.488,16.889 0.332,16.756 C0.177,16.623 0.078,16.437 0.056,16.236 C0.036,15.891 0.047,15.544 0.088,15.201 C0.205,14.133 0.554,13.103 1.112,12.18 C1.935,10.79 3.175,9.686 4.662,9.015 L4.82,8.943 C4.833,8.935 4.84,8.928 4.853,8.913 L4.853,8.912 Z M8.013,1.742 C7.353,1.742 6.707,1.936 6.158,2.298 C5.609,2.659 5.182,3.172 4.931,3.771 C4.679,4.371 4.614,5.031 4.745,5.666 C4.875,6.301 5.195,6.885 5.663,7.342 C6.131,7.8 6.728,8.11 7.376,8.235 C8.025,8.36 8.696,8.294 9.306,8.045 C9.916,7.795 10.437,7.373 10.803,6.833 C11.169,6.293 11.363,5.659 11.362,5.011 C11.352,4.144 10.996,3.315 10.37,2.704 C9.744,2.092 8.897,1.746 8.014,1.742 L8.013,1.742 Z" />
          </svg>
        </a>
      </div>
      <div className={`${searchClass} ${style.shopMobileFooterMenuSearch}`}>
        <div className={style.shopMobileFooterMenuSearch__form}>
          <input
            type="text"
            className="form-control"
            placeholder={translate('shop.searchProductPHMobile')}
            id="mobile-search"
          />
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            onClick={closeSearch}
          >
            <path d="M6.623,5.278 L12,10.656 L17.377,5.278 C17.748,4.908 18.35,4.908 18.722,5.278 C19.092,5.65 19.092,6.252 18.722,6.623 L13.344,12 L18.722,17.377 C19.092,17.748 19.092,18.35 18.722,18.722 C18.35,19.092 17.748,19.092 17.377,18.722 L12,13.344 L6.623,18.722 C6.252,19.092 5.65,19.092 5.278,18.722 C4.908,18.35 4.908,17.748 5.278,17.377 L10.656,12 L5.278,6.623 C4.908,6.252 4.908,5.65 5.278,5.278 C5.65,4.908 6.252,4.908 6.623,5.278 L6.623,5.278 Z"></path>
          </svg>
        </div>
        <div className={style.shopMobileFooterMenuSearch__result}></div>
      </div>
    </>
  )
}
