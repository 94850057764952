import { UseTranslate } from 'hooks/Translate'
import { FC, useContext, useEffect, useState } from 'react'
import styles from './RateCourier.module.scss'
import { Button, TextArea } from '@components/ui'
import OrderActions from './OrderActions'
import CourierTips from './CourierTips'
import UserContext from 'contexts/UserContext'
import ToastContext from 'contexts/ToastContext'
import { logBrazeRateCourier } from '@utils/BrazeDefaultUserDetails'
import { useUser } from '@auth0/nextjs-auth0'

const RateCourier: FC = () => {
  const [translate] = UseTranslate()
  const {
    rateOrderId,
    rateOrderDeliveryType,
    rateOrderTotalAmount,
    rateOrder,
    setRateScreen,
    markOrderReviewRead,
    courierTipVisible,
    orderIsAlreadyReviewed,
    setRateOrderModalVisible
  } = useContext(UserContext)
  const { user } = useUser()
  const { addToast } = useContext(ToastContext)

  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(null)

  const onRateOrder = async () => {
    setLoading(true)
    if (!loading) {
      const rateResponse = await rateOrder(rateOrderId, {
        tipAmount: formValues.courierTip,
        courier: {
          comment: formValues.comment,
          value: formValues.value,
        },
      })
      setLoading(false)
      if (rateResponse.status === 'FAILURE') {
        const id = Math.floor(Math.random() * 101 + 1)
        addToast({
          id: id.toString(),
          description: translate(rateResponse.message),
          dismissTime: 8000,
          type: 'fail',
        })
      } else {
        logBrazeRateCourier(user.userId as string, rateOrderTotalAmount, formValues.value)
        setRateScreen('RateOrder')
      }
    }
  }

  const cancel = () => {
    if (orderIsAlreadyReviewed) {
      setRateOrderModalVisible(null, false, rateOrderDeliveryType)
    } else {
      setRateScreen('RateOrder')
    }
  }

  useEffect(() => {
    markOrderReviewRead(rateOrderId)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={`/images/moped.png`} />
      </div>
      <div className={styles.actionContainer}>
        <OrderActions
          onChange={(value) =>
            setFormValues({
              ...formValues,
              value,
            })
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <TextArea
          id="comment"
          name="comment"
          onChange={(value) =>
            setFormValues({
              ...formValues,
              comment: value,
            })
          }
          placeholder={translate('ecommerce.rateOrder.comment')}
        />
      </div>
      {courierTipVisible ? (
        <CourierTips
          onChange={(tipValue) =>
            setFormValues({
              ...formValues,
              courierTip: tipValue
                ? tipValue.isCustom
                  ? tipValue.customValue
                  : tipValue.value
                : null,
            })
          }
        />
      ) : (
        <></>
      )}
      <div className={styles.footer}>
        <Button
          type="secondary"
          text={translate('ecommerce.rateOrder.cancel')}
          size={'full'}
          showBorder={true}
          style={{ marginLeft: 20, width: '30%' }}
          onClick={cancel}
        />
        <Button
          disabled={formValues && !formValues.value}
          text={translate('ecommerce.rateOrder.continue')}
          size={'full'}
          loading={loading}
          style={{ marginLeft: 10, width: '60%' }}
          onClick={onRateOrder}
        />
      </div>
    </div>
  )
}

export default RateCourier
