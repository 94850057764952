import React, { FC } from 'react'
import Link from 'next/link'
import { BreadArrow } from '@components/ui/Icon/Icon'
import { UseTranslate } from '../../../hooks/Translate'
import style from './Bread.module.scss'

interface BreadProp {
  routes: BreadCrumb[]
  className?: string
}

const Bread: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<BreadProp>>
  >
> = (prop) => {
  const [translate] = UseTranslate()

  const extraBreadcrumbItems = prop.routes.map(
    (row: BreadCrumb, index: number) => {
      const last = prop.routes.length === index + 1
      return last ? (
        <span key={index}>
          {row.translate ? translate(row.breadcrumbName) : row.breadcrumbName}
        </span>
      ) : (
        <span key={index}>
          <Link href={row.path ? `${location.origin}${row.path}` : null}>
            {row.translate ? translate(row.breadcrumbName) : row.breadcrumbName}
          </Link>
          <span style={{ margin: 5 }}> {<BreadArrow />} </span>
        </span>
      )
    }
  )

  return (
    <div className={`${style.breadCrumb} ${prop.className || ''}`}>
      {extraBreadcrumbItems}
    </div>
  )
}
export default Bread
