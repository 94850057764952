import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi'
import Backdrop from '@components/ui/Backdrop/Backdrop'

import cn from 'classnames'

import styles from './Selector.module.scss'
import { UseTranslate } from 'hooks/Translate'

interface SelectorProps {
  id: string
  label?: string
  value?: string
  name: string
  icon?: JSX.Element | null
  arrow?: boolean
  optionsVisible?: boolean
  readonly?: boolean
  placeholder: string
  disabled?: boolean
  onChange?: (value: combined) => void
  children: JSX.Element[]
  leftIcon?: JSX.Element | null
  leftLabel?: string,
  customParentClass?: string
  errorText?: string;
  maxWidth?: boolean
}

const Selector: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SelectorProps>>>> = (props: SelectorProps) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState<combined>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [translate] = UseTranslate()

  const options = cn({
    [styles.options]: true,
    [styles.visible]: visible,
  });

  const inputClasses = cn({
    [styles.input]: true,
    [styles.inputMaxWidth]: props.maxWidth,
    [styles.hasError]: props.errorText,
  })

  const handleClick = (event: Event) => {
    const currentTarget = event.currentTarget as HTMLDivElement
    const valueAttribute = currentTarget.getAttribute('data-value')
    const displayAttribute = currentTarget.getAttribute('data-display')

    if (inputRef.current) {
      inputRef.current.value = displayAttribute as string
    }

    setValue(valueAttribute)
    setVisible(false)
  }

  useEffect(() => {
    if (props.optionsVisible !== undefined) {
      setVisible(props.optionsVisible)
    }
  }, [props.optionsVisible])

  useEffect(() => {
    if (props.onChange && value) {
      props.onChange(value)
    }
  }, [value])

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
      const optionvalue = document
        .getElementById(props.value as string)
        ?.getAttribute('data-display')

      if (inputRef.current) {
        inputRef.current.value = optionvalue as string
      }
    } else {
      setValue(null)
      inputRef.current.value = ''
    }
  }, [props.value])

  return (
    <div className={props.customParentClass ? styles.container + ' ' + props.customParentClass : styles.container}>
      <Backdrop
        transparent={true}
        visible={visible}
        onClick={() => setVisible(!visible)}
      />
      {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
      <div className={inputClasses} onClick={() => setVisible(!visible)}>
        {props.leftIcon ?
          <>
            {props.leftIcon ? <div className={styles.leftIcon}>{props.leftIcon}</div> : null}
            {props.leftLabel ? <span className={styles.leftLabel}>{props.leftLabel}</span> : null}
            {props.icon ? <div className={styles.rightIcon}>{props.icon}</div> : null}
            <input
              type="text"
              style={{ textAlign: 'right', border: 'none', paddingRight: 0, marginLeft: 30, background: '#f8f8f9', width: value ? 40 : 140 }}
              className={props.icon ? styles.inputOnLeftIcon : styles.inputOnLeftIconWhenNoRightIcon}
              id={props.id}
              name={props.name}
              ref={inputRef}
              readOnly={props.readonly}
              placeholder={props.placeholder}
              autoComplete="off"
            />
            <div className={styles.arrowOnLeftLabel}>
              {visible ? <FiChevronUp size={18} /> : <FiChevronRight size={18} />}
            </div>
          </> :
          <>
            {props.icon ? <div className={styles.icon}>{props.icon}</div> : null}
            <input
              type="text"
              style={props.icon ? { paddingLeft: 68, width: '100%' } : { paddingLeft: 20, width: '100%' }}
              id={props.id}
              name={props.name}
              ref={inputRef}
              readOnly={props.readonly}
              placeholder={props.placeholder}
              autoComplete="off"
            />
            <div className={styles.arrow}>
              {visible ? <FiChevronUp /> : <FiChevronDown />}
            </div>
          </>
        }

        <div className={options} onClick={(event) => event.stopPropagation()}>
          {React.Children.map(props.children, (child) => {
            return React.cloneElement(child as ReactElement, {
              onClick: handleClick,
            })
          })}
        </div>
      </div>
      {props.errorText && (
        <div className={styles.errorText}>{translate(props.errorText)}</div>
      )}
    </div>
  )
}

export default Selector
