import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'classnames'
import style from './ProductCategory.module.scss'
import { ListNode } from 'utils/listToTree'
import {
  CategoryMinus,
  CategoryPlus,
  ProductCardImagePlaceholder,
} from '@components/ui/Icon/Icon'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getCategoryImageUrl } from '@utils/getImage'

type Props = ListNode &
  Category & {
    expanded: boolean
    selected?: Category
    onCateGoryClick: () => void
  }

export const ProductCategory = (props: Props) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setExpanded(props.expanded)
  }, [props.selected])

  const productCategory = cn({
    [style.truncated]: expanded,
    [style.shopProductCategory]: true,
  })

  const mainCategory = cn({
    [style.active]: props.expanded,
    [style.truncate]: !expanded,
    [style.shopProductCategory__mainCategory]: true,
  })

  const subCategories = cn({
    [style.subCategories__hidden]: !expanded,
    [style.subCategories]: true,
  })

  const toggle = () => {
    setExpanded(!expanded)
  }

  const onCatClick = () => {
    props.onCateGoryClick()
    if (!expanded) {
      setExpanded(!expanded)
    }
  }

  return (
    <>
      <div className={productCategory}>
        <div className={style.shopProductCategory__item}>
          {props.images[0] ? (
            <img
              src={getCategoryImageUrl(props)}
              className={style.shopProductCategory__image}
              onError={getBrokenImage}
              alt={props.name?.toString()}
            />
          ) : (
            <ProductCardImagePlaceholder />
          )}
          <div className={style.shopProductCategory__inner}>
            <div className={mainCategory} onClick={onCatClick}>
              <div
                className={style.mainCategory__text}
              >
                <span className={style.like_h2}><Link href={`/${lang}/category/${props.nameSlug}`}>{props.name}</Link></span>
              </div>
            </div>
          </div>
          <div className={style.shopProductCategory__plus} onClick={toggle}>
            {expanded ? <CategoryMinus /> : <CategoryPlus />}
          </div>
        </div>
        <div className={subCategories}>
          {props.children.map((c, i) => (
            <div
              onClick={props.onCateGoryClick}
              className={style.shopProductCategory__subCategory}
              data-active={c._id === props.selected?._id}
              key={i}
            >
              <span className={style.like_h3}>
                <Link href={`/${lang}/category/${c.nameSlug}`}>{c.name}</Link>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ProductCategory
