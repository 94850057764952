import { FC, useContext, useEffect, useState } from 'react'

import styles from './CourierTips.module.scss'
import CourierTip from './CourierTip'
import { UseTranslate } from 'hooks/Translate'
import AppContext from 'contexts/AppContext'
import { Button, TextInput } from '@components/ui'
import cn from 'classnames'
import { BiSave, BiCheck } from 'react-icons/bi'
import NumberInput from '@components/ui/NumberInput/NumberInput'
interface ICourierTips {
  onChange: (value) => void
}

interface ICourierTip {
  value: number;
  title: string;
  isCustom: boolean;
  customValue?: number;
}

const CourierTips: FC<ICourierTips> = ({ onChange }) => {
  const [translate] = UseTranslate()
  const [selectedTip, setSelectedTip] = useState(null)
  const { courierTipValueConfig } = useContext(AppContext);
  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const customTip = {
    title: translate('other'),
    value: -1,
    isCustom: true
  };
  const [tipValues, setTipValues] = useState<ICourierTip[]>([...(courierTipValueConfig.config?.tipValues || []), customTip]);

  const handleCustomValue = (val) => {

    if (val >= 0) {
      setSelectedTip({ ...customTip, customValue: val || -1 });

      setTipValues(tipValues.map(v => {
        if (v.isCustom)
          v.customValue = val;
        return v
      }))
    } else {
      setSelectedTip(null);
    }
    setShowCustomInput(false);

  }

  const handleTipValue = (tip) => {
    if (showCustomInput) setShowCustomInput(false)
    setSelectedTip(tip)
  }
  useEffect(() => {
    onChange(selectedTip)
  }, [selectedTip])

  useEffect(() => {
    setSelectedTip(tipValues[0])
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {translate('ecommerce.rateOrder.leaveTip')}
      </div>
      <div className={styles.tipActionContainer}>

        {
          tipValues.map(tip => {

            if (tip.isCustom) {
              return showCustomInput ?
                <CustomInput key={'tips_' + tip.title} onChange={handleCustomValue} initialValue={tip.customValue > -1 ? tip.customValue : null} /> :
                <CourierTip
                  key={'tips_' + tip.title}
                  custom
                  value={tip.customValue}
                  active={selectedTip && selectedTip.isCustom}
                  onClick={(val) => setShowCustomInput(true)}
                />
            } else
              return <CourierTip
                key={'tips_' + tip.title}
                value={tip.value}
                active={selectedTip && !selectedTip.isCustom && selectedTip.value === tip.value}
                onClick={() => handleTipValue(tip)}
              />
          })
        }
      </div>
    </div>
  )
}

export default CourierTips


interface CourierTipCustomInputProps {
  onChange: (value) => void,
  initialValue: number
}

const CustomInput: FC<CourierTipCustomInputProps> = ({ onChange, initialValue }) => {
  const [translate] = UseTranslate()

  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState<number>();
  const container = cn({
    [styles.customInputCnt]: true,
    [styles.focused]: focused,
  })

  useEffect(() => {
    setTimeout(() => {
      setFocused(true)
      if (initialValue) setInputValue(Number(initialValue))
    }, 50)
  }, [])


  return <div className={container}>
    <div className={styles.inputRow}>
      <input
        id="customTip"
        name="customTip"
        style={{
          position: 'relative',
          height: '27px',

        }}
        type='number'
        defaultValue={String(initialValue)}
        placeholder={translate('ecommerce.rateOrder.leaveTip.other')}
        onInput={(event) => {
          setInputValue(Number(event.currentTarget.value))
        }}
        autoFocus={true}
      />
      <Button
        size={27}
        style={{
          height: '26px',
          marginLeft: '-27px',
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          zIndex: 12,
          paddingBottom: 13,
          paddingTop: 14,
        }}
        iconLeft={<BiCheck style={{marginBottom:-4}} />}

        onClick={() => (onChange(inputValue))}
      />
    </div>
  </div>
};