import React, { FC, useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ProductCard } from '@components/common'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

import styles from './ProductList.module.scss'

import RequestHelper from '@utils/RequestHelper'
import { ProductSkeleton } from '@components/ui'
import { UseTranslate } from 'hooks/Translate'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import cn from 'classnames'
import UserContext from 'contexts/UserContext'

interface ProductListProps {
  categoryIds: Array<string>
  products: Product[]
}

const ProductList: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<ProductListProps>>
  >
> = (props: ProductListProps) => {
  const LIMIT = 24
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const [firstRun, setFirstRun] = useState(false)
  const [products, setProducts] = useState<Product[]>([])
  const [hasMore, setHasMore] = useState(true)
  const [loader, setLoader] = useState<React.ReactNode>()
  const [translate] = UseTranslate()
  const [sortField, setSortField] = useState('isInStock')
  const [sortDir, setSortDir] = useState<number>()
  const [sortMenuOpen, setSortMenuOpen] = useState(false)
  const { selectedAddress } = useContext(UserContext)

  const sortMenu = cn({
    [styles.search__top__sort__menu]: true,
    [styles.search__top__sort__menuOpen]: sortMenuOpen,
  })

  const sortDirAsc = cn({
    [styles.search__top__sort__menu__item]: true,
    [styles.search__top__sort__menu__itemActive]: sortDir === 1,
  })

  const sortDirDesc = cn({
    [styles.search__top__sort__menu__item]: true,
    [styles.search__top__sort__menu__itemActive]: sortDir === -1,
  })

  function toggleSort() {
    setSortMenuOpen(!sortMenuOpen)
  }

  useEffect(() => {
    setProducts([])
  }, [lang])

  useEffect(() => {
    if (props.categoryIds.length && !firstRun) {
      products.splice(0)
      loadProducts()
    } else {
      setFirstRun(false)
    }
  }, [props.categoryIds])

  useEffect(() => {
    setFirstRun(true)
    setProducts(props.products)
  }, [])

  useEffect(() => {
    if (sortDir) {
      setProducts([])
      loadProducts()
    }
  }, [sortDir])

  useEffect(() => {
    if (!firstRun) {
      setProducts([])
      loadProducts()
    }
  }, [selectedAddress])

  const loadProducts = async () => {
    if (!props.categoryIds.length) {
      setProducts(props.products)
      return
    }
    setHasMore(true)
    const body = {
      skip: products.length,
      limit: LIMIT,
      categoryIds: props.categoryIds,
    }

    setLoader(<ProductSkeleton />)

    if (!sortField) {
      setSortField('isInStock')
    }

    const url = `products/search?lang=${lang}&sortField=${sortField}&sortDirection=${
      sortDir || -1
    }`

    if (selectedAddress?.warehouseId) {
      body['warehouseId'] = selectedAddress?.warehouseId
    }

    const { data } = await RequestHelper.catalog.post(url, body)

    setProducts((products) => [...products, ...data.data.products])

    setLoader(null)

    if (products.length + LIMIT > data.data.totalCount) {
      setHasMore(false)
    }
  }

  return (
    <>
      <NextHead>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "ItemList", "itemListElement":[${products.map(
              (prod, index) => {
                return JSON.stringify({
                  '@type': 'ListItem',
                  position: index + 1,
                  url: `${process.env.NEXT_PUBLIC_BASE_URL}/${lang}/product/${prod.nameSlug}`,
                })
              }
            )}]}`,
          }}
        />
      </NextHead>
      <div className={styles.productHeader}>
        <div
          tabIndex={0}
          className={styles.search__top__sort}
          onClick={toggleSort}
          onBlur={() => setSortMenuOpen(false)}
        >
          <div className="d-flex w-100">
            {sortDir
              ? sortDir === 1
                ? translate('search.sort.asc')
                : translate('search.sort.desc')
              : translate('search.sort')}
            <div className="flex-fill"></div>
            {sortMenuOpen ? (
              <FiChevronUp size={22} className={styles.sortIcon} />
            ) : (
              <FiChevronDown size={22} className={styles.sortIcon} />
            )}
          </div>
          <div className={sortMenu}>
            <div
              className={sortDirAsc}
              onClick={() => {
                if (sortDir !== 1) {
                  setProducts([])
                  setSortField('stock.price')
                  setSortDir(1)
                }
              }}
            >
              {translate('search.sort.asc')}
            </div>
            <div
              className={sortDirDesc}
              onClick={() => {
                if (sortDir !== -1) {
                  setProducts([])
                  setSortField('stock.price')
                  setSortDir(-1)
                }
              }}
            >
              {translate('search.sort.desc')}
            </div>
          </div>
        </div>
      </div>

      <InfiniteScroll
        next={() => loadProducts()}
        dataLength={products.length}
        hasMore={hasMore}
        loader={loader}
      >
        <div className={styles.productList}>
          {[
            ...new Map(
              products.map((item) => [item['productId'], item])
            ).values(),
          ].map((product: Product) => (
            <ProductCard product={product} key={`productCard-${product._id}`} />
          ))}
        </div>
      </InfiniteScroll>
    </>
  )
}

export default ProductList
