import React, { DOMAttributes, FC, MouseEventHandler } from 'react'

import styles from './SelectorItem.module.scss'

interface SelectorItemProps extends DOMAttributes<HTMLElement> {
  template?: JSX.Element
  display?: string
  value?: string
  itemClick?: MouseEventHandler<HTMLDivElement>,
}

const SelectorItem: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SelectorItemProps>>>> = (props: SelectorItemProps) => {
  return (
    <div
      className={styles.container}
      onClick={props.itemClick ? props.itemClick : props.onClick}
      id={props.value}
      data-value={props.value}
      data-display={props.display}
    >
      {props.template ? (
        props.template
      ) : (
        <div className={styles.displayName}>{props.display}</div>
      )}
    </div>
  )
}

export default SelectorItem
