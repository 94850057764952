import React, { FC, useState, useContext, useEffect } from 'react'

import { Radio } from '@components/ui'
import { FiEdit, FiAlertCircle } from 'react-icons/fi'

import cn from 'classnames'

import styles from './AddressList.module.scss'
import AddressTabs from './AddressTabs'
import { UseTranslate } from 'hooks/Translate'
import AppContext from '../../contexts/AppContext'
import UserContext from '../../contexts/UserContext'

interface AddressListProps {
  onEdit: (address: Record<string, combined>) => void
  addresses: Record<string, combined>[]
  visible: boolean
  onChangeType: (type: 'delivery' | 'pickup') => void
  onChangeSelected: () => void
}

const AddressList: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<AddressListProps>>>> = (props: AddressListProps) => {
  const [translate] = UseTranslate()
  const { setAddressType, setAddress, isInactiveAddress, deliveryServiceConfig } = useContext(AppContext)

  const { selectedAddress, setSelectedAddress } = useContext(UserContext)

  const [addresses, setAddresses] = useState<Record<string, combined>[]>([])
  const [inactiveAddresses, setInactiveAddresses] = useState<Record<string, { msg: string }>>()

  const [type, setType] = useState<'delivery' | 'pickup'>(
    selectedAddress?.type || 'delivery'
  )

  useEffect(() => {
    const inactiveAddressMap = {};
    (props.addresses || []).forEach(add => {
      const { inactive, msg } = isInactiveAddress(add, deliveryServiceConfig, false);
      if (inactive) {
        inactiveAddressMap[add._id] = { msg }
      }
    })
    setInactiveAddresses(inactiveAddressMap)
    setAddresses(props.addresses.sort(latest))
  }, [])

  useEffect(() => {
    //setAddresses(props.addresses.sort(latest))

    if (selectedAddress) {
      setAddress(selectedAddress)
      setAddressType(selectedAddress.type || 'delivery')
    }

  }, [selectedAddress])

  function onChangeType(type: 'delivery' | 'pickup') {
    setType(type || 'delivery')
    props.onChangeType(type)
  }

  const selector = cn({
    [styles.fs16]: type === 'pickup',
    [styles.selector]: true,
  })

  function latest(a: Address, b: Address) {
    return Date.parse(b.createdAt!) - Date.parse(a.createdAt!)
  }

  return (
    <>
      <AddressTabs
        type={type}
        showTabs={true}
        hasMargin={true}
        typeChanged={onChangeType}
      />
      <div className={styles.container}>
        {addresses.map((address) => {
          return (
            address.type === type && (
              <div className={styles.address} key={address?._id}>
                <div className={styles.header}>
                  <div className={selector}>
                    <Radio
                      disabled={!!inactiveAddresses[address._id]}
                      name="place"
                      id={address._id}
                      checked={address._id === selectedAddress?._id}
                      onChange={() => {
                        setSelectedAddress(address)
                        props.onChangeSelected()
                      }}
                      label={
                        address.type === 'pickup'
                          ? address.address.title
                          : address.addressName
                      }
                    />
                  </div>
                  <div
                    className={styles.edit}
                    onClick={() => props.onEdit(address)}
                  >
                    <FiEdit />
                  </div>
                </div>
                {type === 'pickup' && <p></p>}
                {type === 'delivery' && (
                  <div className={!!inactiveAddresses[address._id] ? styles.disabledContent : styles.content}>
                    <div className={styles.location}>
                      {address.address.title}
                    </div>
                    {address.comment ? (
                      <div className={styles.instruction}>
                        <div className={styles.courierInstructions}>
                          {translate('ecommerce.courierInstruction')}:
                        </div>
                        <p>{address.comment}</p>
                      </div>
                    ) : null}
                  </div>
                )}
                {inactiveAddresses[address._id] && (
                  <div className={styles.inactiveAddress}>
                    <span className={styles.inactiveAddressIcon}><FiAlertCircle size={23} /></span> {translate(inactiveAddresses[address._id].msg)}
                  </div>
                )
                }

              </div>
            )
          )
        })}
      </div>
    </>
  )
}

export default AddressList
