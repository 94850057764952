import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import styles from './MobileBanner.module.scss'
import { useRouter } from 'next/router'
import { getAppLink } from '@utils/dynamicLinks'
import Link from 'next/link'

const MobileBanner = () => {
  const [hideBanner, setHideBanner] = useState(false)
  const [linkToApp, setLinkToApp] = useState<string>()

  const mobileBannerClassName = classNames(styles.mobileBanner, {
    [styles.mobileBannerHidden]: hideBanner,
  })
  const router = useRouter();
  const hide = () => {
    setHideBanner(true)
    sessionStorage.setItem('downloadAppsBanner', 'true')
  }


  useEffect(() => {
    if (isIOS || isAndroid) {
      const value = sessionStorage.getItem('downloadAppsBanner') || 'false'

      try {
        setLinkToApp(getAppLink(router.query?.gclid?.toString() || ''));
        const _hideBanner = JSON.parse(value)
        setHideBanner(_hideBanner)
      } catch (e) {
        setHideBanner(false)
        sessionStorage.removeItem('downloadAppsBanner')
      }
    }
  }, [isIOS, isAndroid])

  return (
    <div className={mobileBannerClassName}>
      <div className={styles.mobileBannerBackdrop} onClick={hide}></div>
      <div className={styles.wrapper}>
        <img
          src="/images/closeBanner.png"
          alt="closeBanner"
          className={styles.closeBanner}
          onClick={hide}
        />
        <a href={linkToApp} target='_blank' >
          <img
            src="/images/downloadAppsBanner.png"
            alt="downloadAppsBanner"
            className={styles.downloadAppsBanner}
            onClick={hide}
          />
        </a>
      </div>
    </div>
  )
}

export default MobileBanner
