import React, { memo, useState, useContext } from 'react'

import RequestHelper from '@utils/RequestHelper'
import { User as OIDCUser } from 'oidc-client'
import { UserProfile } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'
import AppContext from './AppContext'
import { PrimeConfiguration } from '@components/UserProfile/prime/Prime'

interface UserContext {
  loading: boolean
  addressesLoading: boolean
  setAddressesLoading: (value?: boolean) => void
  userProfileLoading: boolean
  setUserProfileLoading: (value?: boolean) => void
  userCards: UserCard[]

  setLoading: (value?: boolean) => void
  loadUserCards: (userId: string | null | undefined) => Promise<void>
  loadUserOrders: (
    userId: string | null | undefined,
    pageSize?: number,
    page?: number,
    search?: string,
    statusIds?: number[],
    notInStatus?: boolean,
    sortField?: string,
    sortOrder?: string
  ) => Promise<[Order[]?, number?]>
  loadUserCurrentOrders: (userId: string | null | undefined) => void
  loadUserEwalletOrders: (userId: string | null | undefined) => void
  loadUserPastOrders: (
    userId: string | null | undefined,
    pageSize?: number,
    page?: number,
    search?: string,
    sortField?: string,
    sortOrder?: string
  ) => void
  userCurrentOrders: Order[]
  userEwalletOrders: WalletTransaction[]
  userPastOrders: Order[]
  userPastOrdersTotalCount: number
  userEwalletOrdersTotalCount: number
  userPastOrdersLoading: boolean
  setUserPastOrdersLoading: (value?: boolean) => void
  userCurrentOrdersLoading: boolean
  userEwalletOrdersLoading: boolean
  setUserCurrentOrdersLoading: (value?: boolean) => void
  setUserEwalletOrdersLoading: (value?: boolean) => void
  showCardModal: boolean
  setShowCardModal: (value?: boolean) => void
  addresses: Address[]
  loadAddresses: (userId: string | null | undefined) => void
  selectedAddress: Address | undefined
  setSelectedAddress: (address: Address) => void
  setSelectedAddressFromBack: (address: Address, addresses: Address[]) => void
  loadReferralConfig: () => void
  referralConfig: Partial<ReferralConfig> | undefined
  loadUserReferral: () => void
  userReferral: Partial<Referral> | undefined
  addressToEdit: Address | undefined
  setAddressToEdit: (address: Address) => void
  showAddAddressModal: boolean
  setShowAddAddressModal: (value?: boolean) => void
  iframeUrl: string | undefined
  setIframeUrl: (iframeUrl?: string) => void
  wishlist: Wishlist | undefined
  loadWishlist: () => void
  setWishlist: (wishlist: Wishlist) => void
  profile: User | undefined
  loadProfile: (userId: string | null | undefined) => Promise<User>
  setUser: (user: OIDCUser | UserProfile | null | undefined) => void
  user: OIDCUser | UserProfile | null | undefined
  customerInfo: ICustomerInfo | undefined
  accumulatedPoints: number
  loadCustomerInfo: () => void
  setCustomerInfo: (customerInfo: ICustomerInfo) => void
  pointCalculationIsActive: boolean
  setPointCalculationIsActive: (value?: boolean) => void
  lastPrimeMembership: PrimeDetails | undefined
  membershipDataIsLoading: boolean
  primeConfiguration: PrimeConfiguration | undefined
  loadPrimeConfiguration: () => void
  loadPrimeDetails: () => void
  activatePrime: (cardId: string) => void
  paymentStatus: 'fail' | 'success' | 'processing' | undefined
  resetPaymentStatus: () => void
  primePaymentHistory: any | undefined
  activationScreen: 'activation' | 'payment'
  setActivationScreen: (screen: 'activation' | 'payment') => void
  loadPrimePaymentHistory: (pagingData: PagingData, reset?: boolean) => void
  deactivatePrime: () => void
  pagingData: PagingData | undefined
  setPagingData: (pagingData: PagingData) => void
  rateOrderId: string
  rateOrderTotalAmount: number
  rateOrderDeliveryType: string
  rateOrderModalVisible: boolean
  rateFromDetails: boolean
  setRateOrderModalVisible: (
    orderId: string,
    visible: boolean,
    deliveryType: string,
    rateFromDetails?: boolean,
    totalAmount?: number
  ) => void
  rateOrder: (orderId: string, payload) => Promise<any>
  rateScreen: 'RateCourier' | 'RateOrder'
  setRateScreen: (screen: 'RateCourier' | 'RateOrder') => void
  markOrderReviewRead: (orderId: string) => void
  courierTipVisible?: boolean
  setCourierTipVisible: (visible: boolean) => void
  loadUnreadOrders: () => void
  markOrderAsRead: (orderId: string) => void
  paymentModalVisible: boolean
  setPaymentModalVisibility: (visible: boolean) => void
  orderIsAlreadyReviewed: boolean
  setOrderIsAlreadyReviewed: (v: boolean) => void
  paymentIssuerConfig:
  | {
    tbc: boolean
    bog: boolean
  }
  | undefined

  selectedPaymentIssuer: 'bog' | 'tbc' | null
  loadPaymentIssuerConfig: () => Promise<{ tbc: boolean; bog: boolean }>
  setSelectedPaymentIssuer: (v: string) => void
  setPrimeMembershipIsActive: (v: boolean) => void
  paymentIsInProcess: boolean
  setPaymentIsInProcess: (process: boolean) => void
  paymentError: boolean
  setPaymentError: (value: boolean) => void
}

const initialState: UserContext = {
  loading: false,
  setLoading: () => undefined,
  addressesLoading: false,
  setAddressesLoading: () => undefined,
  userProfileLoading: false,
  setUserProfileLoading: () => undefined,
  userCards: [],
  loadUserCards: () => undefined,
  loadUserOrders: () => undefined,
  loadUserCurrentOrders: () => undefined,
  loadUserEwalletOrders: () => undefined,
  loadUserPastOrders: () => undefined,
  userCurrentOrders: [],
  userEwalletOrders: [],
  userPastOrders: [],
  userCurrentOrdersLoading: false,
  userEwalletOrdersLoading: false,
  setUserCurrentOrdersLoading: () => undefined,
  setUserEwalletOrdersLoading: () => undefined,
  userPastOrdersLoading: false,
  setUserPastOrdersLoading: () => undefined,
  showCardModal: false,
  setShowCardModal: () => undefined,
  addresses: [],
  loadAddresses: () => undefined,
  selectedAddress: undefined,
  setSelectedAddress: () => undefined,
  setSelectedAddressFromBack: () => undefined,
  loadReferralConfig: () => undefined,
  referralConfig: {},
  loadUserReferral: () => undefined,
  userReferral: {},
  addressToEdit: undefined,
  setAddressToEdit: () => undefined,
  showAddAddressModal: false,
  setShowAddAddressModal: () => undefined,
  iframeUrl: undefined,
  setIframeUrl: () => undefined,
  wishlist: undefined,
  loadWishlist: () => undefined,
  setWishlist: () => undefined,
  profile: undefined,
  loadProfile: () => undefined,
  setUser: () => undefined,
  user: undefined,
  customerInfo: undefined,
  accumulatedPoints: 0,
  loadCustomerInfo: () => undefined,
  setCustomerInfo: () => undefined,
  pointCalculationIsActive: false,
  setPointCalculationIsActive: () => undefined,
  userPastOrdersTotalCount: 0,
  userEwalletOrdersTotalCount: 0,
  lastPrimeMembership: undefined,
  membershipDataIsLoading: true,
  primeConfiguration: undefined,
  loadPrimeConfiguration: () => undefined,
  loadPrimeDetails: () => undefined,
  activatePrime: (cardId: string) => undefined,
  paymentStatus: undefined,
  resetPaymentStatus: () => undefined,
  primePaymentHistory: undefined,
  activationScreen: 'activation',
  setActivationScreen: (screen: 'activation' | 'payment') => undefined,
  loadPrimePaymentHistory: () => undefined,
  deactivatePrime: () => undefined,
  pagingData: { page: 0, totalCount: 0, hasMore: false },
  setPagingData: () => undefined,
  rateOrderId: null,
  rateOrderTotalAmount: null,
  rateOrderDeliveryType: null,
  rateOrderModalVisible: false,
  rateFromDetails: false,
  setRateOrderModalVisible: (
    orderId: string,
    visible: boolean,
    deliveryType: string,
    rateFromDetails?: boolean,
    totalAmount?: number
  ) => undefined,
  rateOrder: (orderId: string, payload) => undefined,
  rateScreen: 'RateCourier',
  setRateScreen: (screen: 'RateCourier' | 'RateOrder') => undefined,
  markOrderReviewRead: (orderId: string) => undefined,
  courierTipVisible: false,
  setCourierTipVisible: (visible: boolean) => undefined,
  loadUnreadOrders: () => undefined,
  markOrderAsRead: (orderId: string) => undefined,
  paymentModalVisible: false,
  setPaymentModalVisibility: (visible: boolean) => undefined,
  orderIsAlreadyReviewed: false,
  setOrderIsAlreadyReviewed: (v: boolean) => undefined,
  paymentIssuerConfig: undefined,
  selectedPaymentIssuer: null,
  loadPaymentIssuerConfig: async () => undefined,
  setSelectedPaymentIssuer: (v: string) => undefined,
  setPrimeMembershipIsActive: (visible: boolean) => undefined,
  paymentIsInProcess: false,
  setPaymentIsInProcess: () => undefined,
  paymentError: false,
  setPaymentError: () => undefined,
}

const UserContext = React.createContext<UserContext>(initialState)

export const UserDataProvider = memo((props: Record<string, combined>) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const { loadCart } = useContext(AppContext)

  const [state, setState] = useState<UserContext>({
    ...initialState,

    loadUserCards: async (userId: string | null | undefined) => {
      if (userId) {
        state.setLoading()
        try {
          const resp = await RequestHelper.catalog(
            `userCards/?userId=${userId}`
          )
          let config = state.paymentIssuerConfig
          if (!config) {
            config = await state.loadPaymentIssuerConfig()
          }
          if (resp && resp.data && resp.data.data) {
            const userCards = (resp.data.data || []).map((c) => {
              c.disabled = config && !config[c.bankKey]
              return c
            })

            setState((state) => ({
              ...state,
              userCards,
            }))
          }
        } catch (e) {
          console.error(e)
        }
        state.setLoading()
      }
    },
    loadUserOrders: async (
      userId: string | null | undefined,
      pageSize = 1000,
      page = 1,
      search = '',
      statusIds?: number[],
      notInStatus = false,
      sortField = '',
      sortOrder = ''
    ) => {
      return new Promise(async (resolve) => {
        if (userId) {
          try {
            let url = `orders/?userId=${userId}&pageSize=${pageSize}&search=${search}&page=${
              (page - 1) * pageSize
              }`
            if (statusIds && statusIds.length) {
              url += `&status=${statusIds.join(',')}`
            }
            if (notInStatus) {
              url += `&notInStatus=1`
            }

            if (sortField && sortOrder) {
              url += `&sortField=${sortField}&sortOrder=${sortOrder}`
            }

            const resp = await RequestHelper.catalog(url)

            if (resp && resp.data && resp.data.data) {
              const orders = resp.data.data.orders
              resolve([orders, resp.data.data.totalCount])
            } else {
              resolve([])
            }
          } catch (e) {
            resolve([])
            console.error(e)
          }
        }
      })
    },
    loadUserCurrentOrders: async (
      userId: string | null | undefined,
      pageSize = 1000
    ) => {
      state.setUserCurrentOrdersLoading(true)
      const [orders] = await state.loadUserOrders(
        userId,
        pageSize,
        1,
        '',
        [5, 6, 7, 8],
        true
      )
      setState((state) => ({
        ...state,
        userCurrentOrders: orders,
      }))
      state.setUserCurrentOrdersLoading(false)
    },

    loadUserEwalletOrders: async (
      userId: string | null | undefined,
      pageSize = 100,
      page = 1
    ) => {
      return new Promise(async (resolve: any) => {
        try {
          const url = `/eWallet/transactions?page=${page}&pageSize=${pageSize}`

          const resp = await RequestHelper.kari(url)

          if (resp && resp.data && resp.data.data) {
            const ewalletOrders = resp.data.data.data
            const totalCount = resp.data.data.totalCount

            setState((state) => ({
              ...state,
              userEwalletOrders: ewalletOrders,
              userEwalletOrdersTotalCount: totalCount,
            }))
            state.setUserEwalletOrdersLoading(false)
          } else {
            resolve([])
          }
        } catch (e) {
          resolve([])
          console.error(e)
        }
      })
    },
    loadUserPastOrders: async (
      userId: string | null | undefined,
      pageSize = 10,
      page = 1,
      search = '',
      sortField = '',
      sortOrder = ''
    ) => {
      state.setUserPastOrdersLoading(true)
      const [orders, totalCount] = await state.loadUserOrders(
        userId,
        pageSize,
        page,
        search,
        [5, 6, 7, 8],
        false,
        sortField,
        sortOrder
      )

      setState((state) => ({
        ...state,
        userPastOrders: orders,
        userPastOrdersTotalCount: totalCount,
      }))
      state.setUserPastOrdersLoading(false)
    },

    loadAddresses: async (userId: string | null | undefined) => {
      state.setAddressesLoading(true)
      state.setLoading()
      try {
        const resp = await RequestHelper.catalog(
          `userAddresses/?userId=${userId}`
        )
        if (resp && resp.data && resp.data.data) {
          const addresses = resp.data.data
          let selectedAddress
          for (const address of addresses) {
            if (address.isDefault) {
              selectedAddress = address
              break
            }
          }
          setState((state) => ({
            ...state,
            addresses,
            selectedAddress,
          }))
          state.setLoading()
          state.setAddressesLoading(false)
          router.replace(location.pathname, undefined, { shallow: true })
          loadCart(
            lang,
            state.customerInfo?.clubMembership?.current?.isMember,
            userId,
            selectedAddress?.warehouseId
          )
        }
      } catch (e) {
        state.setLoading()
        state.setAddressesLoading(false)
        console.error(e)
      }
    },
    setSelectedAddress: async (address: Address) => {
      if (address) {
        await RequestHelper.catalog.put(`userAddresses/${address._id}/default`)
        state.loadAddresses(address.userId)
      } else {
        setState((state) => ({
          ...state,
          selectedAddress: address,
        }))
      }
    },
    setSelectedAddressFromBack: (address: Address, addresses: Address[]) => {
      setState((state) => ({
        ...state,
        addresses,
        selectedAddress: address,
      }))
    },
    setLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        loading: typeof value === 'boolean' ? value : !state.loading,
      }))
    },
    setAddressesLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        addressesLoading:
          typeof value === 'boolean' ? value : !state.addressesLoading,
      }))
    },

    setUserCurrentOrdersLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        userCurrentOrdersLoading:
          typeof value === 'boolean' ? value : !state.userCurrentOrdersLoading,
      }))
    },

    setUserEwalletOrdersLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        userEwalletOrdersLoading:
          typeof value === 'boolean' ? value : !state.userEwalletOrdersLoading,
      }))
    },

    setUserPastOrdersLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        userPastOrdersLoading:
          typeof value === 'boolean' ? value : !state.userPastOrdersLoading,
      }))
    },

    setUserProfileLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        userProfileLoading:
          typeof value === 'boolean' ? value : !state.userProfileLoading,
      }))
    },
    setShowCardModal: (value?: boolean) => {
      setState((state) => ({
        ...state,
        showCardModal:
          typeof value === 'boolean' ? value : !state.showCardModal,
      }))
    },
    setAddressToEdit: (value) => {
      setState((state) => ({
        ...state,
        addressToEdit: value,
      }))
    },
    setIframeUrl: (value) => {
      setState((state) => ({
        ...state,
        iframeUrl: value,
      }))
    },
    setShowAddAddressModal: (value) => {
      setState((state) => ({
        ...state,
        showAddAddressModal:
          typeof value === 'boolean' ? value : !state.showAddAddressModal,
      }))
    },
    setUser: async (user: OIDCUser | UserProfile | null | undefined) => {
      setState((state) => {
        return {
          ...state,
          user,
        }
      })
    },
    loadProfile: async (userId: string | null | undefined): Promise<User> => {
      state.setUserProfileLoading(true)
      let data
      try {
        const url = 'users/' + userId + '?loadCourierInfo=1'
        const response = await RequestHelper.cas.get(url)

        data = {
          _id: response.data.data._id,
          email: response.data.data.email,
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          phoneNumber: response.data.data.phoneNumber,
          isCourier: response.data.data.isCourier,
          courierId: response.data.data.courierId,
          sub: response.data.data._id,
        }

        setState((state) => ({
          ...state,
          profile: data,
        }))
      } catch (e) {
        console.error(e)
      }
      state.setUserProfileLoading(false)
      return data
    },
    setWishlist: (wishlist) => {
      setState((state) => ({
        ...state,
        wishlist,
      }))
    },
    loadReferralConfig: async () => {
      const res = await RequestHelper.catalog.get(
        'referal/config?filterWithLanguage=true'
      )
      const referralConfig: ReferralConfig = res.data.data
      setState((state) => ({
        ...state,
        referralConfig,
      }))
    },
    loadUserReferral: async () => {
      const res = await RequestHelper.catalog.post('referal')
      const userReferral: Referral = res.data.data
      setState((state) => ({
        ...state,
        userReferral,
      }))
    },
    loadWishlist: async () => {
      state.setLoading()
      try {
        const res = await RequestHelper.catalog(`wishlists`)
        if (res && res.data) {
          const wishlist: Wishlist = res.data.data || []
          setState((state) => ({
            ...state,
            wishlist,
          }))
        }
      } catch (e: unknown) {
        if (e && e['status'] == 401) {
          window.location.href = '/'
        }
      }
      state.setLoading()
    },
    loadCustomerInfo: async () => {
      state.setLoading()
      try {
        const url = 'loyaltyCustomer/info'
        const res = await RequestHelper.kari.get(url)
        const customerInfo = res.data.data
        setState((state) => {
          return {
            ...state,
            customerInfo,
            accumulatedPoints: customerInfo?.cards.reduce(
              (prev, curr) => prev + curr.points,
              0
            ),
          }
        })
      } catch (e: unknown) {
        if (e && e['status'] == 401) {
          window.location.href = '/'
        }
      }
      state.setLoading()
    },
    setPagingData(pagingData: PagingData) {
      setState((state) => {
        return {
          ...state,
          pagingData,
        }
      })
    },
    setPointCalculationIsActive: (value) => {
      setState((state) => ({
        ...state,
        pointCalculationIsActive:
          typeof value === 'boolean' ? value : !state.pointCalculationIsActive,
      }))
    },
    setCustomerInfo(customerInfo) {
      setState((state) => {
        return {
          ...state,
          customerInfo,
          accumulatedPoints: customerInfo?.cards.reduce(
            (prev, curr) => prev + curr.points,
            0
          ),
        }
      })
    },
    loadPrimeConfiguration: async () => {
      try {
        const configurationResponse = await RequestHelper.catalog.get(
          'primeMembershipConfigurations/last'
        )
        const configuration: PrimeConfiguration =
          configurationResponse.data.data

        setState((state) => {
          return {
            ...state,
            primeConfiguration: configuration,
          }
        })
      } catch (error) {}
    },
    loadPrimeDetails: async () => {
      try {
        const lastMembershipResponse = await RequestHelper.catalog.get(
          '/primeMembers/user/last'
        )

        setState((state) => {
          return {
            ...state,
            lastPrimeMembership: lastMembershipResponse.data.data || undefined,
            membershipDataIsLoading: false,
          }
        })
      } catch (e) {
        setState((state) => {
          return {
            ...state,
            lastPrimeMembership: undefined,
            membershipDataIsLoading: false,
          }
        })
      }
    },
    activatePrime: async (cardId: string) => {
      try {
        const activatePrimeResponse = await RequestHelper.catalog.post(
          'primeMembers/withPayment',
          { cardId }
        )

        const activationData: any = activatePrimeResponse.data

        if (activationData.status === 'FAILURE') {
          setState((state) => {
            return {
              ...state,
              paymentStatus: 'fail',
            }
          })
        } else {
          if (activationData.data.paymentStatus == 4) { //processing
            setState((state) => {
              return {
                ...state,
                paymentStatus: 'processing',
              }
            })
          } else {
            setState((state) => {
              return {
                ...state,
                paymentStatus: 'success',
              }
            })
          }
        }
      } catch (error) {
        setState((state) => {
          return {
            ...state,
            paymentStatus: 'fail',
          }
        })
      }
    },
    resetPaymentStatus: () => {
      setState((state) => {
        return {
          ...state,
          paymentStatus: undefined,
        }
      })
    },
    setActivationScreen: (screen: 'activation' | 'payment') => {
      setState((state) => {
        return {
          ...state,
          activationScreen: screen,
        }
      })
    },
    loadPrimePaymentHistory: async (pagingData, reset) => {
      try {
        if (reset) {
          setState((state) => {
            return {
              ...state,
              primePaymentHistory: [],
              pagingData: {
                page: null,
                totalCount: 0,
                hasMore: true,
              },
            }
          })
        }
        const page = pagingData?.page || 0
        const {
          data: { data },
        } = await RequestHelper.catalog.get(
          `/primeMembers/paymentHistory?page=${page * 5}&pageSize=5`
        )

        setState((state) => {
          const historyData =
            state.primePaymentHistory && !reset
              ? state.primePaymentHistory.concat(data.primeMemberPayments)
              : data.primeMemberPayments
          const map = {}
          for (const item of historyData) {
            map[item._id] = item
          }

          return {
            ...state,
            primePaymentHistory: Object.keys(map).map((v) => map[v]),
            pagingData: {
              page: page + 1,
              totalCount: data.totalCount,
              hasMore: page <= Math.ceil(data.totalCount / 5),
            },
          }
        })
      } catch (error) {}
    },
    deactivatePrime: async () => {
      try {
        await RequestHelper.catalog.delete('/primeMembers/stop')
        state.loadPrimeDetails()
      } catch (error) {
        console.log('-ERROR-', error)
      }
    },
    setRateOrderModalVisible: (
      orderId,
      visible,
      rateOrderDeliveryType,
      rateFromDetails,
      totalAmount
    ) => {
      setState((state) => {
        return {
          ...state,
          rateOrderId: orderId,
          rateOrderModalVisible: visible,
          rateFromDetails,
          rateOrderDeliveryType,
          rateOrderTotalAmount: totalAmount,
        }
      })
    },
    rateOrder: async (orderId, payload) => {
      return new Promise(async (resolve, reject) => {
        try {
          const reviewRequest = await RequestHelper.catalog.post(
            `/orders/review/${orderId}`,
            payload
          )
          const reviewData = reviewRequest.data
          resolve(reviewData)
        } catch (error) {
          reject(false)
        }
      })
    },
    setRateScreen: (screen: 'RateCourier' | 'RateOrder') => {
      setState((state) => {
        return {
          ...state,
          rateScreen: screen,
        }
      })
    },
    markOrderReviewRead: async (orderId: string) => {
      try {
        await RequestHelper.catalog.post(
          `orders/review/markAsRead/${orderId}`,
          {}
        )
      } catch (error) {
        console.log('Error on MarkAsRead')
      }
    },
    setCourierTipVisible: (visible: boolean) => {
      setState((state) => {
        return {
          ...state,
          courierTipVisible: visible,
        }
      })
    },
    loadUnreadOrders: async () => {
      const unreadResponse = await RequestHelper.catalog.get(
        '/orders/review/unRead'
      )
      const unreadOrders = unreadResponse.data.data

      if (unreadOrders?.order) {
        const isPickup = unreadOrders.order.deliveryType == 'pickup'

        setState((state) => {
          return {
            ...state,
            rateOrderId: unreadOrders.order._id,
            rateOrderModalVisible: true,
            rateFromDetails: true,
            rateScreen: isPickup ? 'RateOrder' : 'RateCourier',
            rateOrderDeliveryType: unreadOrders.order.deliveryType,
          }
        })
      }
    },
    markOrderAsRead: async (orderId: string) => {
      await RequestHelper.catalog.get(`/orders/review/markAsRead/${orderId}`)
    },
    setPaymentModalVisibility: (visible: boolean) => {
      setState((state) => {
        return {
          ...state,
          paymentModalVisible: visible,
        }
      })
    },
    setOrderIsAlreadyReviewed: (v: boolean) => {
      setState((state) => {
        return {
          ...state,
          orderIsAlreadyReviewed: v,
        }
      })
    },

    loadPaymentIssuerConfig: async () => {
      const res = await RequestHelper.catalog.get('/configs/activeBank')
      const { config } = res.data.data

      // const config = { tbc: false, bog: true }
      let selectedPaymentIssuer = null
      if (!(config.tbc && config.bog))
        selectedPaymentIssuer = config.tbc ? 'tbc' : 'bog'

      setState((state) => {
        return {
          ...state,
          paymentIssuerConfig: config,
          selectedPaymentIssuer,
        }
      })

      return config
    },
    setSelectedPaymentIssuer: (v: 'bog' | 'tbc') => {
      setState((state) => {
        return {
          ...state,
          selectedPaymentIssuer: v,
        }
      })
    },
    setPaymentIsInProcess: (process: boolean) => {
      setState((state) => ({
        ...state,
        paymentIsInProcess: process,
      }))
    },
    setPaymentError: (value: boolean) => {
      setState((state) => ({
        ...state,
        paymentError: value,
      }))
    },
  })

  // useState(async () => {
  //   const AuthService = (await import('../services/authService')).default
  //   try {
  //     const authService = new AuthService()
  //     const user = await authService.checkSession()

  //     setState((state) => ({
  //       ...state,
  //       authService,
  //       user,
  //     }))
  //   } catch (e) {
  //     console.error(e)
  //   }
  // })

  return (
    <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
  )
})

export default UserContext
