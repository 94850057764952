import { FC, useEffect, useState } from 'react'
import styles from './IngredintChangeButton.module.scss'
import cn from 'classnames'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import Alternatives from './alternativeList/Alternatives'
import { isMobile } from 'react-device-detect'
import { UseTranslate } from 'hooks/Translate'
interface IngredientChangeButton {
  opened: boolean
  onOpen: (e) => void
  onClose: () => void
  alternatives: Product[]
  onChange: (cur: Product) => void
  isLast: boolean
  productQuantityInRecipe: number
}
export const IngredientChangeButton: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<IngredientChangeButton>>
  >
> = ({
  isLast,
  opened,
  onOpen,
  alternatives,
  onClose,
  onChange,
  productQuantityInRecipe,
}) => {
  const [cnMenuDesktop, setSdMenuStyle] = useState<string>()
  const [translate] = UseTranslate()
  const [filteredAlternatives, setFilteredAlternatives] = useState<Product[]>()
  const [cnButtonStyles, setButtonStyles] = useState<string>()

  useEffect(() => {
    const filteredData = (alternatives || []).filter(
      (e) => productQuantityInRecipe < e.stock?.stock
    )
    setFilteredAlternatives(filteredData)

    setButtonStyles(
      cn({
        [styles.changeProductBtnCnt]: true,
        [styles.changeProductBtnCntDisabled]: !filteredData.length,
      })
    )
  }, [alternatives])

  useEffect(() => {
    const openMenuTop = !isMobile && isLast && (alternatives || []).length > 2
    setSdMenuStyle(
      cn({
        [styles.desktopMenu]: true,
        [styles.menuOpen]: opened,
        [styles.menuTop]: openMenuTop,
      })
    )
  }, [opened])

  return (
    <div className={cnButtonStyles}>
      <div
        className={styles.changeButton}
        onClick={($event) => {
          if (filteredAlternatives.length) {
            onOpen($event)
          }
        }}
      >
        {translate('ecommerce.recipe.product.change')}
        {opened ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
      </div>
      <div className={cnMenuDesktop}>
        <Alternatives
          visible={opened}
          onSelect={(pr: Product) => onChange(pr)}
          onClose={() => onClose()}
          alternatives={alternatives}
        />
      </div>
    </div>
  )
}
