import React from 'react'
import styles from './CartButtonSkeleton.module.scss'

const CartButtonSkeleton = () => {
  return (
    <div className={styles.mainContainer}>
      <article className={styles.background}></article>
    </div>
  )
}

export default CartButtonSkeleton
