import Backdrop from '@components/ui/Backdrop/Backdrop'
import { ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import SpecialQuantity from '@components/ui/Quantity/SpecialQuantity'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import { hasScrollbar, paddingInlineEnd } from '@utils/padding-inline-end'
import RequestHelper from '@utils/RequestHelper'
import classNames from 'classnames'
import AppContext from 'contexts/AppContext'
import UserContext from 'contexts/UserContext'
import useDebounce from 'hooks/Debounce'
import useSpecialDiscount from 'hooks/SpecialDiscount'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import styles from './SpecialDiscountPopup.module.scss'
import { UseTranslate } from '../../hooks/Translate'

export default function SpecialDiscountPopup() {
  const router = useRouter()
  const [translate] = UseTranslate()
  const { spend, saved } = useSpecialDiscount()

  const { lang = 'ge' } = router.query

  const {
    openSpecialDiscount,
    setOpenSpecialDiscount,
    setOpenCart,
    cart,
    specialProducts,
    setSpecialProducts,
  } = useContext(AppContext)

  const { customerInfo, accumulatedPoints, selectedAddress } =
    useContext(UserContext)

  const [products, setProducts] = useState<CartProduct[]>([])
  const [calculatedPoint, setCalculatedPoint] = useState<number>(0)

  const calculateDebounce = useDebounce(specialProducts, 500)

  const popupClassName = classNames({
    [styles.popup]: true,
    [styles.showPopup]: openSpecialDiscount,
  })

  const goToCheckoutPage = () => {
    setOpenSpecialDiscount()
    router.push(`/${lang}/checkout`)
  }

  useEffect(() => {
    setProducts(
      (cart?.products || []).filter((product) => {
        if (!product.loyaltyDiscount?.discountPriceInPoints) return false
        return product.loyaltyDiscount.discountPriceInPoints < accumulatedPoints
      })
    )
  }, [cart?.products])

  useEffect(() => {
    const scroll = hasScrollbar()
    document.body.style.overflow = openSpecialDiscount ? 'hidden' : 'auto'
    paddingInlineEnd(openSpecialDiscount, scroll)
  }, [openSpecialDiscount])

  useEffect(() => {
    if (!cart?._id) return
    RequestHelper.catalog
      .post('carts/calculatePoint', {
        cartId: cart._id,
        warehouseId: selectedAddress?.warehouseId,
        specialProducts: calculateDebounce,
        deliveryType: selectedAddress?.type,
      })
      .then((result) => {
        setCalculatedPoint(result.data.data.shouldBePayed)
      })
  }, [calculateDebounce])

  useEffect(() => {
    if (openSpecialDiscount) setSpecialProducts([])
  }, [openSpecialDiscount])

  const close = () => {
    setSpecialProducts([])
    setOpenSpecialDiscount()
  }

  return (
    <>
      <Backdrop onClick={close} visible={openSpecialDiscount} />
      <div className={popupClassName}>
        <div className={styles.header}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              close()
              setOpenCart()
            }}
          >
            <path
              d="M28.5 18H7.5"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 28.5L7.5 18L18 7.5"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>{translate('payByPoints')}</span>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={close}
          >
            <path
              d="M27 9L9 27"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9L27 27"
              stroke="#242424"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={styles.body}>
          <div className={styles.pointInfo}>
            <div className={styles.current}>
              <div className={styles.key}>{translate('currentPoint')}</div>
              <div className={styles.value}>{accumulatedPoints}</div>
            </div>
            <div className={styles.spend}>
              <div className={styles.key}>{translate('youWillSpent')}</div>
              <div className={styles.value}>{spend} {translate('pointInMitsemiti')}</div>
            </div>
            <div className={styles.saved}>
              <div className={styles.key}>{translate('savedMoney')}</div>
              <div className={styles.value}>{saved.toFixed(2)} ₾</div>
            </div>
          </div>
          <div className={styles.separator}>
            {customerInfo?.clubMembership?.current?.isMember ? (
              <span className={styles.clubBadge}>CLUB</span>
            ) : null}
          </div>
          <p className={styles.description}>
            {translate('specialDiscountBuyProductMessage')}
          </p>
          {products.map((product, i) => (
            <div className={styles.product} key={product.prodId}>
              {/* {JSON.stringify(product)} */}
              <div className={styles.product__image}>
                {product?.images?.length ? (
                  <img
                    src={getProductPrimaryImageUrl(product)}
                    onError={getBrokenImage}
                    alt={product.title}
                  />
                ) : (
                  <ProductCardImagePlaceholder />
                )}
              </div>
              <div className={styles.product__details}>
                <div className={styles.details__top}>
                  <div className={styles.product__title}>{product.title}</div>
                  <div className={styles.pointsInfo}>
                    {translate('oneUnit')} = {product.loyaltyDiscount.discountPriceInPoints} {translate('pointSakhelobiti')}
                  </div>
                </div>
                <div className={styles.details__bottom}>
                  <div className={styles.product__price}>
                    <span className={styles.discountPrice}>
                      {product.loyaltyDiscount.price} ₾
                    </span>
                    <span className={styles.originalPrice}>
                      {product.price} ₾
                    </span>
                  </div>
                  <div className={styles.product__quantity}>
                    <SpecialQuantity
                      spend={spend}
                      accumulatedPoints={accumulatedPoints}
                      product={product}
                      specialProducts={specialProducts}
                      onChange={(cnt) => {
                        const tmp = specialProducts.slice(0)
                        const index = tmp.findIndex(
                          (prod) => prod.productId === product.prodId
                        )
                        if (cnt === 0) {
                          if (index >= 0) tmp.splice(index, 1)
                        } else if (cnt > 0) {
                          if (index >= 0) {
                            Object.assign(tmp[index], { cnt })
                          } else {
                            tmp.push({ productId: product.prodId, cnt })
                          }
                        }
                        setSpecialProducts(tmp)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <div className={styles.totalValue}>
            {translate('checkTotalAmountOnSpecialDiscountSidenav')} <b>{calculatedPoint?.toFixed(2)} ₾</b>
          </div>
          <div className={styles.button} onClick={goToCheckoutPage}>
            <span>{translate('nextPage')}</span>
            <div className="icon">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.58325 11H17.4166"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 4.58337L17.4167 11L11 17.4167"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
