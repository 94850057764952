import React, { FC } from 'react'

import { Button } from '@components/ui'
import { FiPlus } from 'react-icons/fi'
import styles from './AddAddressButton.module.scss'
import { UseTranslate } from 'hooks/Translate'

interface AddAddressButtonProps {
  onClick: () => void
}

const AddAddressButton: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<AddAddressButtonProps>>>> = (
  props: AddAddressButtonProps
) => {
  const [translate] = UseTranslate()

  return (
    <div className={styles.container} onClick={() => props.onClick()}>
      <Button iconRight={<FiPlus />} round size={40} />
      <span className={styles.addAddress}>
        {translate('ecommerce.addNewAddress')}
      </span>
    </div>
  )
}

export default AddAddressButton
