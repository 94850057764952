import Backdrop from '@components/ui/Backdrop/Backdrop'
import React, { FC } from 'react'

import styles from './Alternatives.module.scss'
import AlternativesItem from './AlternativesItem'
import { UseTranslate } from 'hooks/Translate'

interface AlternativesProps {
  alternatives: Product[]
  onClose: () => void
  visible: boolean,
  onSelect: (pr: Product) => void
}

const Alternatives: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<AlternativesProps>>>> = (
  props: AlternativesProps
) => {

  return (
    <>
      <Backdrop transparent={true} onClick={() => props.onClose()} visible={props.visible} />
      {props.visible && props.alternatives && props.alternatives.length ? (
        <ul className={styles.SearchSuggestion__result}>
          {props.alternatives.map((alternative) => (
            <li key={alternative._id}>
              <AlternativesItem
                onSelect={(pr: Product) => props.onSelect(pr)}
                suggestion={alternative}
                onNavigate={() => props.onClose()}
              />
            </li>
          ))}

        </ul>
      ) : null}
    </>
  )
}

export default Alternatives
