   const referrerIsInSources = (referrer:string)=>{
  return ['google', 'bing', 'yahoo', 'yandex'].find(a=>referrer.toLowerCase().includes(a.toLowerCase()));
}


const getGacid = () => {
  var match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)'); var raw = (match) ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  return (match) ? match[1] : null;
}

export const getAppLink = (gclidP:string) => {
  const gclid =  gclidP || '';
  let utm_source = '';
  let utm_medium = '';
  const utm_campaign = `web_id_${getGacid()}`


  if (!utm_source) {
    if (gclid) {

      utm_source = 'google';
      utm_medium = 'cpc';
    }

    if (!utm_source) {
      const referrer = document.referrer;
      utm_source = referrerIsInSources(referrer);
      utm_medium = 'organic';
      if (!utm_source) {
        if (referrer) {
          utm_source = (document.referrer || "").replace('http://', "").replace('https://', "").split('/')[0]
          utm_medium = 'referral'
        } else {
          // utm_source = 'direct';
          // utm_medium = 'none'
        }

      }
    }
  }

  let url = `https://orinabiji.page.link/?link=https://2nabiji.ge/&apn=ge.orinabiji.shop&isi=${1635766103}&ibi=ge.orinabiji.shop&utm_campaign${utm_campaign}&gclid=${gclid}`


  if(utm_source){
    url+=`&utm_source=${utm_source}&utm_medium=${utm_medium}`
  }

  return url;
}
