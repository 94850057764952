import React, { FC } from 'react'

import styles from './Card.module.scss'

interface CardProps {
  id?: string
  title: string | JSX.Element
  body: string | JSX.Element
}

const Card: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<CardProps>>>> = (props: CardProps) => {
  return (
    <div id={props.id} className={styles.container}>
      <div className={styles.title}>
        <h2>{props.title}</h2>
      </div>
      <div className={styles.body}>{props.body}</div>
    </div>
  )
}

export default Card
