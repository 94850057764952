import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Progress.module.scss'

interface ProgressProps {
  size: number
  active: number
}

const Progress: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<ProgressProps>>>> = (props: ProgressProps) => {
  return (
    <div className={styles.container}>
      {Array.from(Array(props.size)).map((progress, index) => {
        const progressStyles = cn({
          [styles.progress]: true,
          [styles.active]: index + 1 <= props.active,
        })
        return <div key={index} className={progressStyles}></div>
      })}
    </div>
  )
}

export default Progress
