import { FC, useEffect, useState } from 'react'
import OrderAction from './OrderAction'

import styles from './OrderActions.module.scss'
import { UseTranslate } from 'hooks/Translate'

interface IOrderAction {
  onChange: (value: number) => void
}

const OrderActions: FC<IOrderAction> = ({ onChange }) => {
  const [translate] = UseTranslate()
  const [activeOption, setActiveOption] = useState(null)

  useEffect(() => {
    onChange(activeOption)
  }, [activeOption])

  return (
    <div className={styles.actionContainer}>
      <div>
        <OrderAction
          title={translate('ecommerce.rateOrder.action.bad')}
          icon="😡️"
          onClick={() => setActiveOption('bad')}
          active={activeOption === 'bad'}
        />
      </div>
      <div>
        <OrderAction
          title={translate('ecommerce.rateOrder.action.average')}
          icon="😕"
          onClick={() => setActiveOption('average')}
          active={activeOption === 'average'}
        />
      </div>
      <div>
        <OrderAction
          title={translate('ecommerce.rateOrder.action.good')}
          icon="😊"
          onClick={() => setActiveOption('good')}
          active={activeOption === 'good'}
        />
      </div>
      <div>
        <OrderAction
          title={translate('ecommerce.rateOrder.action.excellent')}
          icon="😍"
          onClick={() => setActiveOption('excellent')}
          active={activeOption === 'excellent'}
        />
      </div>
    </div>
  )
}

export default OrderActions
