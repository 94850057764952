import Bread from '@components/common/Bread'
import { Button, Label } from '@components/ui'
import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import Quantity from '@components/ui/Quantity/Quantity'
import { shareCurrentUrlOnFb } from '@utils/FBUtils'
import { getBrokenImage } from '@utils/getBrokenImage'
import { ListNode } from '@utils/listToTree'
import RequestHelper from '@utils/RequestHelper'
import AppContext from 'contexts/AppContext'
import CategoryContext from 'contexts/CategoryContext'
import ToastContext from 'contexts/ToastContext'
import UserContext from 'contexts/UserContext'
import { UseTranslate } from 'hooks/Translate'
import { NextSeo } from 'next-seo'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import React, {
  CSSProperties,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FiHeart, FiShare, FiX, FiFacebook, FiCopy } from 'react-icons/fi'

import styles from './ProductDetails.module.scss'
import { useUser } from '@auth0/nextjs-auth0'
import tagManagerUtil from '@utils/tagManagerUtil'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import { isMobile } from 'react-device-detect'
import { stripeTextFromHTML } from '@utils/stripeTextFromHTML'
import classNames from 'classnames'
import ProductModuleCarousel from '@components/common/ProductModules/ProductModuleCarousel'
import { findParentCategories, getCategoryLevels } from '@utils/categoryUtils'

interface ProductDetailsProps {
  productData?: Product
  productId?: string
  stockInfo?: Stock
  lang: string
  host?: string
  webUrl?: string
  stockLoading?: boolean
  fromBack?: boolean
  onClose?: () => void
}

export type UniCat = Category & ListNode

const ProductDetails: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<ProductDetailsProps>>
  >
> = ({
  productData,
  lang,
  productId,
  stockInfo,
  webUrl,
  fromBack,
  onClose,
}) => {
    const [routes, setRoutes] = useState<BreadCrumb[]>([])
    const [translate] = UseTranslate()
    const router = useRouter()
    const {
      selectedLanguage,
      cartTotals,
      addProductToCart,
      cart,
      isInactiveAddress,
      setShowInactiveAddressModal,
      deliveryServiceConfig,
    } = useContext(AppContext)
    const { categoriesData } = useContext(CategoryContext)
    const { addToast } = useContext(ToastContext)
    const { wishlist, setWishlist, loadWishlist, selectedAddress, customerInfo } =
      useContext(UserContext)
    const { user, isLoading: userIsLoading } = useUser()
    const [categories, setCategories] = useState<UniCat[]>([])
    const [product, setProduct] = useState<Product>()
    const [currentCategory, setCurrentCategory] = useState<UniCat>()
    const [expanded, setExpanded] = useState<UniCat>()
    const { 'category-slug': nameSlug } = router.query
    const [savedInFavourites, setSavedInFavourites] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const firstLoad = useRef(true)
    const [finalRequest, setFinalRequest] = useState(false)
    const [shareMenuOpen, setShareMenuOpen] = useState(false)
    const [isClub, setIsClub] = useState<boolean>(
      customerInfo?.clubMembership?.current?.isMember
    )
    const [isCustomer, setIsCustomer] = useState<boolean>(
      customerInfo?.cards.length > 0
    )

    useEffect(() => {
      if (fromBack && !firstLoad.current) {
        getStockInfo()
      }
    }, [lang, selectedAddress?.warehouseId, customerInfo])

    useEffect(() => {
      setIsCustomer(customerInfo?.cards.length > 0)
      setIsClub(customerInfo?.clubMembership?.current?.isMember)
    }, [customerInfo])

    const shareMenuClassName = classNames(
      styles.productInfo__properties__shareMenu,
      { [styles.shareMenuOpen]: shareMenuOpen }
    )

    const shareMenuBackdropClassName = classNames(styles.shareMenuBackdrop, {
      [styles.shareMenuOpen]: shareMenuOpen,
    })

    const copyUrl = () => {
      showToast('success', translate('urlCopied'))
      navigator.clipboard.writeText(window.location.href)
      setShareMenuOpen(false)
    }

    const getStockInfo = async () => {
      window.clearTimeout(window['_timeout'])
      window['_timeout'] = window.setTimeout(function () {
        setFinalRequest(true)
      }, 1000)

      try {
        firstLoad.current = false
        let url = `products/stock/${productId}?lang=${lang}`
        if (selectedAddress?.warehouseId) {
          url += `&warehouseId=${selectedAddress?.warehouseId}`
        }
        const response = await RequestHelper.catalog.get(encodeURI(url))
        setProduct((state: Product | undefined) => {
          if (state) {
            return {
              ...state,
              stock: response?.data?.data?.stock,
            }
          }
        })
      } catch (err) {
        console.error('&&&', err)
      }
    }

    const getColor = () => {
      if (product.loyaltyDiscount?.isClub && isClub) {
        return '#F6DD4B'
      }
      // if (product.loyaltyDiscount?.isSpecial && isCustomer) {
      //   return '#A4D442';
      // }
      if (product.loyaltyDiscount && isCustomer) {
        return '#347745'
      }
      if (product.discount) {
        return '#EA5C29'
      }
    }

    const generatedText = useMemo(() => {
      if (!product) {
        return null
      }
      const loyaltyDiscount = product.loyaltyDiscount

      if (loyaltyDiscount?.isSpecial) {
        return {
          text: [
            loyaltyDiscount?.discountName
              ? loyaltyDiscount?.discountName[lang]
              : '',
            loyaltyDiscount?.discountSubName
              ? loyaltyDiscount?.discountSubName[lang]
              : '',
          ]
            .filter((f) => !!f)
            .join(' = '),
          color: '#A4D442',
        }
      }

      if (
        product.loyaltyDiscount?.isClub &&
        customerInfo?.clubMembership?.current?.isMember
      ) {
        return {
          text: product.loyaltyDiscount?.discountName
            ? product.loyaltyDiscount?.discountName[lang]
            : '',
        }
      }
      if (product.loyaltyDiscount && customerInfo?.cards.length) {
        return {
          text: product.loyaltyDiscount?.discountName
            ? product.loyaltyDiscount?.discountName[lang]
            : '',
        }
      }
      if (product.discount) {
        return {
          text: product.discount?.discountName
            ? product.discount?.discountName[lang]
            : '',
        }
      }
    }, [customerInfo, product])

    const parentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (fromBack || finalRequest) {
        if (product && !userIsLoading && categories.length) {
          const categoryLevels = getCategoryLevels(
            product.categoryId,
            categories,
            lang
          )

          const category: UniCat[] = categories.filter((pr: UniCat) => {
            return pr._id === product.categoryId
          })
          setCurrentCategory(category[0])

          tagManagerUtil.ProductView({
            ProductName: product.title,
            ProductPrice: product.stock?.price,
            ProductCategory: product.categoryId,
            ProductDescription: product.description,
            ProductID: product.productId,
            UserID: user?.sub,
            categoryLevels,
          })
        }
      }
    }, [product, user, userIsLoading, fromBack, finalRequest, categories])

    useEffect(() => {
      if (productData) {
        setProduct(productData)
      }
    }, [productData])

    useEffect(() => {
      if (stockInfo) {
        setProduct((state: Product | undefined) => {
          if (state) {
            return {
              ...state,
              stock: stockInfo,
            }
          }
        })
      }
    }, [stockInfo])

    useEffect(() => {
      if (productId && !fromBack) {
        const getProduct = async () => {
          const response = await RequestHelper.catalog.get(
            `products/details/${productId}?lang=${lang}`
          )
          setProduct(response.data.data)
          getStockInfo()
        }

        getProduct()
      }
    }, [productId])

    useEffect(() => {
      if (wishlist && product && wishlist.products) {
        const index = wishlist?.products?.findIndex((item) => {
          return item === product.productId
        })
        setSavedInFavourites(index !== -1)
      }
    }, [wishlist, product])

    useEffect(() => {
      if (user) {
        loadWishlist()
      }
    }, [user])

    useEffect(() => {
      setSelectedCategory(categoriesData as UniCat[])
    }, [lang, nameSlug])

    useEffect(() => {
      setCategories(categoriesData as UniCat[])
    }, [categoriesData])

    useEffect(() => {
      if (product && categories.length) {
        setBreadData(product)
      }
    }, [categories, product])

    const setSelectedCategory = (categories: Array<UniCat>) => {
      const selectedCategory = categories.find(
        (category) => category.nameSlug === nameSlug
      )

      const parent = categories.find(
        (category) => category._id === selectedCategory?.parent
      )

      setExpanded(parent ? parent : selectedCategory)
    }

    const setBreadData = (product: Product) => {
      const breadRoutes: BreadCrumb[] = [
        {
          path: `/${lang}`,
          breadcrumbName: 'shop.main',
          translate: true,
        },
      ]

      if (product.categoryId) {
        const category: UniCat[] = categories.filter((pr: UniCat) => {
          return pr._id === product.categoryId
        })
        if (category && category.length) {
          const breadcrumbs = findParentCategories(category[0], categories)
          breadcrumbs.forEach((category: UniCat) => {
            breadRoutes.push({
              path: `/${lang}/category/${category?.nameSlug}`,
              breadcrumbName:
                Object.keys(category?.name || {}).length > 0 &&
                  category?.name[lang]
                  ? category?.name[lang]
                  : `no translation for ${lang}`,
              translate: false,
            })
          })

          breadRoutes.push({
            path: null,
            breadcrumbName: (product.title || product.nameSlug).replace(/"/g, ''),
            translate: false,
          })
        }
      }

      setRoutes(breadRoutes)
    }

    const saveInWishlist = async () => {
      if (!isLoading) {
        setIsLoading(true)
        if (!user) {
          window.open(
            `/api/auth/login?lang=${selectedLanguage?.key || 'ge'}`,
            '_self'
          )
        } else {
          await addProductInWishlist()
        }
        setIsLoading(false)
      }
    }

    const isInStock = () => {
      return (
        product &&
        product.stock &&
        !(product.stock?.stock - product.stockReservationAmount < 1) &&
        product.isPublished
      )
    }

    const addProductInWishlist = async () => {
      try {
        const newWishlist = await RequestHelper.catalog.post(
          `wishlists?userId=${user?.sub}`,
          {
            prodId: product?.productId,
          }
        )
        if (newWishlist && newWishlist.data) {
          showToast(
            'success',
            savedInFavourites
              ? translate('successfullyDeletedProductInWishlist')
              : translate('successfullyAddedProductInWishlist')
          )
          setWishlist(newWishlist.data.data)
        }
        setIsLoading(false)
      } catch (e) {
        showToast('fail', translate('errorAddingProductInWishlist'))
        setIsLoading(false)
      }
    }

    const showToast = (type, description) => {
      const id = Math.floor(Math.random() * 101 + 1)
      addToast({
        id: id.toString(),
        description: description,
        type: type,
      })
    }

    const getIconColor = (): CSSProperties => {
      return savedInFavourites
        ? { width: 18, height: 18, fill: '#FE5000' }
        : { width: 18, height: 18, fill: '#ffffff' }
    }

    const ShareActions = () => {
      return (
        <div className={styles.contentRight}>
          <div
            className={styles.productInfo__properties__share}
            onClick={() => setShareMenuOpen(!shareMenuOpen)}
          >
            <FiShare className={styles.share_svg} />
            <div className={shareMenuBackdropClassName}></div>
            <ul className={shareMenuClassName}>
              <li onClick={shareCurrentUrlOnFb}>
                <FiFacebook />
                <span style={{ marginLeft: 15 }}>
                  {translate('productDetails.facebookShare')}
                </span>
              </li>
              <li onClick={copyUrl}>
                <FiCopy />
                <span style={{ marginLeft: 15 }}>
                  {translate('productDetails.copy')}
                </span>
              </li>
            </ul>
          </div>
          <div
            className={styles.productInfo__properties__heart}
            onClick={saveInWishlist}
          >
            <FiHeart style={getIconColor()} />
          </div>
        </div>
      )
    }

    const ShareActionsMobile = () => {
      return (
        <div className={styles.contentRight}>
          <div
            onClick={shareCurrentUrlOnFb}
            className={styles.productInfo__properties__share}
          >
            <FiFacebook />
          </div>
          <div onClick={copyUrl} className={styles.productInfo__properties__copy}>
            <FiCopy />
          </div>
          <div
            className={styles.productInfo__properties__heart}
            onClick={saveInWishlist}
          >
            <FiHeart style={getIconColor()} />
          </div>
        </div>
      )
    }

    // const getPrice = () => {
    //   if (product) {
    //     if (product.discount) {
    //       return product.discount?.price;
    //     } else {
    //       return product.stock?.price;
    //     }
    //   } else if (productData) {
    //     if (productData.discount) {
    //       return productData.discount?.price;
    //     } else {
    //       return productData.stock?.price;
    //     }
    //   }
    // }
    const generatePrice = (product) => {
      if (
        product?.loyaltyDiscount?.isClub &&
        customerInfo?.clubMembership?.current?.isMember
      ) {
        return product.loyaltyDiscount.price
      }
      if (
        product?.loyaltyDiscount &&
        customerInfo?.cards.length > 0 &&
        !product?.loyaltyDiscount?.isSpecial &&
        !product?.loyaltyDiscount?.isClub
      ) {
        return product.loyaltyDiscount.price
      }
      if (product?.discount) {
        return product.discount?.price
      }
      return product?.stock?.price
      // if (
      //     product.loyaltyDiscount &&
      //     !product.loyaltyDiscount?.isSpecial &&
      //     !product.loyaltyDiscount.isClub &&
      //     UserStore.isCustomer
      // ) {
      //   return product.loyaltyDiscount.price;
      // }
    }

    const getBarCode = () => {
      if (product) {
        return product.barCode
      } else if (productData) {
        return productData.barCode
      }
    }

    const getPriceValidUntil = () => {
      const now = new Date()
      now.setDate(now.getDate() + 1)
      return now.toISOString()
    }

    return (
      <>
        <NextHead>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
            __html: `{"@context": "https://schema.org","@type": "Product","sku": "${getBarCode()}","brand": "2nabiji","mpn":"${getBarCode()}", "category" : "${
              currentCategory?.name[lang]
                }","description": "${stripeTextFromHTML(
                  productData?.metaSEO?.description ||
                  product?.metaSEO?.description ||
                  'N/A'
                ).replace(/"/g, '\\"')}","name": "${(
                  productData?.metaSEO?.title ||
                  product?.metaSEO?.title ||
                  ''
                ).replace(/"/g, '')}","image": "${getProductPrimaryImageUrl(
                  productData
            )}","offers": {"@type": "Offer","url" : "${`${
              process.env.NEXT_PUBLIC_BASE_URL
            }/${lang}/product/${
              productData?.nameSlug || product?.nameSlug
                }`}","availability": "https://schema.org/InStock","itemCondition": "https://schema.org/NewCondition","price": "${generatePrice(
                  product
                )}","priceCurrency": "GEL","priceValidUntil": "${getPriceValidUntil()}", "seller": {
                "@type": "Organization",
                "name": "2NABIJI - online supermarket and grocery delivery in Tbilisi."
              }}}`,
            }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [${routes
                .filter((e) => e.breadcrumbName)
                .map((route, index) => {
                  return JSON.stringify({
                    '@type': 'ListItem',
                    position: index + 1,
                    name:
                      index == 0
                        ? translate('breadCrumb.schema.name')
                        : route.translate
                          ? translate(route.breadcrumbName)
                          : route.breadcrumbName,
                    item: `${location.origin}${route.path}`,
                  })
                })}]}`,
            }}
          />
        </NextHead>
        {productData?.metaSEO.hideSeo || product?.metaSEO?.hideSeo ? (
          <NextHead>
            <meta name="robots" content="noindex,nofollow" />
          </NextHead>
        ) : (
          <>
            {!router.pathname.includes('category') ? (
              <NextHead>
                <link
                  rel="canonical"
                  href={`https://2nabiji.ge/${selectedLanguage?.key || 'ge'
                    }/product/${productData?.nameSlug || product?.nameSlug}`}
                />
                <link
                  rel="alternate"
                  href={`https://2nabiji.ge/ge/product/${productData?.nameSlug || product?.nameSlug
                    }`}
                  hrefLang="ka-GE"
                />
                <link
                  rel="alternate"
                  href={`https://2nabiji.ge/en/product/${productData?.nameSlug || product?.nameSlug
                    }`}
                  hrefLang="en-GE"
                />
                <link
                  rel="alternate"
                  href={`https://2nabiji.ge/en/product/${productData?.nameSlug || product?.nameSlug
                    }`}
                  hrefLang="x-default"
                />
              </NextHead>
            ) : (
              <></>
            )}

            <NextSeo
              title={(
                productData?.metaSEO?.title ||
                product?.metaSEO?.title ||
                ''
              ).replace(/"/g, '')}
              description={stripeTextFromHTML(
                productData?.metaSEO?.description ||
                product?.metaSEO?.description ||
                'N/A'
              )}
              additionalMetaTags={[
                {
                  property: 'product:price.amount',
                  content: String(
                    productData?.stock?.price || product?.stock?.price
                  ),
                },
                {
                  property: 'product:price.currency',
                  content: 'GEL',
                },
                {
                  property: 'fb:app_id',
                  content: String(process.env.NEXT_PUBLIC_FB_APP_ID),
                },
              ]}
              openGraph={{
                type: 'product',
                title: (
                  productData?.metaSEO?.title ||
                  product?.metaSEO?.title ||
                  ''
                ).replace(/"/g, '\\"'),
                description: stripeTextFromHTML(
                  productData?.metaSEO?.description ||
                  product?.metaSEO?.description ||
                  'N/A'
                ),
                url: webUrl,
                images: [
                  {
                    url: getProductPrimaryImageUrl(productData),
                    alt: (productData?.title || product?.title || '').replace(
                      /"/g,
                      '\\"'
                    ),
                  },
                ],
              }}
            />
          </>
        )}

        {product ? (
          <section className={styles.mbFive}>
            <div className="metad-container">
              <div className="df">
                {routes && routes.length ? <Bread routes={routes} /> : ''}
                {onClose ? (
                  <div className={styles.closeBtn} onClick={() => onClose()}>
                    <FiX />
                  </div>
                ) : null}
              </div>
              <div className={styles.productDetails}>
                <div className={styles.productInfo}>
                  <figure className={styles.productInfo__image}>
                    {product?.images?.length ? (
                      <img
                        src={getProductPrimaryImageUrl(product)}
                        alt={product.title}
                        onError={getBrokenImage}
                      />
                    ) : (
                      <ProductCardImagePlaceholder />
                    )}
                  </figure>
                  <div className={styles.product_additionalImages}>
                    {product?.images?.length > 1
                      ? product.images?.map((value: Image) => (
                        <img
                          key={value.imageId}
                          src={getProductPrimaryImageUrl(product)}
                          alt={product.title}
                          onError={getBrokenImage}
                        />
                      ))
                      : ''}
                  </div>
                  <div className={styles.productInfo__properties}>
                    <div className="">
                      {!product.stock ? (
                        <></>
                      ) : (
                        <>
                          {isInStock() ? (
                            <>
                              {generatedText?.text ? (
                                <Label
                                  color={generatedText.color || getColor()}
                                  text={generatedText.text}
                                />
                              ) : (
                                ''
                              )}
                            </>
                          ) : !product.isPublished ? (
                            <Label
                              color="#99AEBB"
                              text={translate('ecommerce.notPublishedProduct')}
                            />
                          ) : (
                            <Label
                              color="#99AEBB"
                              text={translate('ecommerce.notInStock')}
                            />
                          )}
                        </>
                      )}
                      <div
                        className={styles.productInfo__properties__title__wrapper}
                      >
                        <div className={styles.productInfo__properties__title}>
                          <span className="like_h1">{product?.title}</span>
                        </div>

                        {!isMobile ? <ShareActions /> : null}
                      </div>

                      <div className={styles.productInfo__properties__unit}>
                        <span>
                          {product.unitType &&
                            product.unitValue &&
                            `${product.unitValue || ''} ${product.unitType || ''
                            }`}
                          &nbsp;
                        </span>
                      </div>

                      {!product.stock ? (
                        <></>
                      ) : (
                        <div className={styles.productInfo__properties__price}>
                          <span>
                            {generatePrice(product).toFixed(2)}
                            &nbsp;
                            <Lari />
                          </span>
                          {product.discount?.price ||
                            (product.loyaltyDiscount?.isClub &&
                              customerInfo?.clubMembership?.current?.isMember) ||
                            (customerInfo?.cards.length > 0 &&
                              product.loyaltyDiscount &&
                              !product.loyaltyDiscount?.isClub &&
                              !product.loyaltyDiscount?.isSpecial) ? (
                            <span
                              className={styles.productInfo__properties__subprice}
                            >
                              {product.stock.price} &nbsp;
                              <Lari />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                  {isMobile ? (
                    <div className={styles.productInfo__share__action}>
                      <div
                        className={styles.productInfo__properties__title__wrapper}
                      >
                        <ShareActionsMobile />
                      </div>
                    </div>
                  ) : null}

                  {!product.stock ? (
                    <></>
                  ) : (
                    <div className={styles.cart_wrapper} ref={parentRef}>
                      {isInStock() ? (
                        <>
                          {cartTotals.productToCountMap[product.productId] ||
                            0 > 0 ? (
                            <Quantity
                              productId={product.productId}
                              shake={parentRef.current}
                              id={product._id}
                              title={product.title}
                              countStep={product.countStep}
                              price={generatePrice(product)}
                            />
                          ) : (
                            <div ref={parentRef}>
                              <Button
                                text={translate('shop.addToCart')}
                                onClick={() => {
                                  const { inactive, msg, details } =
                                    isInactiveAddress(
                                      selectedAddress,
                                      deliveryServiceConfig,
                                      true
                                    )

                                  if (inactive) {
                                    setShowInactiveAddressModal(
                                      inactive,
                                      msg,
                                      details
                                    )
                                    return
                                  }
                                  const qnt =
                                    cartTotals.productToCountMap[
                                    product.productId
                                    ]
                                  addProductToCart(
                                    cart?._id,
                                    product.productId,
                                    product._id,
                                    'ADD',
                                    lang,
                                    qnt,
                                    user?.sub,
                                    selectedAddress?.warehouseId,
                                    customerInfo?.clubMembership?.current
                                      ?.isMember,
                                    product.countStep,
                                    cartTotals
                                  ).then((result) => {
                                    if (result) {
                                      const categoryLevels = getCategoryLevels(
                                        product.categoryId,
                                        categories,
                                        lang
                                      )
                                      tagManagerUtil.RemoveFromCart({
                                        ProductName: product.title,
                                        ProductID: product.productId,
                                        UserID: user?.sub,
                                        ProductPrice: product.discount
                                          ? product.discount.price
                                          : product.stock.price,
                                        ProductCategory: product.categoryId,
                                        Quantity: product.countStep || 1,
                                        categoryLevels,
                                      })
                                    }
                                    if (!result && parentRef.current) {
                                      parentRef.current.classList.add('shake')
                                      addToast({
                                        id:
                                          cart?._id?.toString() ||
                                          'addproducttocart',
                                        description: translate(
                                          'shop.notInStockMessage'
                                        ),
                                        type: 'info',
                                      })
                                      window.setTimeout(function () {
                                        if (parentRef.current) {
                                          parentRef.current.classList.remove(
                                            'shake'
                                          )
                                        }
                                      }, 820)
                                    }
                                  })
                                }}
                                iconRight={
                                  <img
                                    src={`/svgs/add-to-basket-white.svg`}
                                    className={styles.quantityAddToCart}
                                    alt={translate('shop.addToCart')}
                                  />
                                }
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  )}
                </div>

                <ProductModuleCarousel
                  showAllProductsLink={false}
                  productModule={{
                    prId: product.productId,
                    _id: product._id + '_digital_shelving',
                    title: translate('products.recommended.title'),
                    productIds: product.relatedProducts,
                    category: null,
                    isRelatedProduct: true,
                  }}
                />

                <div className="mt-5">
                  <div className={styles.description_title}>
                    {product.description && translate('shop.description_title')}
                  </div>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: product.description || '',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
      </>
    )
  }

export default ProductDetails
