import React, { FC, useContext, useState, useEffect } from 'react'
import { SubHeader, MobileFooterMenu, Footer } from '@components/common'
import style from './Layout.module.scss'

import 'bootstrap/dist/css/bootstrap.min.css'
import dynamic from 'next/dynamic'
import { Modal } from '@components/ui'
import { useRouter } from 'next/router'
import { ProductDetails, RecipeDetails } from '@components/index'
import CartShortCut from '../CartShortCut'
import AppContext from 'contexts/AppContext'
import Backdrop from '@components/ui/Backdrop/Backdrop'
import AddressInactiveWarnModal from '@components/Address/addressInactiveWarnModal'

interface Props {
  pageProps: {
    pages?: unknown[]
  }
}

const Header = dynamic(() => import('@components/common/Header'), {
  ssr: false,
})

const Layout: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<Props>>>> = ({ children }) => {
  const router = useRouter()
  const { cartTotals } = useContext(AppContext)
  const [visible, setvisible] = useState(false)

  let { lang, } = router.query as combined
  const { id, returnTo, uuid, recipe } = router.query as combined
  if (!lang) {
    lang = 'ge'
  }

  useEffect(() => {
    if (uuid) {
      setvisible(!!id)
    } else {
      setvisible(false)
    }
  }, [uuid])

  return (
    <>
      <div className={`${style.contentWrapper}`}>
        <Backdrop onClick={() => setvisible(false)} visible={visible} />
        <Modal
          showHeader={false}
          showFooter={false}
          visible={visible}
          backdrop={false}
          static={true}
          containerStyle={{
            width: '960px',
            maxHeight: 'initial',
            minHeight: '200px',
          }}
          style={{
            maxHeight: 'initial',
          }}
          content={
            recipe ?
              <RecipeDetails
                lang={lang}
                recipeId={id}
                onClose={() => {
                  history.pushState({}, '', returnTo)
                  setvisible(false)
                }}
              />
              :
              <ProductDetails
                webUrl={typeof location !== 'undefined' ? `${location.href}` : ''}
                host={
                  typeof location !== 'undefined'
                    ? `${location.protocol}//${location.host}`
                    : ''
                }
                lang={lang}
                productId={id}
                onClose={() => {
                  history.pushState({}, '', returnTo)
                  setvisible(false)
                }}
              />
          }
          onClose={
            () => {
              history.pushState({}, '', returnTo)
              setvisible(false)
            } //router.replace(`${returnTo}`, undefined, { shallow: true })
          }
        />
        <Header />
        {router.pathname !== '/[lang]/checkout' && cartTotals.quantity > 0 ? (
          <CartShortCut />
        ) : null}
        {router.pathname !== '/[lang]/checkout' && <SubHeader />}
        <MobileFooterMenu />
        <main className="fit">{children}</main>
        <AddressInactiveWarnModal />
        <Footer />
      </div>
    </>
  )
}

export default Layout
