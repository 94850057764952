import { getBrokenImage } from '@utils/getBrokenImage'
import React, { FC } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'

import styles from './MainPageSlider.module.scss'
import Link from 'next/link'

interface MainPageSliderProps {
  items: Array<Slide>
}

const getImageName = (image: { [key: string]: Record<string, string> }) => {
  return encodeURIComponent(
    String(image.originalName).replace(/[^\x00-\x7F]/g, '')
  );
}

const MainPageSlider: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<MainPageSliderProps>>>> = (
  props: MainPageSliderProps
) => {
  return (
    <>
      {props.items.map((item) => (

        <a target="_blank" href={item.sliderLink ? item.sliderLink : null} className={`${styles.slide} slide`} key={item._id} style={{ cursor: !item.buttonText && item.sliderLink ? 'pointer' : 'default' }}>
          <picture>
            <MobileView>
              {item.mobileImage && item.mobileImage.imageId ? (
                <img
                  src={`/api/files/download/${item.mobileImage.imageId
                    }/${getImageName(item.mobileImage)}`}
                  onError={getBrokenImage}
                  alt={item.sliderText}
                />
              ) : null}
            </MobileView>
            <BrowserView>
              {item.webImage && item.webImage.imageId ? (
                <img
                  src={`/api/files/download/${item.webImage.imageId
                    }/${getImageName(item.webImage)}`}
                  max-width={1400}
                  onError={getBrokenImage}
                  alt={item.sliderText}
                />
              ) : null}
            </BrowserView>
          </picture>
          <div className={styles.description__wrapper}>
            <div className={styles.slide__title}>
              <span style={{ color: item.textColor }}>{item.sliderText}</span>
            </div>
            {item.buttonText ? (
              <div className={styles.slide__seeMore}>
                <span>{item.buttonText}</span>
              </div>
            ) : null}
          </div>
        </a>

      ))}
    </>
  )
}

export default MainPageSlider
