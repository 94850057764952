import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import AuthService from 'services/authService'

export default class RequestHelper {
  private static KARI_BASE_URL = process.env.NEXT_PUBLIC_KARI_API
  private static CAS_BASE_URL = process.env.NEXT_PUBLIC_CAS_API
  public static RENEW_REDIRECT_URL =
    process.env.NEXT_PUBLIC_RENEW_REDIDIRECT_URL
  public static CATALOG_BASE_URL = process.env.NEXT_PUBLIC_CATALOG_API
  public static LOYALTY_BASE_URL = process.env.NEXT_PUBLIC_LOYALTY_API

  private static _kari: AxiosInstance | null
  private static _cas: AxiosInstance | null
  private static _catalog: AxiosInstance | null
  private static _loyalty: AxiosInstance | null
  private static _catalogWeb: AxiosInstance | null
  private static _authService = new AuthService()
  private static _isRefreshing = false
  private static failedRequests: { resolve; reject }[] = []

  static processQueue(error: string | null, token: string): void {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    this.failedRequests = []
  }

  public static resetAxiosInstances(): void {
    this._cas = null
    this._kari = null
    this._loyalty = null
    this._catalog = null
    this._loyalty = null
    this._catalogWeb = null
  }

  private static getToken(): string | null {
    if (process.browser) {
      const sessionKey = `oidc.user:https://${process.env.NEXT_PUBLIC_CAS_API_ISSUER}:shop`
      const session = localStorage ? localStorage.getItem(sessionKey) : null;
      if (session) {
        const data = JSON.parse(session)
        return data ? data.id_token : null
      }
    }
    return ''
  }

  private static getLanguage(): string {
    let langKey = ''
    if (process.browser) {
      const langStr = localStorage ? localStorage.getItem('language') : null;
      if (langStr) {
        langKey = JSON.parse(langStr).key
      }
      return langKey
    }
    return langKey
  }

  private static setInterceptor(instance: AxiosInstance, catchResponse = true) {
    const sessionKey = `oidc.user:https://${process.env.NEXT_PUBLIC_CAS_API_ISSUER}:shop`
    instance.interceptors.request.use(
      (request): Promise<AxiosRequestConfig> => {
        return new Promise((resolve) => {
          // eslint-disable-line
          if (process.browser) {
            const session = localStorage ? localStorage.getItem(sessionKey) : null;
            if (session) {
              const data = JSON.parse(session)
              request.headers.Authorization = data
                ? `Bearer ${data.id_token}`
                : null
            }
            return resolve(request)
          } else {
            resolve(request)
          }
        })
      }
    )
    if (catchResponse) {
      instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          let errorMsg = ''
          if (error.response) {
            errorMsg = error.response.data.message
          } else {
            errorMsg = error.toJSON()
          }
          if (error && error.response && error.response.status === 401) {
            if (this._isRefreshing) {
              try {
                const token = await new Promise((resolve, reject) => {
                  this.failedRequests.push({ resolve, reject })
                })
                error.config.headers.Authorization = `Bearer ${token}`
                return instance(error.config)
              } catch (e) {
                return e
              }
            }
            this._isRefreshing = true
            error.config.__isRetryRequest = true

            const result = await this._authService.signinSilent()
            this.resetAxiosInstances()
            if (result) {
              error.config.headers.Authorization = `Bearer ${result.id_token}`
              this._isRefreshing = false
              this.processQueue(null, result.id_token)
              axios.request(error.config)
            } else {
              Promise.reject(error.response)
            }
          }
          return Promise.reject({ errorMessage: errorMsg, error: error.response.data })
        }
      )
    }
  }

  static get kari(): AxiosInstance {
    if (!this._kari) {
      this._kari = axios.create({
        baseURL: this.KARI_BASE_URL,
        headers: {
          'Project-Key': 'KARI',
          'Accept-Language': this.getLanguage(),
          Authorization: `Bearer ${this.getToken()}`,
        },
      })

      this.setInterceptor(this._kari)
    }

    return this._kari
  }

  static get loyalty(): AxiosInstance {
    if (!this._loyalty) {
      this._loyalty = axios.create({
        baseURL: this.LOYALTY_BASE_URL,
        headers: {
          'Project-Key': 'LOYALTY',
          Authorization: `Bearer ${this.getToken()}`,
        },
      })

      this.setInterceptor(this._loyalty)
    }
    return this._loyalty
  }

  static get catalog(): AxiosInstance {
    if (!this._catalog) {
      this._catalog = axios.create({
        baseURL: this.CATALOG_BASE_URL,
        headers: {
          'Project-Key': 'CATALOG',
          Language: this.getLanguage(),
          'Accept-Language': this.getLanguage(),
          Authorization: `Bearer ${this.getToken()}`,
        },
      })

      this.setInterceptor(this._catalog)
    }

    return this._catalog
  }

  static get catalogWeb(): AxiosInstance {
    if (!this._catalogWeb) {
      this._catalogWeb = axios.create({
        baseURL: this.CATALOG_BASE_URL,
        headers: {
          'Project-Key': 'CATALOG',
          Language: this.getLanguage(),
          'Accept-Language': this.getLanguage(),
          Authorization: `Bearer ${this.getToken()}`,
        },
      })

      this.setInterceptor(this._catalogWeb)
    }

    return this._catalogWeb
  }

  static get cas(): AxiosInstance {
    if (!this._cas) {
      this._cas = axios.create({
        baseURL: this.CAS_BASE_URL,
        headers: {
          'Project-Key': 'CAS',
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      this.setInterceptor(this._cas)
    }

    return this._cas
  }

  static get nonCasCatchResponse(): AxiosInstance {
    if (!this._cas) {
      this._cas = axios.create({
        baseURL: this.CAS_BASE_URL,
        headers: {
          'Project-Key': 'CAS',
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      this.setInterceptor(this._cas, false)
    }

    return this._cas
  }

  static reset() {
    this._kari = null;
    this._cas = null;
    this._catalog = null;
    this._loyalty = null;
    this._catalogWeb = null;
  }
}
