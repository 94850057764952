import React, { useContext, useEffect } from 'react'
import { UseTranslate } from 'hooks/Translate'
import styles from './CartShortCut.module.scss'
import cn from 'classnames'
import { Lari } from '@components/ui/Icon/Icon'
import AppContext from 'contexts/AppContext'
import { isMobile } from 'react-device-detect'

const CartShortCut = () => {
  const [translate] = UseTranslate()
  const { cartTotals, openCart, setOpenCart } = useContext(AppContext)

  const cartMiniCartClassName = cn({
    [styles.productCartsm]: true,
  })

  return (
    <div
      className={cartMiniCartClassName}
      onClick={() => {
        setOpenCart()
      }}
    >
      <div className={styles.productCart__footer__button}>
        <div className={styles.productCart__footer_total_price}>
          {cartTotals.totalPrice.toFixed(2)} <Lari />
        </div>
        <div className={styles.productCart__footer_go_to_cart}>
          {translate('cartPopup.cart')}
          <svg width="15px" height="14px" viewBox="0 0 15 14">
            <path d="M10.586,5.645 L6.636,1.695 C6.257,1.302 6.262,0.679 6.648,0.293 C7.034,-0.093 7.658,-0.098 8.05,0.281 L13.707,5.938 C13.895,6.125 14.001,6.379 14.001,6.645 C14.001,6.91 13.895,7.165 13.707,7.352 L8.05,13.009 C7.799,13.269 7.427,13.373 7.077,13.281 C6.727,13.19 6.455,12.917 6.363,12.567 C6.272,12.217 6.376,11.846 6.636,11.595 L10.586,7.645 L1,7.645 C0.448,7.645 0,7.197 0,6.645 C0,6.092 0.448,5.645 1,5.645 L10.586,5.645 L10.586,5.645 Z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default CartShortCut
