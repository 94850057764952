import React, { FC, useState, useEffect } from 'react'
import styles from './Toast.module.scss'
import cn from 'classnames'
import { FiCheck, FiX, FiInfo, FiAlertTriangle } from 'react-icons/fi'
import { IconType } from 'react-icons/lib/cjs'

export interface ToastInfo {
  id: string
  title?: string
  description?: string
  backgroundColor?: string
  icon?: IconType
  dismissTime?: number
  type?: string
  iconBackgroundColor?: string
}

interface ToastProps {
  toastList: ToastInfo[]
  position: string
  autoDelete: boolean
  dismissTime: number
}

const Toast: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<ToastProps>>>> = (props: ToastProps) => {
  const { toastList, autoDelete, dismissTime } = props
  const [list, setList] = useState(toastList)

  useEffect(() => {
    setList([...toastList])

    // eslint-disable-next-line
  }, [toastList])

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id)
      }
    }, dismissTime)

    return () => {
      clearInterval(interval)
    }

    // eslint-disable-next-line
  }, [toastList, autoDelete, dismissTime, list])

  const deleteToast = (id: string) => {
    const listItemIndex = list.findIndex((e) => e.id === id)
    const toastListItem = toastList.findIndex((e) => e.id === id)
    list.splice(listItemIndex, 1)
    toastList.splice(toastListItem, 1)
    setList([...list])
  }

  const baseContainer = cn({
    [styles.notificationContainer]: true,
    [styles.bottomRight]: true,
  })

  const container = cn({
    [styles.notificationContainer]: true,
    [styles.toast]: true,
    [styles.bottomRight]: true,
  })

  const getBackgroundColor = (toast: ToastInfo): string => {
    switch (toast.type) {
      case 'success':
        return '#007A3E'
      case 'fail':
        return '#EC5D2A'
      case 'warning':
        return '#FCA652'
      case 'info':
        return '#99AEBB'
      default:
        return toast.backgroundColor || ''
    }
  }

  const getIconBackgroundColor = (toast: ToastInfo): string => {
    switch (toast.type) {
      case 'success':
        return '#006031'
      case 'fail':
        return '#C13C0D'
      case 'warning':
        return '#DA822C'
      case 'info':
        return '#7A909D'
      default:
        return toast.iconBackgroundColor || ''
    }
  }

  return (
    <>
      <div className={baseContainer}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={container}
            style={{
              backgroundColor: getBackgroundColor(toast),
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              className={styles.notificationImage}
              style={{ backgroundColor: getIconBackgroundColor(toast) }}
            >
              {(() => {
                switch (toast.type) {
                  case 'success':
                    return <FiCheck />
                  case 'fail':
                    return <FiX />
                  case 'warning':
                    return <FiAlertTriangle />
                  case 'info':
                    return <FiInfo />
                  default:
                    return toast.icon
                }
              })()}
            </div>
            <div>
              <p className={styles.notificationMessage}>{toast.description}</p>
            </div>
            <button onClick={() => deleteToast(toast.id)}>
              <FiX />
            </button>
          </div>
        ))}
      </div>
    </>
  )
}

export default Toast
