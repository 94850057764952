import classNames from 'classnames'
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import styles from './TextInput.module.scss'

interface TextInputProps {
  id: string
  label?: string
  name: string
  value?: string
  style?: CSSProperties
  bodyStyle?: CSSProperties
  onChange?: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder: string
  disabled?: boolean
  readOnly?: boolean
  hasError?: boolean
  infoText?: string
  errorText?: string
}

const TextInput: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<TextInputProps>>
  >
> = (props: TextInputProps) => {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.value) {
      setValue(props?.value)
      if (inputRef.current) {
        inputRef.current.value = props.value
      }
    }
  }, [props.value])

  const disabledClass = classNames({
    [styles.disabled]: props.disabled,
  })

  const inputClasses = classNames({
    [styles.hasError]: props.hasError,
  })

  return (
    <div className={styles.container} style={props.bodyStyle}>
      <div className="df fd">
        {props.label && (
          <label htmlFor={props.id} className={disabledClass}>
            {props.label}
          </label>
        )}
        {props.infoText ? (
          <>
            <span data-tip data-for="registerTip" className={styles.infoIcon}>
              !
            </span>
            <ReactTooltip
              id="registerTip"
              place="top"
              effect="solid"
              className={styles.tooltip}
              arrowColor={'#a5d444'}
              backgroundColor={'#a5d444'}
            >
              {props.infoText}
            </ReactTooltip>
          </>
        ) : (
          ''
        )}
      </div>

      <fieldset className={inputClasses}>
        <input
          id={props.id}
          type="text"
          name={props.name}
          ref={inputRef}
          autoComplete="off"
          style={props.style}
          onBlur={() => (props.onBlur ? props.onBlur() : null)}
          onFocus={() => (props.onFocus ? props.onFocus() : null)}
          onKeyDown={(e) => (props.onKeyDown ? props.onKeyDown(e) : null)}
          onInput={(e) =>
            props.onChange ? props.onChange(e.currentTarget.value) : null
          }
          placeholder={props.placeholder}
          defaultValue={value}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
        <div className={styles.errorIcon}>!</div>
        <div className={styles.errorText}>
          {props.errorText || 'გთხოვთ შეავსოთ ველი'}
        </div>
      </fieldset>
    </div>
  )
}

TextInput.defaultProps = {
  disabled: false,
  hasError: false,
}

export default TextInput
