import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'

const useScroll = () => {
  const scrollY = useRef(0)
  const router = useRouter()
  const [scrollUp, setScrollUp] = useState(false)
  const [currentY, setCurrentY] = useState(0)

  useEffect(() => {
    const onScroll = (e) => {
      const window = e.currentTarget
      if (scrollY.current < 100) {
        setScrollUp(false)
      } else {
        if (scrollY.current > window.scrollY) {
          setScrollUp(true)
        } else if (scrollY.current < window.scrollY) {
          setScrollUp(false)
        }
      }

      scrollY.current = window.scrollY
      setCurrentY(window.scrollY)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    setScrollUp(false)
  }, [router])

  return useMemo(() => {
    return [scrollUp, currentY]
  }, [scrollUp, currentY])
}

export default useScroll
