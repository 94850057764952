import React, { memo, useState } from 'react'

import RequestHelper from '@utils/RequestHelper'

interface CategoryContext {
  loading: boolean
  setLoading: (value?: boolean) => void
  categoriesData: Category[]
  loadCategories: (lang: string | 'ge') => void
  setCategoriesData: (categories: Category[]) => void
}

const initialState: CategoryContext = {
  loading: false,
  setLoading: () => undefined,
  categoriesData: [],
  loadCategories: () => undefined,
  setCategoriesData: () => undefined
}

const CategoryContext = React.createContext<CategoryContext>(initialState)

export const CategoryProvider = memo((props: combined) => {
  const [state, setState] = useState<CategoryContext>({
    ...initialState,

    loadCategories: async (lang: string | 'ge') => {
      state.setLoading()
      try {
        const resp = await RequestHelper.catalog(`categories/thin?lang=${lang}`)
        if (resp && resp.data && resp.data.data) {
          const categories = resp.data.data
          setState((state) => ({
            ...state,
            categoriesData: categories,
          }))
        }
      } catch (e) {
        console.error(e)
      }
      state.setLoading()
    },

    setLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        loading: typeof value === 'boolean' ? value : !state.loading,
      }))
    },
    setCategoriesData: (categories: Category[]) => {
      setState((state) => ({
        ...state,
        categoriesData: categories,
      }))
    }
  })

  return (
    <CategoryContext.Provider value={state}>
      {props.children}
    </CategoryContext.Provider>
  )
})

export default CategoryContext
