import React, { CSSProperties, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { FiMenu } from 'react-icons/fi'
import styles from './Header.module.scss'
import { UseTranslate } from 'hooks/Translate'
import cn from 'classnames'
import AppContext from '../../../contexts/AppContext'
import {
  LogoIcon,
  ShoppingCartGray,
  ShoppingCartWhite,
  UserProfileIconGrey,
} from '@components/ui/Icon/Icon'
import { LanguageSelector, SearchField } from '..'
import { Button } from '@components/ui'
import MobileNavigation from './MobileNavigation'
import { isMobile } from 'react-device-detect'
import AuthUser from './AuthUser'
import CartButtonSkeleton from './CartButtonSkeleton/CartButtonSkeleton'
import AuthUserSkeleton from './AuthUserSkeleton/AuthUserSkeleton'
import { useUser } from '@auth0/nextjs-auth0'
import AddressSelectorSkeleton from './AddressSelectorSkeleton/AddressSelectorSkeleton'
import UserContext from 'contexts/UserContext'
import tagManagerUtil from '@utils/tagManagerUtil'
import useScroll from 'hooks/Scroll'
import LanguageSelectorSkeleton from './LanguageSelectorSkeleton/LanguageSelectorSkeleton'
import RequestHelper from '@utils/RequestHelper'
import Link from 'next/link'
import {
  BrazeCustomAttributes,
  GetBrazeDetailsToSessionStorage,
  SetBrazeDetailsToSessionStorage,
} from '@utils/BrazeDefaultUserDetails'
import { validateEmail } from '@utils/orderUtil'

const citySateWrapperHeight = 37

const SideBar = dynamic(() => import('@components/ui/SideBar/SideBar'), {
  ssr: false,
})

const AddressSelector = dynamic(
  () => import('@components/Address/AddressSelector'),
  {
    ssr: false,
  }
)

const Header = () => {
  const router = useRouter()
  const isCheckoutPage = router.pathname == '/[lang]/checkout'

  const [translate] = UseTranslate()
  const {
    setOpenCart,
    cartTotals,
    configLoading,
    cartLoading,
    cityState,
    loadCityStates,
    setSearchValue,
    selectedLanguage,
    isInactiveAddress,
    deliveryServiceConfig,
    setShowInactiveAddressModal,
  } = useContext(AppContext)

  const { user, isLoading } = useUser()
  const [scrollUp, scrollY] = useScroll()

  const { addressesLoading, selectedAddress } = useContext(UserContext)
  const { setRecipeIsActive } = useContext(AppContext)

  const [searchFocused, setSearchFocused] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [citySateText, setCitySateText] = useState({})
  const [citySateVisible, setCitySateVisible] = useState(false)

  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const floatHeader = scrollY > citySateWrapperHeight

  const headerWrapper = cn({
    [styles.wrapper]: true,
    [styles.floating]: !isMobile && floatHeader && !isCheckoutPage,
  })

  const toggleCartOpen = () => {
    const { inactive, msg, details } = isInactiveAddress(
      selectedAddress,
      deliveryServiceConfig,
      true
    )

    if (inactive) {
      setShowInactiveAddressModal(inactive, msg, details)
      return
    }
    setOpenCart()
  }

  const onLoginClick = async () => {
    window.open(
      '/api/auth/login?lang=' + (localStorage.getItem('lang') || 'ge'),
      '_self'
    )
  }

  const onNavigateHome = () => {
    setSearchValue('')
    loadCityStates(selectedAddress?.warehouseId)
  }

  const getConfigData = async () => {
    const pointAdditionActiveResponse = await RequestHelper.catalog.get(
      '/configs/calculatePointForOrder'
    )
    setRecipeIsActive(pointAdditionActiveResponse?.data?.data?.recipeIsActive)
  }

  useEffect(() => {
    getConfigData()
  }, [])

  useEffect(() => {
    if (user && user.isNewUser) {
      let regData: string[]

      try {
        const _regData = localStorage.getItem('Registration')
        regData = JSON.parse(_regData || '[]')
      } catch {
        regData = []
      }

      if (regData.indexOf(user.phoneNumber as string) >= 0) return

      regData.push(user.phoneNumber as string)
      localStorage.setItem('Registration', JSON.stringify(regData))
      tagManagerUtil.Registration({
        UserID: user.userId as string,
        PhoneNumber: user.phoneNumber as string,
      })
    }
    if (user?.sub) {
      const language = localStorage.getItem('lang')
      RequestHelper.kari.put('/users/language', { language: language || 'ge' })
      setBrazeUserDefault()
    }
  }, [user])

  const setBrazeUserDefault = async () => {
    const detailsFromStorage = GetBrazeDetailsToSessionStorage(
      user.userId as string
    )
    if (!detailsFromStorage) {
      try {
        const res = await RequestHelper.catalog.get('/braze/custom')
        const { data } = res.data

        SetBrazeDetailsToSessionStorage(user.userId as string, data)

        const { getUser, changeUser } = await import('@braze/web-sdk')

        //set default properties
        changeUser(user.userId as string)
        getUser().setFirstName((user.firstName as string) || '')
        getUser().setLastName((user.lastName as string) || '')
        if (validateEmail(user.userName as string)) {
          getUser().setEmail((user.userName as string) || '')
        }
        getUser().setPhoneNumber((user.phoneNumber as string) || '')
        getUser().setLanguage(lang === 'ge' ? 'ka' : (lang as string))

        //set customer attributes
        Object.keys(data).forEach((key) => {
          if (BrazeCustomAttributes.includes(key)) {
            getUser().setCustomUserAttribute(key, data[key])
          }
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
  useEffect(() => {
    if (selectedAddress?.warehouseId) {
      loadCityStates(selectedAddress.warehouseId, selectedAddress.type)
    } else if (!user) {
      loadCityStates()
    }
  }, [selectedAddress])

  useEffect(() => {
    setCitySateVisible(false)
    if (cityState) {
      setCitySateText(cityState.message)
      setCitySateVisible(true)
      return
    } else {
      setCitySateVisible(false)
    }
  }, [cityState, user])

  const shoppingCartStyles: CSSProperties = {
    height: '42px',
    fontSize: '18px',
  }
  return (
    <div>
      {citySateVisible ? (
        <div className={styles.citySateWrapper}>
          <div className={styles.citySateContent}>
            {citySateText && citySateText[lang.toString()]
              ? citySateText[lang.toString()]
              : ''}
          </div>
        </div>
      ) : null}
      {!isMobile && floatHeader && !isCheckoutPage && (
        <div className={styles.wrapper}>
          <div className="metad-container">
            <div className={styles.container} />
          </div>
        </div>
      )}
      <div className={headerWrapper}>
        <div className="metad-container">
          {isCheckoutPage ? (
            <div className={styles.checkoutHeaderContainer}>
              <Link
                href={`${location.origin}/${selectedLanguage?.key || 'ge'}`}
              >
                <a
                  className={styles.logoCheckout}
                  onClick={() => onNavigateHome()}
                >
                  <LogoIcon />
                </a>
              </Link>
            </div>
          ) : (
            <div className={styles.container}>
              <div className={styles.logoWrapper}>
                <div
                  className={styles.burgerMenu}
                  onClick={() => setShowSideBar(true)}
                >
                  <FiMenu />
                </div>
                <Link
                  href={`${location.origin}/${selectedLanguage?.key || 'ge'}`}
                >
                  <a className={styles.logo} onClick={() => onNavigateHome()}>
                    <LogoIcon />
                  </a>
                </Link>
              </div>
              <div className={styles.search}>
                <SearchField
                  warehouseId={selectedAddress?.warehouseId}
                  suggestionsOpen={(isOpen: boolean) =>
                    setSearchFocused(isOpen)
                  }
                />
              </div>
              <div
                className={styles.delivery}
                style={{ display: searchFocused && !isMobile ? 'none' : '' }}
              >
                {addressesLoading || configLoading ? (
                  <AddressSelectorSkeleton />
                ) : (
                  <AddressSelector />
                )}
              </div>
              <div className={styles.rightSide}>
                <div className={styles.languageSelector}>
                  {!isMobile ? (
                    configLoading ? (
                      <LanguageSelectorSkeleton />
                    ) : (
                      <LanguageSelector />
                    )
                  ) : null}
                </div>
                <div className={styles.login}>
                  {isLoading ? (
                    <AuthUserSkeleton />
                  ) : user ? (
                    <AuthUser />
                  ) : isMobile ? (
                    <span onClick={onLoginClick}>
                      <UserProfileIconGrey width="24" height="24" />
                    </span>
                  ) : (
                    <div className={styles.loginButton}>
                      <Button
                        type="additional"
                        onClick={onLoginClick}
                        iconLeft={<UserProfileIconGrey />}
                        text={translate('ecommerce.login')}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.cart} onClick={toggleCartOpen}>
                  {cartLoading ? (
                    <CartButtonSkeleton />
                  ) : cartTotals.totalPrice ? (
                    <Button
                      style={shoppingCartStyles}
                      iconLeft={<ShoppingCartWhite />}
                      text={`${
                        Math.round(cartTotals.totalPrice * 100) / 100
                      } ₾`}
                    />
                  ) : (
                    <ShoppingCartGray />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {isMobile ? (
          <SideBar
            visible={showSideBar}
            onClose={() => setShowSideBar(false)}
            content={
              <MobileNavigation onSelect={() => setShowSideBar(false)} />
            }
            title={translate('ecommerce.menu')}
            footer={
              <footer className={styles.footer}>
                <div className={styles.title}>
                  {translate('ecommerce.changeLanguage')}
                </div>
                <div className={styles.languages}>
                  <LanguageSelector onSelect={() => setShowSideBar(false)} />
                </div>
              </footer>
            }
          />
        ) : null}
      </div>
    </div>
  )
}

export default Header
