import useSpecialDiscount from 'hooks/SpecialDiscount'
import { useEffect, useReducer, useRef } from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'
import styles from './Quantity.module.scss'

type Props = {
  spend: number
  accumulatedPoints: number
  product: CartProduct
  specialProducts: SpecialProduct[]
  onChange: (value: number) => void
}

export default function SpecialQuantity({
  spend,
  accumulatedPoints,
  product,
  specialProducts,
  onChange,
}: Props) {
  const shakeRef = useRef<HTMLDivElement>(null)

  const { cntMap } = useSpecialDiscount()

  const [state, dispatch] = useReducer((state: number, action: string) => {
    const shake = () => {
      shakeRef.current.classList.add('shake')
      window.setTimeout(() => {
        shakeRef.current.classList.remove('shake')
      }, 820)
    }

    if (action === 'DECREMENT') {
      if (state === 0) {
        shake()
        return state
      }

      return state - (product.countStep || 1)
    }

    if (action === 'INCREMENT') {
      if (
        state === product.quantity ||
        product.loyaltyDiscount.discountPriceInPoints + spend >
          accumulatedPoints
      ) {
        shake()
        return state
      }

      return state + (product.countStep || 1)
    }

    if (action === 'RESET') {
      return cntMap.get(product.prodId) || 0
    }

    throw new Error('Unknown Action')
  }, 0)

  useEffect(() => onChange(state), [state])

  useEffect(() => dispatch('RESET'), [cntMap])

  return (
    <span className={styles.roundQuantity} ref={shakeRef}>
      <FiMinus
        size="22px"
        color="#007A3E"
        onClick={() => dispatch('DECREMENT')}
      />
      <span>{state}</span>
      <FiPlus
        size="28px"
        color="#007A3E"
        onClick={() => dispatch('INCREMENT')}
      />
    </span>
  )
}
