import { FC } from 'react'

import cn from 'classnames'

import styles from './OrderAction.module.scss'

interface IOrderAction {
  onClick?: () => void
  icon: string
  title: string
  active: boolean
}

const OrderAction: FC<IOrderAction> = ({ onClick, icon, title, active }) => {
  const orderContainer = cn({
    [styles.container]: true,
    [styles.active]: active,
  })

  return (
    <div className={styles.wrapper}>
      <div className={orderContainer} onClick={onClick}>
        <div className={styles.icon}>{icon}</div>
      </div>
      <div className={styles.actionTitle}>{title}</div>
    </div>
  )
}

export default OrderAction
