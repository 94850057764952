import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import styles from './Check.module.scss'

interface CheckProps {
  disabled?: boolean
  id: string
  value?: string | number
  onChange?: (value: string) => void
  checked?: boolean
  defaultChecked?: boolean
  name: string
  label: string
}

const Check: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<CheckProps>>>> = (props: CheckProps) => {
  const [checked, setChecked] = useState(false)

  const disabledClass = classNames({
    disabled: props.disabled,
  })

  useEffect(() => {
    setChecked(props.checked as boolean)
  }, [props.checked])

  return (
    <div className={styles.container}>
      <input
        className={styles.check}
        name={props.name}
        value={props.value}
        onChange={(e) =>
          props.onChange ? props.onChange(e.target.value) : null
        }
        type="checkbox"
        id={props.id}
        checked={checked}
        disabled={props.disabled}
      />
      <label htmlFor={props.id} className={disabledClass}>
        {props.label}
      </label>
    </div>
  )
}

export default Check
