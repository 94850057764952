import React from 'react'
import { useRouter } from 'next/router'
import styles from './ProductModuleCarousel.module.scss'
import { isMobile } from 'react-device-detect'
import { TProductModule } from '../../../@types/ProductModule'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getBannerImageUrl } from '@utils/getImage'
import Link from 'next/link'

type Props = {
  productModule: TProductModule
}

const BannerModule = React.memo((props: Props) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const GetImage = () => {
    return !isMobile ? (
      props.productModule?.webImage &&
        props.productModule.webImage[lang as string] ? (
        props.productModule.bannerLink && props.productModule.bannerLink[lang as string] ?
          <Link href={props.productModule.bannerLink[lang as string]}>
            <img
              max-width={1400}
              src={getBannerImageUrl(props.productModule, lang as string)}
              onError={getBrokenImage}
              alt={props.productModule.title}
            />
          </Link> :
          <img
            max-width={1400}
            src={getBannerImageUrl(props.productModule, lang as string)}
            onError={getBrokenImage}
            alt={props.productModule.title}
          />
      ) : null
    ) : props.productModule?.mobileImage &&
      props.productModule.mobileImage[lang as string] ? (
      props.productModule.bannerLink && props.productModule.bannerLink[lang as string] ?
        <Link href={props.productModule.bannerLink[lang as string]}>
          <img
            src={getBannerImageUrl(props.productModule, lang as string, true)}
            onError={getBrokenImage}
            alt={props.productModule.title}
          />
        </Link> :
        <img
          src={getBannerImageUrl(props.productModule, lang as string, true)}
          onError={getBrokenImage}
          alt={props.productModule.title}
        />
    ) : null
  }

  return (
    <div className={`row ${styles.withMargin}`}>
      <div className={`col-md-12 ${styles.maxWidth}`}>
        {props.productModule.bannerType === 'oneBanner' ? (
          <div className={styles.banner__oneBanner}>
            <GetImage />
          </div>
        ) : !isMobile ? (
          <div className={styles.banner__twoBanner}>
            {props.productModule?.webImage &&
              props.productModule.webImage[lang as string] ? (
              props.productModule.bannerLink && props.productModule.bannerLink[lang as string] ?
                <Link href={props.productModule.bannerLink[lang as string]}>
                  <img
                    max-width={700}
                    src={getBannerImageUrl(props.productModule, lang as string)}
                    onError={getBrokenImage}
                    alt={props.productModule.title}
                  />
                </Link> :
                <img
                  max-width={700}
                  src={getBannerImageUrl(props.productModule, lang as string)}
                  onError={getBrokenImage}
                  alt={props.productModule.title}
                />

            ) : null}
            {props.productModule?.webImageSecond &&
              props.productModule.webImageSecond[lang as string] ? (
              props.productModule.bannerLink && props.productModule.bannerLink[lang as string] ?
                <Link href={props.productModule.bannerLink[lang as string]}>
                  <img
                    max-width={700}
                    src={getBannerImageUrl(
                      props.productModule,
                      lang as string,
                      false,
                      'webImageSecond'
                    )}
                    onError={getBrokenImage}
                    alt={props.productModule.title}
                  />
                </Link> :
                <img
                  max-width={700}
                  src={getBannerImageUrl(
                    props.productModule,
                    lang as string,
                    false,
                    'webImageSecond'
                  )}
                  onError={getBrokenImage}
                  alt={props.productModule.title}
                />
            ) : null}
          </div>
        ) : (
          <div className={styles.banner__twoBanner}>
            {props.productModule?.mobileImage &&
              props.productModule.mobileImage[lang as string] ? (
              props.productModule.bannerLink && props.productModule.bannerLink[lang as string] ?
                <Link href={props.productModule.bannerLink[lang as string]}>
                  <img
                    src={getBannerImageUrl(
                      props.productModule,
                      lang as string,
                      true
                    )}
                    onError={getBrokenImage}
                    alt={props.productModule.title}
                  />
                </Link> :
                <img
                  src={getBannerImageUrl(
                    props.productModule,
                    lang as string,
                    true
                  )}
                  onError={getBrokenImage}
                  alt={props.productModule.title}
                />
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
});
export default BannerModule
