import { AppProps } from 'next/app'

import {
  CartPopup,
  SpecialDiscountPopup,
  MobileBanner,
  AndroidBanner,
} from '@components/index'
import { Layout, Head } from '@components/common'

import { AppProvider } from 'contexts/AppContext'

import '../public/fonts/stylesheet.scss'
import './app.scss'
import { UserDataProvider } from '../contexts/UserContext'
import { CategoryProvider } from 'contexts/CategoryContext'
import { ToastContextProvider } from 'contexts/ToastContext'
import { UserProvider } from '@auth0/nextjs-auth0'
import { FC } from 'react'
import RateOrderModal from '@components/RateOrder/RateOrderModal'

const App: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<AppProps>>
  >
> = ({ Component, pageProps }: AppProps) => {
  return (
    <AppProvider>
      <ToastContextProvider>
        <CategoryProvider>
          <UserProvider>
            <UserDataProvider>
              <Head pageProps={pageProps} />
              <CartPopup />
              <SpecialDiscountPopup />
              <MobileBanner />
              <AndroidBanner />
              <Layout pageProps={pageProps}>
                <Component {...pageProps} />
              </Layout>
              <RateOrderModal />
            </UserDataProvider>
          </UserProvider>
        </CategoryProvider>
      </ToastContextProvider>
    </AppProvider>
  )
}

export default App
