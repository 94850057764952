import { UseTranslate } from 'hooks/Translate'
import { FC, useEffect, useState } from 'react'
import styles from './AndroidBanner.module.scss'

const AndroidBanner: FC = () => {
  const [translate] = UseTranslate()

  const [isAndroid, setIsAndroid] = useState(false)
  const [showBanner, setShowBanner] = useState(true)

  const onClickClose = () => {
    localStorage.setItem('android-banner', JSON.stringify(true))
    setShowBanner(false)
  }

  useEffect(() => {
    const matches = navigator.userAgent.match(/Android/i)
    const _isAndroid = matches !== null
    setIsAndroid(_isAndroid)
    const _showBanner = localStorage.getItem('android-banner') === null
    setShowBanner(_showBanner)
  }, [])

  return (
    <>
      {isAndroid && showBanner && (
        <div className={styles.androidBanner}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClickClose}
          >
            <path
              d="M3.13802 2.19531L2.19531 3.13802L7.05729 8L2.19531 12.862L3.13802 13.8047L8 8.94271L12.862 13.8047L13.8047 12.862L8.94271 8L13.8047 3.13802L12.862 2.19531L8 7.05729L3.13802 2.19531Z"
              fill="white"
            />
          </svg>
          <span style={{ marginRight: 12 }}></span>
          <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.0073 40H8.99708C4.03044 39.9944 0.00556799 35.97 0 31.0039V8.99609C0.00556799 4.02999 4.03044 0.00556737 8.99708 0H31.0073C35.974 0.00556737 39.9989 4.02999 40.0044 8.99609V31.0039C39.9989 35.97 35.974 39.9944 31.0073 40ZM8.99708 4.08637C6.28638 4.08916 4.08961 6.28568 4.08683 8.99609V31.0039C4.08961 33.7143 6.28638 35.9108 8.99708 35.9136H31.0073C33.718 35.9108 35.9148 33.7143 35.9176 31.0039V8.99609C35.9148 6.28568 33.718 4.08916 31.0073 4.08637H8.99708Z"
              fill="#93D500"
            />
            <path
              d="M32.5733 32.5698H7.43103V26.1397H32.5733V32.5698ZM24.8139 7.43024H7.43103V13.8603H32.5733C31.869 10.1306 28.61 7.4299 24.8139 7.43024ZM15.1854 16.785C11.3842 16.7869 8.12459 19.4983 7.43103 23.2353H24.8139C28.6163 23.2338 31.8776 20.5227 32.5733 16.785H15.1854Z"
              fill="#007A3E"
            />
          </svg>
          <div className={styles.meta}>
            <div>{translate('androidBannerTitle')}</div>
            <div>{translate('androidBannerSubtitle')}</div>
          </div>
          <span className={styles.spacer}></span>
          <a
            href="https://play.google.com/store/apps/details?id=ge.orinabiji.shop"
            className={styles.downloadLink}
            target="_blank"
            rel="noreferrer"
          >
            {translate('androidBannerInstall')}
          </a>
        </div>
      )}
    </>
  )
}

export default AndroidBanner
