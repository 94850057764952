export enum OrderState {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  IN_PROGRESS = 'LOADING',
}

interface IOrderStatus {
  [key: number]: {
    text: string
    state: OrderState
  }
}

export const statusMap: IOrderStatus = {
  0: {
    text: 'shop.orders.draft',
    state: OrderState.IN_PROGRESS,
  },
  1: {
    text: 'shop.orders.current',
    state: OrderState.IN_PROGRESS,
  },
  2: {
    text: 'shop.orders.processing',
    state: OrderState.IN_PROGRESS,
  },
  3: {
    text: 'shop.orders.readyForPickup',
    state: OrderState.IN_PROGRESS,
  },
  4: {
    text: 'shop.orders.pickedUp',
    state: OrderState.SUCCESS,
  },
  5: {
    text: 'shop.orders.finished',
    state: OrderState.SUCCESS,
  },
  6: {
    text: 'shop.orders.canceled',
    state: OrderState.ERROR,
  },
  7: {
    text: 'shop.orders.rejectedByPayment',
    state: OrderState.ERROR,
  },
}

export const pickupStatusMap: IOrderStatus = {
  0: {
    text: 'shop.orders.pickUp.draft',
    state: OrderState.IN_PROGRESS,
  },
  1: {
    text: 'shop.orders.pickUp.current',
    state: OrderState.IN_PROGRESS,
  },
  2: {
    text: 'shop.orders.pickUp.processing',
    state: OrderState.IN_PROGRESS,
  },
  3: {
    text: 'shop.orders.pickUp.readyForPickup',
    state: OrderState.SUCCESS,
  },
  4: {
    text: 'shop.orders.pickUp.pickedUp',
    state: OrderState.SUCCESS,
  },
  5: {
    text: 'shop.orders.pickUp.pickupFinished',
    state: OrderState.SUCCESS,
  },
  6: {
    text: 'shop.orders.pickUp.canceled',
    state: OrderState.ERROR,
  },
  7: {
    text: 'shop.orders.pickUp.rejectedByPayment',
    state: OrderState.ERROR,
  },
}

export const formatPickUpTime = (d: Date): string => {
  const hours = d.getHours()
  const minutes = d.getMinutes()
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}
