import React, { FC } from 'react'
import styles from './ProductSkeleton.module.scss'


const ProductSkeletonSingle: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<unknown>>>> = () => {
  return (
    <div className={styles.holder}>
      <section className={styles.mainContainer}>
        <article className={styles.background}>
          <div className={styles.c}></div>
          <div className={styles.d}></div>
          <div className={styles.e}></div>
          <div className={styles.f}></div>
          <div className={styles.g}></div>
        </article>
      </section>
      <section className={styles.mainContainer}>
        <article className={styles.background}>
          <div className={styles.c}></div>
          <div className={styles.d}></div>
          <div className={styles.e}></div>
          <div className={styles.f}></div>
          <div className={styles.g}></div>
        </article>
      </section>
      <section className={styles.mainContainer}>
        <article className={styles.background}>
          <div className={styles.c}></div>
          <div className={styles.d}></div>
          <div className={styles.e}></div>
          <div className={styles.f}></div>
          <div className={styles.g}></div>
        </article>
      </section>
      <section className={styles.mainContainer}>
        <article className={styles.background}>
          <div className={styles.c}></div>
          <div className={styles.d}></div>
          <div className={styles.e}></div>
          <div className={styles.f}></div>
          <div className={styles.g}></div>
        </article>
      </section>
    </div>
  )
}

export default ProductSkeletonSingle
