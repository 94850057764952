import React from 'react'

import styles from './Backdrop.module.scss'

interface BackdropProps {
  onClick?: () => void
  transparent?: boolean
  visible: boolean
  zIndex?: number
}

const Backdrop = (props: BackdropProps) => {
  return (
    <>
      {props.visible ? (
        <div
          style={{
            zIndex: props.zIndex,
            backgroundColor: props.transparent ? 'transparent' : '',
          }}
          onClick={() => (props.onClick ? props.onClick() : null)}
          className={styles.container}
        ></div>
      ) : null}
    </>
  )
}

export default Backdrop
