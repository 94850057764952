import React, { FC, useContext, useEffect, useRef } from 'react'

import { useLocalStorage } from 'hooks/LocalStorage'
import { useRouter } from 'next/router'
import AppContext from 'contexts/AppContext'

import Menu from '../Menu/Menu'
import { isMobile } from 'react-device-detect'
import { useUser } from '@auth0/nextjs-auth0'

interface LanguageSelectorProps {
  onSelect?: () => void
}

const LanguageSelector: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<LanguageSelectorProps>>
  >
> = (props: LanguageSelectorProps) => {
  const { languages, setLanguage, selectedLanguage } = useContext(AppContext)
  const [storedLanguage, setStoredLanguage] = useLocalStorage('language', {})
  const firstLoad = useRef(true)
  const router = useRouter()
  const user = useUser()

  const languageFlags: Record<string, combined> = {
    ge: 'geo.svg',
    en: 'usa.svg',
    ru: 'rus.svg',
  }

  useEffect(() => {
    if (
      storedLanguage &&
      !Object.keys(storedLanguage).length &&
      languages?.length
    ) {
      const defaultLanguage = languages?.find(
        (language: Language) => language.isDefault
      )
      if (window.location.pathname) setStoredLanguage(defaultLanguage)
      if (defaultLanguage) {
        setLanguage(defaultLanguage, user?.user?.sub)
      }
    }

    if (Object.keys(storedLanguage).length && languages.length) {
      if (firstLoad.current) {
        if (router.query && router.query.lang) {
          const storedLanguage = languages?.find(
            (language: Language) => language.key == router.query.lang
          )
          setLanguage(storedLanguage, user?.user?.sub)
        } else {
          setLanguage(storedLanguage, user?.user?.sub)
        }
        firstLoad.current = false

        // This was causing infinit reload error on error page
        // This could break it again if uncomented
        //router.push(`${assembleURL(storedLanguage)}`)
      }
      const storedLanguageAvailable = languages?.find(
        (language: Language) => storedLanguage.key === language.key
      )
      if (storedLanguageAvailable && !storedLanguageAvailable?.enabled) {
        const defaultLanguage = languages?.find(
          (language: Language) => language.isDefault
        )
        if (defaultLanguage) {
          setStoredLanguage(defaultLanguage)
          setLanguage(defaultLanguage, user?.user?.sub)
        }
      }
    }
  }, [languages, setStoredLanguage, storedLanguage, setLanguage])

  const assembleURL = (language: Language) => {
    const currentURL = window.location.pathname.split('/')
    currentURL[1] = language.key
    return currentURL.join('/')
  }

  const activeLanguages = () => {
    if (languages && languages.length) {
      return languages?.filter((language: Language) => {
        return language.enabled && language.active
      })
    }
    return []
  }

  const handleSelect = async (language: Language) => {
    setStoredLanguage(language)
    setLanguage(language, user?.user?.sub)
    props.onSelect ? props.onSelect() : null
    try {
      const { getUser } = await import('@braze/web-sdk')
      getUser().setLanguage(language.key === 'ge' ? 'ka' : language.key)
    } catch (error) {
      console.error('error_on_braze', error)
    }
    router.push(`${assembleURL(language)}`, '')
  }

  const handleSelectLanguage = (id: string) => {
    const language = languages?.find((language) => language._id == id)
    handleSelect(language as Language)
  }

  const renderLanguages = () => {
    return (
      <>
        {activeLanguages().map((language) => (
          <div
            key={language.key}
            onClick={() => handleSelectLanguage(language._id)}
          >
            <span>
              <img
                width="22px"
                height="23px"
                src={`/flags/${languageFlags[language.key]}`}
                alt={language.key}
              />
            </span>
            <span> </span>
            <span>{language.title}</span>
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {isMobile ? (
        renderLanguages()
      ) : (
        <Menu
          text={`${selectedLanguage?.title?.substring(0, 3)}.`}
          icon={languageFlags[selectedLanguage?.key || 'ge']}
          type={'bubble'}
          iconType={'image'}
        >
          {renderLanguages()}
        </Menu>
      )}
    </>
  )
}

export default LanguageSelector
