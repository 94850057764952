import React from 'react'
import styles from './AuthUserSkeleton.module.scss'

const AuthUserSkeleton = () => {
  return (
    <div className={styles.mainContainer}>
      <article className={styles.background}></article>
    </div>
  )
}

export default AuthUserSkeleton
