import React, { FC, useContext, useState } from 'react'

import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'

import { useRouter } from 'next/router'
import styles from './SuggestionItem.module.scss'
import Quantity from '@components/ui/Quantity/Quantity'
import { Label } from '@components/ui'
import { UseTranslate } from 'hooks/Translate'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import UserContext from '../../../contexts/UserContext'

interface SuggestionItemProps {
  suggestion: Product
  onNavigate: () => void
}

const SuggestionItem: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<SuggestionItemProps>>
  >
> = (props: SuggestionItemProps) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const [showAddToCart, setShowAddToCart] = useState(false)
  const [translate] = UseTranslate()
  const { customerInfo } = useContext(UserContext)
  const [isClub] = useState<boolean>(
    customerInfo?.clubMembership?.current?.isMember
  )
  const [isCustomer] = useState<boolean>(customerInfo?.cards.length > 0)

  const generatePrice = (product) => {
    if (
      product?.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product?.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product?.loyaltyDiscount?.isSpecial &&
      !product?.loyaltyDiscount?.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product?.discount) {
      return product.discount?.price
    }
    return product?.stock?.price
    // if (
    //     product.loyaltyDiscount &&
    //     !product.loyaltyDiscount?.isSpecial &&
    //     !product.loyaltyDiscount.isClub &&
    //     UserStore.isCustomer
    // ) {
    //   return product.loyaltyDiscount.price;
    // }
  }

  const navigateToDetails = () => {
    router.push(`/${lang}/product/${props.suggestion.nameSlug}`)
    props.onNavigate()
  }

  const isInStock = () => {
    return (
      props.suggestion.stock &&
      !(
        props.suggestion.stock?.stock -
        props.suggestion.stockReservationAmount <
        1
      )
    )
  }

  return (
    <div
      className={styles.container}
      onMouseOver={() => setShowAddToCart(true)}
      onMouseLeave={() => setShowAddToCart(false)}
    >
      <figure onClick={() => navigateToDetails()}>
        {props.suggestion.images && props.suggestion.images[0] ? (
          <img
            src={getProductPrimaryImageUrl(props.suggestion)}
            alt={props.suggestion.title}
            onError={getBrokenImage}
          />
        ) : (
          <ProductCardImagePlaceholder />
        )}
      </figure>
      <div className={styles.description} onClick={() => navigateToDetails()}>
        <div className={styles.description__title}>
          {props.suggestion.title}
        </div>
        <div className={styles.description__properties}>
          <span className={styles.description__properties__price}>
            {generatePrice(props.suggestion).toFixed(2)} <Lari />
          </span>
          {props.suggestion.discount?.price ||
            (props.suggestion.loyaltyDiscount?.isClub && isClub) ||
            (isCustomer &&
              props.suggestion.loyaltyDiscount &&
              !props.suggestion.loyaltyDiscount?.isClub &&
              !props.suggestion.loyaltyDiscount?.isSpecial &&
              isInStock()) ? (
            <span className={styles.description__properties__discountPrice}>
              {props.suggestion?.stock?.price} <Lari />
            </span>
          ) : null}
          <span className={styles.description__properties__unit}>
            {props.suggestion.unitType &&
              props.suggestion.unitValue &&
              `${props.suggestion.unitValue || ''} ${props.suggestion.unitType || ''
              }`}
            &nbsp;{' '}
          </span>
        </div>
      </div>
      {isInStock() ? (
        <>
          {showAddToCart ? (
            <div className={styles.container__quantity}>
              <Quantity
                productId={props.suggestion.productId}
                id={props.suggestion._id}
                title={props.suggestion.title}
                countStep={props.suggestion.countStep}
                price={generatePrice(props.suggestion)}
                category={props.suggestion.categoryId}
              />
            </div>
          ) : null}
        </>
      ) : (
        <Label
          color="#99AEBB"
          size="auto"
          text={translate('ecommerce.notInStock')}
        />
      )}
    </div>
  )
}

export default SuggestionItem
