import { useCallback, useContext } from 'react'
import AppContext from '../contexts/AppContext'
import { useLocalStorage } from './LocalStorage'

export const UseTranslate = (): [
  (key: string, placeholder?: number | string) => string
] => {
  const { keywords, selectedLanguage } = useContext(AppContext)
  const [storedLanguage] = useLocalStorage('language', '')

  const translate = useCallback(
    (key: string, placeholder?: number | string) => {
      if (keywords && keywords.length) {
        const keyword = keywords?.find((keyword) => keyword.key === key)
        if (keyword) {
          const lang =
            selectedLanguage &&
            Object.keys(selectedLanguage as Record<string, string>).length
              ? selectedLanguage
              : storedLanguage
          let translation =
            keyword.translations && lang && lang.key
              ? keyword.translations[lang.key.toString()]
              : undefined

          if (translation && placeholder) {
            translation = translation.replace('{0}', placeholder.toString())
          }

          return translation ? translation : key
        }
        return key
      }
      return ''
    },
    [keywords, storedLanguage, selectedLanguage]
  )

  return [translate]
}
