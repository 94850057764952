import React, { FC, useContext, useState } from 'react'

import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'

import { useRouter } from 'next/router'
import styles from './AlternativesItem.module.scss'
import Quantity from '@components/ui/Quantity/Quantity'
import { Label } from '@components/ui'
import { UseTranslate } from 'hooks/Translate'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import UserContext from '../../../contexts/UserContext'

interface AlternativesItemProps {
  suggestion: Product
  onNavigate: () => void;
  onSelect: (pr: Product) => void;
}

const AlternativesItem: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<AlternativesItemProps>>
  >
> = (props: AlternativesItemProps) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const { customerInfo } = useContext(UserContext)
  const [isClub] = useState<boolean>(
    customerInfo?.clubMembership?.current?.isMember
  )
  const [isCustomer] = useState<boolean>(customerInfo?.cards.length > 0)

  const generatePrice = (product) => {
    if (
      product?.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product?.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product?.loyaltyDiscount?.isSpecial &&
      !product?.loyaltyDiscount?.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product?.discount) {
      return product.discount?.price
    }
    return product?.stock?.price

  }

  const isInStock = () => {
    return (
      props.suggestion.stock &&
      !(
        props.suggestion.stock?.stock -
        props.suggestion.stockReservationAmount <
        1
      )
    )
  }

  return (
    <div
      className={styles.container}
    >
      <figure onClick={() => props.onSelect(props.suggestion)}>
        {props.suggestion.images && props.suggestion.images[0] ? (
          <img
            src={getProductPrimaryImageUrl(props.suggestion)}
            alt={props.suggestion.title}
            onError={getBrokenImage}
          />
        ) : (
          <ProductCardImagePlaceholder />
        )}
      </figure>
      <div className={styles.description} onClick={() => props.onSelect(props.suggestion)}>
        <div className={styles.description__title}>
          {props.suggestion.title}
        </div>
        <div className={styles.description__properties}>
          <span className={styles.description__properties__price}>
            {generatePrice(props.suggestion).toFixed(2)} <Lari />
          </span>
          {props.suggestion.discount?.price ||
            (props.suggestion.loyaltyDiscount?.isClub && isClub) ||
            (isCustomer &&
              props.suggestion.loyaltyDiscount &&
              !props.suggestion.loyaltyDiscount?.isClub &&
              !props.suggestion.loyaltyDiscount?.isSpecial &&
              isInStock()) ? (
            <span className={styles.description__properties__discountPrice}>
              {props.suggestion?.stock?.price} <Lari />
            </span>
          ) : null}
          <span className={styles.description__properties__unit}>
            {props.suggestion.unitType &&
              props.suggestion.unitValue &&
              `${props.suggestion.unitValue || ''} ${props.suggestion.unitType || ''
              }`}
            &nbsp;{' '}
          </span>
        </div>
      </div>
    </div >
  )
}

export default AlternativesItem
