import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import styles from './CartPopup.module.scss'

import Quantity from '@components/ui/Quantity/Quantity'
import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import { FiLoader } from 'react-icons/fi'
import classNames from 'classnames'
import AppContext from 'contexts/AppContext'
import { getBrokenImage } from '@utils/getBrokenImage'
import { UseTranslate } from 'hooks/Translate'
import { CgTrashEmpty } from 'react-icons/cg'
import UserContext from 'contexts/UserContext'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import { UniCat, getCategoryLevels } from '@utils/categoryUtils'
import tagManagerUtil from '@utils/tagManagerUtil'
import { UserProfile } from '@auth0/nextjs-auth0'

type Props = {
  prodId: string
  cartId: string
  product: CartProduct,
  categories: UniCat[],
  user: UserProfile
}

export default function CartPopupItem({ product, cartId, prodId, categories, user }: Props) {
  const router = useRouter()
  const [translate] = UseTranslate()
  const [itemLoading, setItemLoading] = useState(false)
  const { cart, setOpenCart, deleteCartProduct } = useContext(AppContext)
  const { selectedAddress, customerInfo } = useContext(UserContext)

  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  useEffect(() => {
    setItemLoading(true)
    window.setTimeout(async function () {
      setItemLoading(false)
    }, 100)
  }, [product.quantity])

  const navigateToDetails = () => {
    setOpenCart()
    router.push(`/${lang}/product/${product.nameSlug}`)
  }

  const generatePrice = (product) => {
    if (
      product?.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product?.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product?.loyaltyDiscount?.isSpecial &&
      !product?.loyaltyDiscount?.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product?.discount) {
      return product.discount?.price
    }
    return product?.price || 0
    // if (
    //     product.loyaltyDiscount &&
    //     !product.loyaltyDiscount?.isSpecial &&
    //     !product.loyaltyDiscount.isClub &&
    //     UserStore.isCustomer
    // ) {
    //   return product.loyaltyDiscount.price;
    // }
  }

  const querntityWrapper = classNames({
    [styles.cartPopup__product__quantity]: true,
    noselect: true,
  })

  async function decrease() {
    await deleteCartProduct(
      cart ? cart._id : cartId,
      prodId,
      lang,
      selectedAddress?.warehouseId,
      customerInfo?.clubMembership?.current?.isMember
    );
    const categoryLevels = getCategoryLevels(product.categoryId, categories, lang.toString());
    tagManagerUtil.RemoveFromCart({
      ProductName: product.title,
      ProductID: product.prodId,
      UserID: user?.sub,
      ProductPrice: product.price,
      ProductCategory: product.categoryId,
      Quantity: product.countStep || 1,
      categoryLevels
    })
  }

  return (
    <>
      <div className={styles.cartPopup__product} key={product.prodId}>
        <div
          className={styles.cartPopup__product__image}
          onClick={navigateToDetails}
        >
          {product?.images?.length ? (
            <img
              src={getProductPrimaryImageUrl(product)}
              onError={getBrokenImage}
              alt={product.title}
            />
          ) : (
            <ProductCardImagePlaceholder />
          )}
        </div>

        <span
          className={styles.cartPopup__product__delete}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            if (!itemLoading) {
              decrease()
            }
          }}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="15" fill="#F3F4F6" />
            <path
              d="M8.25 10.5H9.75H21.75"
              stroke="#111827"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 10.5V9C12 8.60218 12.158 8.22064 12.4393 7.93934C12.7206 7.65804 13.1022 7.5 13.5 7.5H16.5C16.8978 7.5 17.2794 7.65804 17.5607 7.93934C17.842 8.22064 18 8.60218 18 9V10.5M20.25 10.5V21C20.25 21.3978 20.092 21.7794 19.8107 22.0607C19.5294 22.342 19.1478 22.5 18.75 22.5H11.25C10.8522 22.5 10.4706 22.342 10.1893 22.0607C9.90804 21.7794 9.75 21.3978 9.75 21V10.5H20.25Z"
              stroke="#111827"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>

        <div className={styles.cartPopup__product__body}>
          <span
            className={styles.cartPopup__product__title}
            onClick={navigateToDetails}
          >
            {product.title}
          </span>

          {!product.inStock && product.maxAvailable <= 0 && (
            <div className={styles.maxQuantity}>
              {translate('shop.cart.item.notInStock')}
            </div>
          )}
          {!product.inStock &&
            product.maxAvailable > 0 &&
            !product.maxSetFromCartLimit && (
              <div className={styles.maxQuantity}>
                {translate('shop.cart.item.maximumAvailable')} {` - `}
                {product.maxAvailable}
              </div>
            )}
          {product.maxSetFromCartLimit && (
            <div className={styles.maxQuantity}>
              {translate('shop.cart.item.maxSetFromCartLimit')}
            </div>
          )}
          {product.maxAvailable > 0 ? (
            <div className={styles.cartPopup__product__value}>
              <div className={querntityWrapper}>
                <Quantity
                  productId={product.prodId}
                  hideDelete={true}
                  id={product._id}
                  title={product.title}
                  countStep={product.countStep}
                  price={product.price}
                />
              </div>

              <span className={styles.cartPopup__product__x}>
                <svg width="14px" height="14px" viewBox="0 0 24 24">
                  <path d="M6.623,5.278 L12,10.656 L17.377,5.278 C17.748,4.908 18.35,4.908 18.722,5.278 C19.092,5.65 19.092,6.252 18.722,6.623 L13.344,12 L18.722,17.377 C19.092,17.748 19.092,18.35 18.722,18.722 C18.35,19.092 17.748,19.092 17.377,18.722 L12,13.344 L6.623,18.722 C6.252,19.092 5.65,19.092 5.278,18.722 C4.908,18.35 4.908,17.748 5.278,17.377 L10.656,12 L5.278,6.623 C4.908,6.252 4.908,5.65 5.278,5.278 C5.65,4.908 6.252,4.908 6.623,5.278 L6.623,5.278 Z" />
                </svg>
              </span>

              <span className={styles.cartPopup__product__price}>
                {generatePrice(product)?.toFixed(2)} <Lari />
              </span>

              {product.discount?.price ||
                (product.loyaltyDiscount?.isClub &&
                  customerInfo?.clubMembership?.current?.isMember) ||
                (customerInfo?.cards.length > 0 &&
                  product.loyaltyDiscount &&
                  !product.loyaltyDiscount?.isClub &&
                  !product.loyaltyDiscount?.isSpecial) ? (
                <div className={styles.cartPopup__product__discount}>
                  <span>{product.price || 0}</span>
                  &nbsp;
                  <Lari />
                </div>
              ) : (
                <div className={styles.cartPopup__product__discount}></div>
              )}

              <div className={styles.cartPopup__product__total}>
                {itemLoading ? (
                  <>
                    <FiLoader
                      className={styles.cartPopup__product__item_loading}
                    />
                  </>
                ) : (
                  <>
                    {product.quantity && product
                      ? Number(
                        product.quantity * generatePrice(product)
                      ).toFixed(2)
                      : 0}{' '}
                    <Lari />
                  </>
                )}
              </div>
            </div>
          ) : (
            <span className={styles.emptyTrash}>
              <CgTrashEmpty
                size="22px"
                color="#007A3E"
                onClick={async (e: React.MouseEvent) => {
                  e.stopPropagation()
                  await deleteCartProduct(
                    cart ? cart._id : cartId,
                    prodId,
                    lang,
                    selectedAddress?.warehouseId,
                    customerInfo?.clubMembership?.current?.isMember
                  )
                }}
              />
            </span>
          )}
        </div>
      </div>
      <div className={styles.cartPopup__product__item_bottom_border}></div>
    </>
  )
}
