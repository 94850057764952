import React, { FC, useContext, useEffect, useState } from 'react'
import style from './Footer.module.scss'
import { UseTranslate } from 'hooks/Translate'
import AppContext from 'contexts/AppContext'
import { useRouter } from 'next/router'
import { getAppLink } from '@utils/dynamicLinks'
import Link from 'next/link'
import tagManagerUtil from '@utils/tagManagerUtil'

const Footer: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<unknown>>
  >
> = () => {
  const [translate] = UseTranslate()
  const { menuConfig, menuConfigLoading } = useContext(AppContext)
  const [appLink, setAppLink] = useState<string>()
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  useEffect(() => {
    setAppLink(getAppLink(router.query?.gclid?.toString() || ''))
  }, [])

  return (
    <footer className={`fit ${style.shopFooter}`}>
      <div className={`metad-container d-flex ${style.shopFooterContent}`}>
        <div className={`d-flex  ${style.shoopFooterLinks}`}>
          {menuConfigLoading ? (
            <></>
          ) : menuConfig && menuConfig.length ? (
            menuConfig.map((item) =>
              item.position === 2 ? (
                item.url &&
                item.url[lang.toString()] &&
                item.url[lang.toString()].startsWith('/') ? (
                  <Link key={item._id} href={item.url[lang.toString()]}>
                    <a className={style.footerNavItem}>
                      {item.icon && item.icon[lang.toString()] ? (
                        <img
                          width="24px"
                          height="24px"
                          src={`/api/files/download/${
                            item.icon[lang.toString()].imageId
                          }/${item.icon[lang.toString()].originalName}`}
                          alt={item.name[lang.toString()]}
                        />
                      ) : (
                        <></>
                      )}
                      {item.name[lang.toString()]}
                    </a>
                  </Link>
                ) : (
                  <a
                    className={style.footerNavItem}
                    key={item._id}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.url[lang.toString()] || '#'}
                  >
                    {item.icon && item.icon[lang.toString()] ? (
                      <img
                        width="24px"
                        height="24px"
                        src={`/api/files/download/${
                          item.icon[lang.toString()].imageId
                        }/${item.icon[lang.toString()].originalName}`}
                        alt={item.name[lang.toString()]}
                      />
                    ) : (
                      <></>
                    )}
                    {item.name[lang.toString()]}
                  </a>
                )
              ) : null
            )
          ) : null}

          <a
            className={style.footerNavItem}
            target="_blank"
            rel="noopener noreferrer"
            href={appLink}
          >
            <img
              width="24px"
              height="24px"
              src={`/svgs/android.svg`}
              alt={'android'}
            />
          </a>

          <a
            className={style.footerNavItem}
            href={appLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              width="24px"
              height="24px"
              src={`/svgs/ios.svg`}
              alt={'android'}
            />
          </a>

          <a
            id="intercom-chat-help-link"
            className={style.footerNavItem}
            onClick={() => {
              tagManagerUtil.StartChat()
            }}
          >
            <span className={style.footerIntercomNavLink}>
              <img
                width={14}
                height={14}
                style={{ marginRight: 5 }}
                src={`/images/chat-icon.png`}
                alt={translate('shop.intercomHelpFooterLink')}
              />
              {translate('shop.intercomHelpFooterLink')}
            </span>
          </a>
        </div>
        <div
          className={`justify-content-end  ${style.shoopFooterRightWrapper}`}
        >
          <span className={`${style.copyRight}`}>
            {new Date().getFullYear()} &#169;
          </span>
          <span className={`${style.companyTitle}`}>
            {translate('shop.footer.2stepTitle')}
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
