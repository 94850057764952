import { Dropdown } from '@components/ui'
import useDebounce from 'hooks/Debounce'
import React, { FC, useEffect, useState } from 'react'

interface PlacesAutocompleteProps {
  id: string
  label: string
  value?: string
  predictions?: Record<string, combined>[]
  name: string
  onChange?: (value: Record<string, combined> | null) => Promise<string>
  placeholder: string
  errorText?: string
  minSymbols?: number
}

const PlacesAutocomplete: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<PlacesAutocompleteProps>>>> = (
  props: PlacesAutocompleteProps
) => {
  const [searchValue, setSearchValue] = useState('')
  const [suggestions, setSuggestions] = useState<DropdownItem[]>([])

  const searchDebounce = useDebounce(searchValue, 500)

  useEffect(() => {
    getAddressSuggestions(searchDebounce)
  }, [searchDebounce])

  useEffect(() => {
    if (props.predictions?.length) {
      setSuggestions(props.predictions as DropdownItem[])
    }
  }, [props.predictions])

  const getAddressSuggestions = async (filterKey: string): Promise<void> => {
    const { minSymbols = 0 } = props
    if (filterKey && filterKey.length >= minSymbols) {
      const autoCompleteService =
        new window.google.maps.places.AutocompleteService()
      const transformedSuggestions: DropdownItem[] = []
      autoCompleteService.getPlacePredictions(
        {
          input: filterKey,
          location: new window.google.maps.LatLng(41.702, 44.797),
          radius: 5000,
        },
        (suggestions) => {
          suggestions?.forEach((suggestion) => {
            transformedSuggestions.push({
              id: suggestion.place_id,
              title: suggestion.terms[0].value,
              subTitle: suggestion.terms[1].value,
            })
          })
          setSuggestions(transformedSuggestions)
        }
      )
    } else {
      setSuggestions([])
    }
  }
  return (
    <Dropdown
      options={suggestions}
      id={props.id}
      name={props.name}
      label={props.label}
      value={props.value}
      errorText={props.errorText}
      onInput={(value) => setSearchValue(value)}
      requestCurrentLocation
      onChange={async (value) => {
        if (props.onChange) {
          return props.onChange(value)
        }

        return value?.title || ''
      }}
      placeholder={props.placeholder}
    />
  )
}

export default PlacesAutocomplete
