import AppContext from 'contexts/AppContext'
import { useContext, useMemo } from 'react'

export default function useSpecialDiscount() {
  const { cart, specialProducts } = useContext(AppContext)

  const cntMap = useMemo(
    () => new Map(specialProducts.map((p) => [p.productId, p.cnt])),
    [specialProducts]
  )

  const specials = useMemo(
    () => (cart?.products || []).filter((p) => cntMap.has(p.prodId)),
    [cart, specialProducts]
  )

  const spend = useMemo(
    () =>
      specials.reduce(
        (prev, curr) =>
          prev +
          curr.loyaltyDiscount.discountPriceInPoints * cntMap.get(curr.prodId),
        0
      ),
    [specials]
  )

  const saved = useMemo(
    () =>
      specials.reduce(
        (prev, curr) =>
          prev +
          (curr.price - curr.loyaltyDiscount.price) * cntMap.get(curr.prodId),
        0
      ),
    [specials]
  )

  return { cntMap, specials, spend, saved }
}
