import React, { FC, useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FiGrid } from 'react-icons/fi'
import { Menu } from '..'
import styles from './MobileNavigation.module.scss'
import { UseTranslate } from 'hooks/Translate'
import CategoryContext from 'contexts/CategoryContext'
import AppContext from 'contexts/AppContext'

interface MobileNavigationProps {
  onSelect: () => void
}

const MobileNavigation: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<MobileNavigationProps>>>> = (
  props: MobileNavigationProps
) => {
  const router = useRouter()
  const [translate] = UseTranslate()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const [categories, setCategories] = useState<Category[]>([])
  const { categoriesData } = useContext(CategoryContext)
  const { menuConfig, menuConfigLoading } = useContext(AppContext)
  const [menuConfigData, setMenuConfigData] = useState<MenuConfig[]>()

  useEffect(() => {
    if (categoriesData) loadCategories(categoriesData)
  }, [categoriesData])

  const loadCategories = (data: Category[]) => {
    const categories = data.filter((category: Category) => !category.parent)
    setCategories(categories)
  }

  useEffect(() => {
    if (menuConfig) {
      const data = menuConfig.sort(function (a, b) {
        return a.position - b.position || a.priority - b.priority
      })
      setMenuConfigData(data)
    }
  }, [menuConfig])

  return (
    <div className={styles.container}>
      <div className={styles.navigations}>
        <div className={styles.link}>
          <Menu
            text={translate('ecommerce.categories')}
            backdrop={false}
            arrow={true}
            icon={<FiGrid />}
            hover={false}
          >
            {categories.map((category) => (
              <ul key={category._id}>
                <Link href={`/${lang}/category/${category.nameSlug}`}>
                  <li onClick={() => props.onSelect()}>
                    {category.name[lang as string] || '-'}
                  </li>
                </Link>
              </ul>
            ))}
          </Menu>
        </div>

        {menuConfigLoading ? (
          <></>
        ) : menuConfigData && menuConfigData.length ? (
          menuConfigData.map((item) =>
            item.url &&
            item.url[lang.toString()] &&
            item.url[lang.toString()].startsWith('/') ? (
              <div key={`config-${item._id}`} className={styles.link}>
                <a
                  key={item._id}
                  onClick={() => router.push(item.url[lang.toString()])}
                >
                  {item.name[lang.toString()]}
                </a>
              </div>
            ) : (
              <div key={`config-${item._id}`} className={styles.link}>
                <a
                  key={item._id}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    window.open(item.url[lang.toString()], '_blank')
                  }
                >
                  {item.name[lang.toString()]}
                </a>
              </div>
            )
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default MobileNavigation
