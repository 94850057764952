import React, { FC, CSSProperties } from 'react'

import styles from './Label.module.scss'
import cn from 'classnames'

interface LabelProps {
  style?: CSSProperties
  size?: 'stretch' | 'auto'
  color: string
  textColor?: string
  type?: 'regular' | 'bubble'
  text: string
}

const Label: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<LabelProps>>
  >
> = (props: LabelProps) => {
  const labelContainer = cn({
    [styles.container]: true,
    [styles.stretch]: props.size === 'stretch',
  })

  return (
    <div
      className={labelContainer}
      style={{
        ...props.style,
        backgroundColor: props.color,
        width: props.size === 'auto' ? 'fit-content' : 'initial',
        position: props.type === 'bubble' ? 'absolute' : 'initial',
      }}
    >
      <div
        className={styles.label}
        style={{ color: props.textColor || 'white' }}
      >
        {props.text}
      </div>
    </div>
  )
}

Label.defaultProps = {
  size: 'auto',
  type: 'regular',
}

export default Label
