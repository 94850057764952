import React, { CSSProperties, FC, useState } from 'react'

import cn from 'classnames'
import styles from './Menu.module.scss'

import MenuItem from './MenuItem'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import Backdrop from '@components/ui/Backdrop/Backdrop'

interface MenuProps {
  onClose?: () => void
  style?: CSSProperties
  icon?: JSX.Element | string
  text: string
  backdrop?: boolean
  type?: 'plain' | 'bubble'
  arrow?: boolean
  hover?: boolean
  children: React.ReactNode
  iconType?: 'image' | 'icon'
}

const Menu: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<MenuProps>>>> = (props: MenuProps) => {
  const [visible, setVisible] = useState(false)

  const alternateStyles: CSSProperties = {
    color: props.backdrop && visible ? 'white' : '',
    zIndex: props.backdrop && visible ? 102 : 10,
  }

  const menuItemStyles: CSSProperties = {
    position: props.type === 'bubble' ? 'absolute' : 'unset',
    zIndex: props.type === 'bubble' ? 102 : 0,
    width: props.type === 'bubble' ? 'max-content' : '100%',
    border: props.type === 'bubble' ? '1px solid #E6E7E8' : 'none',
    boxShadow:
      props.type === 'bubble' ? '0px 2px 4px rgb(0 0 0 / 10%)' : 'unset',
  }

  const container = cn({
    [styles.container]: true,
    [styles.hover]: props.hover,
  })

  return (
    <>
      <div
        className={container}
        onClick={() => {
          setVisible(!visible)
        }}
        style={props.style}
      >
        {props.type === 'bubble' ? (
          <Backdrop visible={visible} transparent={!props.backdrop} />
        ) : null}
        {props.icon ? (
          <div style={alternateStyles}>
            {props.iconType === 'image' ? (
              <img
                src={`/flags/${props.icon}`}
                width={22}
                height={22}
                alt={props.text}
              />
            ) : (
              props.icon
            )}
          </div>
        ) : null}
        <span style={alternateStyles} className={styles.text}>
          {props.text}
        </span>
        {props.arrow ? (
          <div style={alternateStyles} className={styles.arrow}>
            {!visible ? <FiChevronDown /> : <FiChevronUp />}
          </div>
        ) : null}
        <MenuItem style={menuItemStyles} visible={visible}>
          {props.children}
        </MenuItem>
      </div>
    </>
  )
}

Menu.defaultProps = {
  type: 'plain',
  hover: true,
  iconType: 'icon',
}

export default Menu
