import React, { CSSProperties, FC, useEffect, useState } from 'react'

import styles from './MenuItem.module.scss'

import cn from 'classnames'

interface MenuItemProps {
  visible: boolean
  onClose?: () => void
  onItemClick?: (item: unknown) => void
  style?: CSSProperties
  children: React.ReactNode
}

const MenuItem: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<MenuItemProps>>>> = (props: MenuItemProps) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  const container = cn({
    [styles.container]: true,
    [styles.visible]: visible,
  })

  return (
    <div className={container} style={props.style}>
      {props.children}
    </div>
  )
}

export default MenuItem
