import { IRecipe } from "../@types/Recipe";
import { ListNode } from "./listToTree";

const mediaURLPrefixes = ['first', 'second', 'third', 'fourth', 'fifth', 'six'];

export const getProductPrimaryImageUrl = (product: Product | CartProduct, width = 300, height = 300): string => {
  if (product && product.images && product.images.length) {
    let primaryImage = product.images.find((image) => image.isPrimary);
    if (!primaryImage) {
      primaryImage = product.images[0];
    }
    const fileName = primaryImage.originalName;
    const productSlugName = encodeURIComponent(product.nameSlug?.replace(/[^\x00-\x7F]/g, ''));
    const fileNameParts = fileName.split('.');
    const extension = fileNameParts[fileNameParts.length - 1] || 'jpeg';
    const loadUrl = `/api/files/resize/${width}/${height}/${primaryImage.imageId}/${productSlugName}.${extension}`
    if (process.env.NEXT_PUBLIC_MEDIA_API) {
      const index = Math.floor(Math.random() * 6);
      return `https://${mediaURLPrefixes[index]}.${process.env.NEXT_PUBLIC_MEDIA_API}${loadUrl}`;
    } else {
      return loadUrl;
    }
  }
  return '';
}

export const getCategoryImageUrl = (props: ListNode & Category | Category): string => {
  let extension = 'jpeg';
  if (props && props.images && props.images.length) {
    if (props.images[0].originalName) {
      const arr = props.images[0].originalName.split('.')
      extension = arr[arr.length - 1]
    }
    const basePart = `/api/files/download/${props.images[0].imageId}/${encodeURIComponent(
      props.nameSlug.replace(/[^\x00-\x7F]/g, '')
    )}.${extension || 'jpeg'}`;
    if (process.env.NEXT_PUBLIC_MEDIA_API) {
      const index = Math.floor(Math.random() * 6);
      return `https://${mediaURLPrefixes[index]}.${process.env.NEXT_PUBLIC_STATIC_API}${basePart}`;
    } else {
      return basePart;
    }
  }
  return '';
}

export const getMenuIconUrl = (item: MenuConfig, lang: string): string => {
  if (item && item.icon && item.icon[lang.toString()] && item.icon[lang.toString()]['imageId']) {
    const iconId = item.icon[lang.toString()]['imageId']
    const basePart = `/api/files/download/${iconId}`
    if (process.env.NEXT_PUBLIC_MEDIA_API) {
      const index = Math.floor(Math.random() * 6);
      return `https://${mediaURLPrefixes[index]}.${process.env.NEXT_PUBLIC_STATIC_API}${basePart}`;
    } else {
      return basePart;
    }
  }
  return '';
}

export const getBannerImageUrl = (productModule: {
  webImage?: { [key: string]: Record<string, string> }
  webImageSecond?: { [key: string]: Record<string, string> }
  mobileImage?: { [key: string]: Record<string, string> }
  mobileImageSecond?: { [key: string]: Record<string, string> }
}, lang: string, isMobile = false, customProp = ''): string => {
  let prop = isMobile ? 'mobileImage' : 'webImage';
  if (customProp) {
    prop = customProp;
  }
  const imageName = encodeURIComponent(
    String(productModule[prop][lang as string].originalName).replace(/[^\x00-\x7F]/g, '')
  );
  const imageId = productModule[prop][lang].imageId;
  if (imageId) {
    const basePart = `/api/files/download/${imageId}/${imageName}`;
    if (process.env.NEXT_PUBLIC_MEDIA_API) {
      const index = Math.floor(Math.random() * 6);
      return `https://${mediaURLPrefixes[index]}.${process.env.NEXT_PUBLIC_STATIC_API}${basePart}`;
    } else {
      return basePart;
    }
  }
}

export const getRecipeImageUrl = (recipe: IRecipe, width = 300, height = 300): string => {
  if (recipe && recipe.image) {
    let primaryImage = recipe.image;
    const fileName = primaryImage.originalName;
    const productSlugName = encodeURIComponent(recipe.nameSlug?.replace(/[^\x00-\x7F]/g, ''));
    const fileNameParts = fileName.split('.');
    const extension = fileNameParts[fileNameParts.length - 1] || 'jpeg';
    const loadUrl = `/api/files/resize/${width}/${height}/${primaryImage.imageId}/${productSlugName}.${extension}`
    if (process.env.NEXT_PUBLIC_MEDIA_API) {
      const index = Math.floor(Math.random() * 6);
      return `https://${mediaURLPrefixes[index]}.${process.env.NEXT_PUBLIC_MEDIA_API}${loadUrl}`;
    } else {
      return loadUrl;
    }
  }
  return '';
}