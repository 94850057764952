import classNames from 'classnames'
import { UseTranslate } from 'hooks/Translate'
import React, { FC, useEffect, useState, useRef } from 'react'
import { TiLocationArrowOutline } from 'react-icons/ti'

import styles from './Dropdown.module.scss'

interface DropdownProps {
  id: string
  label?: string
  value?: string
  name: string
  placeholder: string
  disabled?: boolean
  optionContent?: JSX.Element
  options?: Array<DropdownItem>
  requestCurrentLocation?: boolean
  onChange: (value: Record<string, combined> | null) => Promise<string>
  onInput: (value: string) => void
  errorText?: string
}

const Dropdown: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<DropdownProps>>>> = (props: DropdownProps) => {
  const [value, setValue] = useState('')
  const [errorText, setErrorText] = useState('')
  const [options, setOptions] = useState<Array<DropdownItem>>([])
  const [showOptions, setShowOptions] = useState(false)
  const selectInput = useRef<HTMLInputElement>(null)
  const firstLoad = useRef(true)
  const [translate] = UseTranslate()

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  useEffect(() => {
    if (props.options?.length && !firstLoad.current) {
      setShowOptions(true)
      setOptions(props.options)
    } else {
      setOptions([])
      setShowOptions(false)
    }
    firstLoad.current = false
  }, [props.options])

  useEffect(() => {
    setErrorText('')
    if (value && selectInput.current) {
      selectInput.current.value = value
    }
  }, [value])

  useEffect(() => {
    if (props.errorText) setErrorText(props.errorText)
  }, [props.errorText])

  const inputClasses = classNames({
    [styles.hasError]: errorText,
  })

  const currentLocation = () => {
    return (
      <div
        key={'myCurrentLocationId'}
        className={styles.currentLocation}
        onMouseDown={async () => {
          setValue(
            await props.onChange({
              id: '',
            })
          )
          setShowOptions(false)
        }}
      >
        <TiLocationArrowOutline size={24} />
        {translate('myCurrentLocation')}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <fieldset className={inputClasses}>
        {props.label ? <label htmlFor={props.id}>{props.label}</label> : null}
        <input
          autoComplete="off"
          id={props.id}
          type="text"
          ref={selectInput}
          name={props.name}
          defaultValue={value}
          onBlur={() => setShowOptions(false)}
          onFocus={() => setShowOptions(true)}
          onInput={(e) => {
            if (value) {
              setValue('')
              props.onChange(null)
            }
            props.onInput(e.currentTarget.value)
          }}
          placeholder={props.placeholder}
          disabled={props.disabled}
        />
        <div className={styles.errorIcon}>!</div>
        {props.errorText && (
          <div className={styles.errorText}>{translate(props.errorText)}</div>
        )}
      </fieldset>
      {showOptions ? (
        <div className={styles.options}>
          {props.optionContent
            ? props.optionContent
            : options.length
            ? options?.map((option, i) => (
                <div key={option.id}>
                  {i == 0 && props.requestCurrentLocation == true
                    ? currentLocation()
                    : ''}
                  <div
                    className={`${styles.option} ${styles[option.class]}`}
                    onMouseDown={async () => {
                      setValue(await props.onChange(option))
                      setShowOptions(false)
                    }}
                  >
                    <div className={styles.title}>{option.title}</div>
                    <div className={styles.subTitle}>{option.subTitle}</div>
                  </div>
                </div>
              ))
            : currentLocation()}
        </div>
      ) : null}
    </div>
  )
}

Dropdown.defaultProps = {
  disabled: false,
  requestCurrentLocation: false,
}

export default Dropdown
