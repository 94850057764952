import React from 'react'
import styles from './AddressSelectorSkeleton.module.scss'

const AddressSelectorSkeleton = () => {
  return (
    <div className={styles.mainContainer}>
      <article className={styles.background}></article>
    </div>
  )
}

export default AddressSelectorSkeleton
