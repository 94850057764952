import React, { useEffect, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { UseTranslate } from 'hooks/Translate'
import { useWindowSize } from 'hooks/WindowSize'
import CategoryContext from 'contexts/CategoryContext'
import RequestHelper from '@utils/RequestHelper'
import { ProductCard } from '@components/common'
import { TProductModuleBase } from '../../../@types/ProductModule'

import cn from 'classnames'
import styles from './ProductModuleCarousel.module.scss'
import UserContext from 'contexts/UserContext'
import { pushChildren } from '@utils/listToTree'

type Props = {
  productModule: TProductModuleBase
  showAllProductsLink?: boolean
}

let SLIDES_PER_VIEW: number

export default function ProductModuleCarousel(props: Props) {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }

  const { width } = useWindowSize()
  const [translate] = UseTranslate()

  const { categoriesData } = useContext(CategoryContext)

  const [skip, setSkip] = useState(0)
  const [products, setProducts] = useState<Array<null | Product>>([])
  const [categories, setCategories] = useState<Category[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { selectedAddress } = useContext(UserContext)

  const { showAllProductsLink = true } = props

  const arrowLeft = cn({
    [styles.disabled]: skip === 0,
    [styles.module__header__arrow_left]: true,
  })

  const arrowRight = cn({
    [styles.disabled]: isLoading || skip === getMaxSkip(),
    [styles.module__header__arrow_right]: true,
  })

  function onPrev() {
    setSkip(Math.max(0, skip - SLIDES_PER_VIEW))
  }

  function onNext() {
    setSkip(Math.min(skip + SLIDES_PER_VIEW, getMaxSkip()))
  }

  function getMaxSkip() {
    const m = totalCount % SLIDES_PER_VIEW
    return totalCount - (m > 0 ? m : SLIDES_PER_VIEW)
  }

  useEffect(() => {
    if (width) {
      if (width > 1200) {
        if (props?.productModule.isRelatedProduct) {
          SLIDES_PER_VIEW = 4
        } else SLIDES_PER_VIEW = 5
      } else if (width > 1024) {
        SLIDES_PER_VIEW = 4
      } else if (width > 768) {
        SLIDES_PER_VIEW = 3
      } else {
        SLIDES_PER_VIEW = 10
      }

      if (props.productModule.onlyPriorityProducts) {
        setProducts(props.productModule.products)
        setTotalCount(props.productModule.products.length)
      } else if (props.productModule.category) {
        const categories = pushChildren(
          props.productModule.category,
          categoriesData
        )
        setCategories(categories)
        if (skip > 0) {
          loadProducts(skip, categories)
        } else {
          setProducts(props.productModule.products)
          setTotalCount(props.productModule.allProductCnt)
        }
      } else if (
        props.productModule.offerGroups &&
        props.productModule.offerGroups.length
      ) {
        if (skip > 0) {
          loadProducts(
            skip,
            [],
            props.productModule.offerGroups.map((e) => e.id)
          )
        } else {
          setProducts(props.productModule.products)
          setTotalCount(props.productModule.allProductCnt)
        }
      }
    }
  }, [width, props.productModule, categoriesData, skip])

  useEffect(() => {
    if (width && props.productModule.isRelatedProduct) {
      if (!products.length) {
        //loadRecommendedProducts()
        loadProducts(skip, [], [], props.productModule.productIds);
      }
    }
  }, [width, skip])

  function getFilledProducts() {
    if (width && width <= 768) {
      return products.slice(0, 10)
    }
    return products[skip]
      ? products.slice(skip, skip + SLIDES_PER_VIEW)
      : new Array(SLIDES_PER_VIEW).fill(null)
  }

  async function loadProducts(
    skip = 0,
    _categories = categories,
    _offerGroupIds = [],
    productIds = []
  ) {
    if (products[skip]) return

    setIsLoading(true)
    let url = `products/search?lang=${lang}`
    if (selectedAddress?.warehouseId) {
      url += `&warehouseId=${selectedAddress?.warehouseId}`
    }

    const requestData = {
      skip,
      limit: SLIDES_PER_VIEW,
      categoryIds: _categories.map((x) => x._id),
      offerGroupIds: _offerGroupIds,
      warehouseId: selectedAddress?.warehouseId || undefined,
      onlyInStockProducts: true,
    }

    if (productIds?.length) {
      requestData['productIds'] = productIds
    }

    const { data } = await RequestHelper.catalog.post(url, requestData)
    setProducts([...products, ...data.data.products])
    setTotalCount(data.data.totalCount)
    setIsLoading(false)
  }

  async function loadRecommendedProducts() {
    if (!props?.productModule?.prId) return

    setIsLoading(true)
    let url = `products/related/random?lang=${lang}&onlyInStockProducts=true&productId=${props?.productModule?.prId}`
    if (selectedAddress?.warehouseId) {
      url += `&warehouseId=${selectedAddress?.warehouseId}`
    }

    const { data } = await RequestHelper.catalog.get(url)
    if (data.data && data.data.products) {
      setProducts([...data.data.products])
      setTotalCount(data.data.totalCount)
    }

    setIsLoading(false)
  }

  return (
    <div className={`row ${styles.withMargin}`}>
      <div className="col-md-12">
        {(products || []).length > 0 && (
          <div
            className={styles.module__header}
            style={{ marginBottom: showAllProductsLink ? 32 : 8 }}
          >
            <div className={styles.module__header__title}>
              <h2 style={{ marginBottom: showAllProductsLink ? 8 : 0 }}>
                {props.productModule.title}
              </h2>
              {showAllProductsLink ? (
                categories[0] ? (
                  <Link href={`/${lang}/category/${categories[0].nameSlug}`}>
                    <a>{translate('allProducts')}</a>
                  </Link>
                ) : (
                  <Link
                    href={`/${lang}/search?searchId=${props.productModule._id}`}
                  >
                    <a>{translate('allProducts')}</a>
                  </Link>
                )
              ) : null}
            </div>
            <div className="flex-fill"></div>
            {totalCount > SLIDES_PER_VIEW ? (
              <div className={styles.module__header__arrows}>
                <div className={arrowLeft} onClick={onPrev}>
                  <FiChevronLeft color={'#007a3e'} fontSize={24} />
                </div>
                <div className={arrowRight} onClick={onNext}>
                  <FiChevronRight color={'#007a3e'} fontSize={24} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <div
          style={{
            gridTemplateColumns: `repeat(${SLIDES_PER_VIEW}, 1fr)`,
          }}
          className={styles.module__slider2}
        >
          {totalCount > 0 ? (
            getFilledProducts().map((p, i) =>
              p ? (
                <ProductCard product={p} key={props.productModule._id + i} />
              ) : (
                <section className={styles.mainContainer} key={Math.random()}>
                  <article className={styles.background}>
                    <div className={styles.c}></div>
                    <div className={styles.d}></div>
                    <div className={styles.e}></div>
                    <div className={styles.f}></div>
                    <div className={styles.g}></div>
                  </article>
                </section>
              )
            )
          ) : (
            <></>
          )}
          {totalCount > 10 ? (
            categories[0] ? (
              <Link href={`/${lang}/category/${categories[0].nameSlug}`}>
                <div className={styles.showMore}>
                  <span>{translate('showAllProducts')}</span>
                  <div className={styles.showMore__arrow}>
                    <FiChevronRight size={28} />
                  </div>
                </div>
              </Link>
            ) : (
              <Link
                href={`/${lang}/search?searchId=${props.productModule._id}`}
              >
                <div className={styles.showMore}>
                  <span>{translate('showAllProducts')}</span>
                  <div className={styles.showMore__arrow}>
                    <FiChevronRight size={28} />
                  </div>
                </div>
              </Link>
            )
          ) : null}
        </div>
      </div>
    </div>
  )
}
