import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { UseTranslate } from 'hooks/Translate'
import style from './CategoryCard.module.scss'
import { ListNode } from 'utils/listToTree'
import { getBrokenImage } from '@utils/getBrokenImage'
import { getCategoryImageUrl } from '@utils/getImage'

const CategoryCard = (props: ListNode & Category) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const [translate] = UseTranslate()

  return (
    <div className={style.shopCategoryCard}>
      {props.images[0] && (
        <img
          src={getCategoryImageUrl(props)}
          className={style.shopCategoryCard__image}
          onError={getBrokenImage}
          alt={props.name?.toString()}
        />
      )}
      <div className={style.shopCategoryCard__inner}>
        <div className={style.shopCategoryCard__mainCategory}>
          <Link href={`/${lang}/category/${props.nameSlug}`}>{props.name}</Link>
        </div>
        {props.children.slice(0, 4).map((c, i) => (
          <div className={style.shopCategoryCard__subCategory} key={i}>
            <Link href={`/${lang}/category/${c.nameSlug}`}>{c.name}</Link>
          </div>
        ))}
        {props.children.length > 4 && (
          <div className={style.shopCategoryCard__more}>
            <Link href={`/${lang}/category/${props.nameSlug}`}>
              {translate('shop.viewMore')}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default CategoryCard
