import React, { useEffect, useRef } from 'react'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import RequestHelper from '@utils/RequestHelper'

type Props = {
  zoom: number
  className: string
  centerPosition: {
    lat: number
    lng: number
  }
  pickupBranches?: boolean
  zoomControl?: boolean
  mapTypeControl?: boolean
  onMarkerClick: (value: Record<string, combined>) => void
}

type Warehouse = {
  _id: string
  address: string
  warehouseERPAddress: string
  warehouseName: string
  warehouseId: string
  geolocation?: {
    type: { type: string }
    coordinates: [number, number]
  }
}

const BranchGoogleMaps = (props: Props) => {
  const maps = window.google.maps
  const mapRef = useRef<HTMLDivElement>(null)
  function resetMarkerIcons(markers: any) {
    //  reset all the icons back to normal except the one you clicked
    for (let i = 0; i < markers.length; i++) {
      markers[i].setIcon('/images/map-pin.png')
    }
  }
  useEffect(() => {
    initMap()

    async function initMap(): Promise<void> {
      const map = new maps.Map(mapRef.current!, {
        zoomControl: props.zoomControl && !isMobile(),
        mapTypeControl: props.mapTypeControl && !isMobile(),
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ['satellite', 'roadmap'],
        },
        fullscreenControl: false,
        zoomControlOptions: {
          position: maps.ControlPosition.TOP_LEFT,
        },
        center: props.centerPosition,
        zoom: props.zoom,
      })

      let res
      let branches
      if (props.pickupBranches) {
        res = await RequestHelper.kari.get(
          '/branches?page=0&pageSize=2000&filterWithWarehouse=true'
        )
        branches = res.data.data.branches
      } else {
        res = await RequestHelper.catalog.get('/warehouses')
        branches = res.data.data
      }
      const markers = branches
        .filter((w) => w.geolocation)
        .map((warehouse: Warehouse) => {
          const [lat, lng] = warehouse.geolocation.coordinates
          const marker = new maps.Marker({
            //label: branch.branchName,
            position: { lat, lng },
            icon: '/images/map-pin.png',
          })

          maps.event.addListener(marker, 'click', () => {
            resetMarkerIcons(markers)
            marker.setIcon('/images/map-pin-green.png')
            props.onMarkerClick({
              id: warehouse._id,
              title: warehouse.address || warehouse.warehouseERPAddress.replace(/\s+/g, ' '),
              subTitle: warehouse.warehouseName,
              geolocation: {
                coordinates: [lat, lng],
              },
              warehouseId: warehouse.warehouseId,
            })
          })

          return marker
        })

      new MarkerClusterer(map, markers, { imagePath: '/images/m' })
    }
  }, [])

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  return <div className={props.className} ref={mapRef}></div>
}

BranchGoogleMaps.defaultProps = {
  zoomControl: true,
  mapTypeControl: true,
}

export default BranchGoogleMaps
