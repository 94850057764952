import React, { memo, useEffect, useState } from 'react'
import Toast, { ToastInfo } from '@components/Toast/Toast'

interface ToastContext {
  position?: string
  autoDelete?: boolean
  dismissTime: number
  addToast: (toast: ToastInfo) => void
  deleteToast: (index: number) => void
  toasts: ToastInfo[]
}

const initialState: ToastContext = {
  position: 'bottomRight',
  autoDelete: true,
  dismissTime: 2000,
  addToast: () => undefined,
  deleteToast: () => undefined,
  toasts: [],
}

const ToastContext = React.createContext<ToastContext>(initialState)

export default ToastContext

export const ToastContextProvider = memo((props: Record<string, combined>) => {
  const [state, setState] = useState<ToastContext>({
    ...initialState,
    addToast: (toast: ToastInfo) => {
      toast.dismissTime = toast.dismissTime || 400
      setState((state) => ({
        ...state,
        toasts: [...state.toasts, toast],
      }))
    },
    deleteToast: () => {
      setState((state) => ({
        ...state,
        toasts: state.toasts.slice(1),
      }))
    },
  })

  useEffect(() => {
    if (state.toasts.length > 0) {
      const timer = setTimeout(() => {
        setState((state) => ({
          ...state,
          toasts: state.toasts.slice(1),
        }))
      }, 60000)
      return () => clearTimeout(timer)
    }
  }, [state.toasts])

  return (
    <ToastContext.Provider value={state}>
      {props.children}
      <Toast
        toastList={state.toasts}
        position={state.position || 'bottomRight'}
        autoDelete={state.autoDelete || true}
        dismissTime={state.dismissTime || 2000}
      />
    </ToastContext.Provider>
  )
})
