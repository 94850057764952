import React, { memo, useState } from 'react'
import RequestHelper from '@utils/RequestHelper'
import {
  logBrazeEventCleanCart,
  logBrazeEventCreateCart,
} from '@utils/BrazeDefaultUserDetails'

const GOOGLE_API_TOKEN = process.env.NEXT_PUBLIC_GOOGLE_MAPS_TOKEN

interface AppContext {
  loading: boolean
  setLoading: (value?: boolean) => void
  configLoading: boolean
  setConfigLoading: (value?: boolean) => void
  cartLoading: boolean
  setCartLoading: (value?: boolean) => void
  languages?: Language[]
  setLanguage: (language: Language, userId: string) => void
  selectedLanguage?: Partial<Language>
  keywords?: Keyword[]
  deliveryServiceConfig: Record<string, Record<string, DeliveryServiceConfig>>
  courierTipValueConfig: DeliveryServiceConfig
  dictionaries?: DictionaryGroup[]
  loadConfig: () => void
  cart: Cart | undefined
  addressComment: string | undefined
  orderPhoneNumber: string | undefined
  cartTotals: {
    quantity: number
    totalPrice: number
    productToCountMap: { [key: string]: number }
  }
  openCart: boolean
  setOpenCart: () => void
  specialProducts: SpecialProduct[]
  setSpecialProducts: (specialProducts: SpecialProduct[]) => void
  openSpecialDiscount: boolean
  setOpenSpecialDiscount: () => void
  loadCart: (
    lang: string | string[] | undefined,
    isMember: boolean,
    userId?: string | undefined | null,
    warehouseId?: string,
    repeatCartId?: string
  ) => Promise<boolean>
  setCart: (cart: Cart, isMember: boolean) => void
  setAddressComment: (addressComment: string | undefined) => void
  setOrderPhoneNumber: (orderPhoneNumber: string | undefined) => void
  addProductToCart: (
    cartId: string | undefined,
    productId: string,
    id: string,
    type: string,
    lang: string | string[] | undefined,
    amount: number,
    userId: string | null | undefined,
    warehouseId: string | undefined,
    isMember: boolean,
    countStep: number,
    cartTotals: {
      quantity: number
      totalPrice: number
      productToCountMap: { [key: string]: number }
    }
  ) => Promise<Cart>
  clearCart: () => void
  deleteCartProduct: (
    cartId: string | undefined,
    productId: string,
    lang: string | string[] | undefined,
    warehouseId: string | undefined,
    isMember: boolean
  ) => void
  setOrder: (order: Order) => void
  createOrder: (cartId: string) => void
  loadOrder: (orderId: string) => void
  order?: Order
  paymentLoading: boolean
  setPaymentLoading: () => void
  openAddressModal: boolean
  setOpenAddressModal: (visible: boolean) => void
  address: Record<string, combined> | undefined
  setAddress: (address: Record<string, combined> | undefined) => void
  addressType: 'delivery' | 'pickup'
  setAddressType: (addressType: 'delivery' | 'pickup') => void
  loadMenuConfig: (lang: string | string[] | undefined) => void
  menuConfigLoading: boolean
  setMenuConfigLoading: (value?: boolean) => void
  menuConfig: MenuConfig[]
  loadCityStates: (warehouseId?: string, deliveryType?: string) => void
  cityStateLoading: boolean
  setCityStateLoading: (value?: boolean) => void
  cityState: CityState
  searchValue: string
  setSearchValue: (value: string | undefined) => void
  additionalSum: number
  setAdditionalSum: (value: number | undefined) => void
  orderRateIsActive: boolean
  setOrderRateIsActive: (value?: boolean) => void
  primeMembershipIsActive: boolean
  setPrimeMembershipIsActive: (value?: boolean) => void
  walletPaymentIsActive: boolean
  setWalletPaymentIsActive: (value?: boolean) => void
  referalIsActive: boolean
  setReferalIsActive: (value?: boolean) => void
  isInactiveAddress: (
    address: Address | null,
    deliveryServiceConfig: Record<
      string,
      Record<string, DeliveryServiceConfig>
    >,
    modal: boolean
  ) => { inactive: boolean; msg?: string; details?: string }
  inactiveAddressModal: { show: boolean; msg?: string; details?: string }
  setShowInactiveAddressModal: (
    value: boolean,
    msg?: string,
    details?: string
  ) => void
  recipeIsActive: boolean
  setRecipeIsActive: (value?: boolean) => void
}

const initialState: AppContext = {
  loading: false,
  setLoading: () => undefined,
  configLoading: false,
  setConfigLoading: () => undefined,
  cartLoading: true,
  setCartLoading: () => undefined,
  languages: [],
  setLanguage: () => undefined,
  selectedLanguage: {
    key: 'ge',
    title: 'ქართული',
  },
  keywords: [],
  deliveryServiceConfig: {},
  courierTipValueConfig: undefined,
  dictionaries: [],
  loadConfig: () => undefined,
  cart: undefined,
  addressComment: undefined,
  orderPhoneNumber: undefined,
  cartTotals: {
    quantity: 0,
    totalPrice: 0,
    productToCountMap: {},
  },
  openCart: false,
  setOpenCart: () => undefined,
  specialProducts: [],
  setSpecialProducts: () => undefined,
  openSpecialDiscount: false,
  setOpenSpecialDiscount: () => undefined,
  loadCart: () => Promise.resolve(false),
  setCart: () => undefined,
  setAddressComment: () => undefined,
  setOrderPhoneNumber: () => undefined,
  clearCart: () => undefined,
  deleteCartProduct: () => undefined,
  addProductToCart: () => undefined,
  setOrder: () => undefined,
  createOrder: () => undefined,
  loadOrder: () => undefined,
  order: undefined,
  paymentLoading: false,
  setPaymentLoading: () => undefined,
  openAddressModal: false,
  setOpenAddressModal: () => undefined,
  address: {},
  setAddress: () => undefined,
  addressType: 'delivery',
  setAddressType: () => undefined,
  loadMenuConfig: () => undefined,
  setMenuConfigLoading: () => undefined,
  menuConfigLoading: false,
  menuConfig: [],
  loadCityStates: () => undefined,
  setCityStateLoading: () => undefined,
  cityStateLoading: false,
  cityState: undefined,
  searchValue: '',
  setSearchValue: () => undefined,
  additionalSum: 0,
  setAdditionalSum: () => undefined,
  orderRateIsActive: false,
  setOrderRateIsActive: () => undefined,
  primeMembershipIsActive: false,
  setPrimeMembershipIsActive: () => undefined,
  walletPaymentIsActive: false,
  setWalletPaymentIsActive: () => undefined,
  referalIsActive: false,
  setReferalIsActive: () => undefined,
  isInactiveAddress: () => ({ msg: '', details: '', inactive: false }),
  inactiveAddressModal: { show: false },
  setShowInactiveAddressModal: () => false,
  recipeIsActive: false,
  setRecipeIsActive: () => undefined,
}

const AppContext = React.createContext<AppContext>(initialState)

export const AppProvider = memo((props: combined) => {
  const [state, setState] = useState<AppContext>({
    ...initialState,
    loadConfig: async () => {
      state.setLoading()
      state.setConfigLoading()
      const url = '/configs?projectKey=ecommerce'
      const res = await RequestHelper.kari.get(url)
      const { keywords, languages, commonConfigs } = res.data.data
      const configs = commonConfigs.filter(
        (commonConfig) => commonConfig.type === 'deliveryService'
      )

      const deliveryServiceConfig = {
        delivery: {
          corporate: null,
          retail: null,
        },
        pickup: {
          corporate: null,
          retail: null,
        },
      }

      let courierTipValueConfig = null
      configs.forEach((config) => {
        switch (config.config.type) {
          case 'delivery':
          case 'pickup': {
            deliveryServiceConfig[config.config.type][
              config.config.clientType
            ] = config
            break
          }
          case 'courierTip': {
            courierTipValueConfig = Object.assign({}, config)
            break
          }
        }
      })

      setState((state) => ({
        ...state,
        keywords,
        deliveryServiceConfig,
        languages,
        courierTipValueConfig,
      }))
      state.setLoading()
      state.setConfigLoading(false)
    },
    loadCart: async (lang, isMember, userId?, warehouseId?, repeatId = '') => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          state.setLoading()
          state.setCartLoading(true)
          try {
            const res = await RequestHelper.catalog(
              `carts?cartId=${
                localStorage.getItem('cartId') || ''
              }&lang=${lang}&userId=${userId || ''}&warehouseId=${
                warehouseId || ''
              }&repeatId=${repeatId}`
            )

            if (res && res.data && res.data.data) {
              const cart: Cart = res.data.data
              window.localStorage.setItem('cartId', cart._id)
              // if (cart.userId) {
              //   localStorage.removeItem('cartId')
              // }
              if (repeatId && !state.cart && res.data.data.products.length) {
                logBrazeEventCreateCart(userId)
              }
              state.setCart(cart, isMember)
              resolve(true)
            }
          } catch (e) {
            console.error(e)
            if (e === 'CART_NOT_FOUND') {
              window.localStorage.removeItem('cartId')
            }
          }
          state.setLoading()
          state.setCartLoading(false)
        }, 0)
      })
    },
    deleteCartProduct: async (
      cartId,
      productId,
      lang,
      warehouseId,
      isMember
    ) => {
      state.setLoading()
      try {
        let url = `carts/${cartId}/product/${productId}?lang=${lang}`
        if (warehouseId) {
          url += `&warehouseId=${warehouseId}`
        }
        const res = await RequestHelper.catalog.delete(url)
        if (res && res.data && res.data.data) {
          const cart: Cart = res.data.data
          if (cart && (!cart.products || !cart.products.length)) {
            logBrazeEventCleanCart(cart.userId)
          }
          state.setCart(cart, isMember)
        }
      } catch (e) {
        console.error(e)
        if (e === 'CART_NOT_FOUND') {
          window.localStorage.removeItem('cartId')
        }
      }
      state.setLoading()
    },
    addProductToCart: async (
      cartId: string | undefined,
      productId: string,
      id: string,
      type: string,
      lang: string | string[] | undefined,
      amount: number,
      userId: string | null | undefined,
      warehouseId: string | undefined,
      isMember: boolean,
      countStep: number,
      cartTotals: {
        quantity: 0
        totalPrice: 0
        productToCountMap: {}
      }
    ) => {
      return new Promise((resolve) => {
        ;(async function () {
          if (
            cartId &&
            cartTotals.productToCountMap[productId] &&
            Math.round(cartTotals.productToCountMap[productId] * 100) / 100 <= 0
          ) {
            return state.deleteCartProduct(
              cartId,
              productId,
              lang,
              warehouseId,
              isMember
            )
          }

          const data = {
            cartId: cartId,
            prodId: productId,
            type,
            _id: id,
            userId,
            countStep,
          }

          if (state.cart) {
            data.cartId = state.cart._id
            data['userId'] = state.cart.userId
          }

          let updateURL = `/carts?lang=${lang}`
          if (warehouseId) {
            updateURL += `&warehouseId=${warehouseId}`
          }
          const res = await RequestHelper.catalog.put(updateURL, data)

          resolve(res.data.data)

          if (res && res.data && res.data.status === 'SUCCESS') {
            state.setCart(res.data.data, isMember)
            if (!state.cart && res.data && res.data.data) {
              localStorage.setItem('cartId', res.data.data._id)
              if (
                userId &&
                type == 'ADD' &&
                res.data.data.products.length == 1 &&
                (res.data.data.products[0].countStep || 1) ==
                  res.data.data.products[0].quantity
              ) {
                logBrazeEventCreateCart(userId)
              }
            }
            //when cart is empty on checkout
            if (
              !res.data.data.products?.length &&
              window.location.pathname.includes('checkout')
            ) {
              window.location.href = '/'
            }

            if (
              userId &&
              res.data.data &&
              (!res.data.data.products || !res.data.data.products.length)
            ) {
              logBrazeEventCleanCart(userId)
            }
          }
        })()
      })
    },
    setCart: (cart: Cart, isMember: boolean) => {
      if (cart) {
        setState((state) => {
          const productToCountMap: { [key: string]: number } = {}
          let cartProductQuantity = 0
          let cartTotalPrice = 0
          cart.products.forEach((prod) => {
            if (prod && prod.quantity && prod.prodId) {
              cartProductQuantity += prod.quantity
              productToCountMap[prod.prodId] =
                Math.round(prod.quantity * 100) / 100
              if (
                typeof prod.maxAvailable != 'undefined' &&
                prod.price &&
                prod.maxAvailable > 0
              ) {
                if (
                  (prod.loyaltyDiscount?.isClub && isMember) ||
                  (prod.loyaltyDiscount &&
                    !prod.loyaltyDiscount?.isClub &&
                    !prod.loyaltyDiscount?.isSpecial)
                ) {
                  cartTotalPrice += prod.loyaltyDiscount.price * prod.quantity
                } else {
                  cartTotalPrice +=
                    (prod.discount ? prod.discount.price : prod.price) *
                    prod.quantity
                }
              }
            }
          })

          return {
            ...state,
            cart,
            cartTotals: {
              ...state.cartTotals,
              totalPrice: cartTotalPrice,
              quantity: cartProductQuantity,
              productToCountMap,
            },
          }
        })
      }
    },
    setSpecialProducts: (specialProducts: SpecialProduct[]) => {
      setState((state) => ({
        ...state,
        specialProducts,
      }))
    },
    setAddressComment: (addressComment: string | undefined) => {
      setState((state) => ({
        ...state,
        addressComment,
      }))
    },
    setOrderPhoneNumber: (orderPhoneNumber: string | undefined) => {
      setState((state) => ({
        ...state,
        orderPhoneNumber,
      }))
    },
    clearCart: () => {
      localStorage.removeItem('cartId')
      setState((state) => ({
        ...state,
        cart: undefined,
        cartTotals: {
          quantity: 0,
          totalPrice: 0,
          productToCountMap: {},
        },
      }))
    },
    setOrder: (order: Order) => {
      setState((state) => ({
        ...state,
        order,
      }))
    },
    createOrder: async (cartId) => {
      state.setLoading()
      try {
        const res = await RequestHelper.catalog.post(`orders`, {
          cartId,
          orderPhoneNumber: '1234',
        })
        if (res && res.data && res.data.data) {
          const order: Order = res.data.data
          setState((state) => ({
            ...state,
            order,
          }))
          state.clearCart()
          alert('Order Created. TODO: implement payment api')
        }
      } catch (e) {
        console.error(e)
      }
      state.setLoading()
    },
    loadOrder: async (orderId) => {
      state.setLoading()
      try {
        const res = await RequestHelper.catalog(`orders/${orderId}`)
        if (res && res.data && res.data.data) {
          const order: Order = res.data.data
          setState((state) => ({
            ...state,
            order,
          }))
        }
      } catch (e) {
        console.error(e)
        if (e === 'ORDER_NOT_FOUND') {
          // TODO handle order not found if necessary
        }
      }
      state.setLoading()
    },
    setLanguage: (language: Language, userId: string) => {
      localStorage.setItem('lang', language ? language.key : 'ge')
      if (userId) {
        RequestHelper.kari.put('/users/language', {
          language: language ? language.key : 'ge',
        })
      }
      RequestHelper.reset()
      setState((state) => ({
        ...state,
        selectedLanguage: language,
      }))
    },
    setLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        loading: typeof value === 'boolean' ? value : !state.loading,
      }))
    },
    setConfigLoading: () => {
      setState((state) => ({
        ...state,
        configLoading: !state.configLoading,
      }))
    },
    setCartLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        cartLoading: typeof value === 'boolean' ? value : !state.cartLoading,
      }))
    },
    setPaymentLoading: () => {
      setState((state) => ({
        ...state,
        paymentLoading: !state.paymentLoading,
      }))
    },
    setOpenCart: () => {
      setState((state) => {
        return {
          ...state,
          openCart: !state.openCart,
        }
      })
    },
    setOpenSpecialDiscount: () => {
      setState((state) => {
        return {
          ...state,
          openSpecialDiscount: !state.openSpecialDiscount,
        }
      })
    },
    setOpenAddressModal: (visible) => {
      setState((state) => {
        return {
          ...state,
          openAddressModal: visible,
        }
      })
    },
    setAddress: (address) => {
      setState((state) => {
        return {
          ...state,
          address,
        }
      })
    },
    setAddressType: (addressType) => {
      setState((state) => {
        return {
          ...state,
          addressType,
        }
      })
    },
    loadMenuConfig: async (lang) => {
      state.setLoading()
      state.setMenuConfigLoading(true)
      const url = `/menuConfig?lang=${lang}&status=true`
      const res = await RequestHelper.kari.get(url)
      const menuConfig = res.data.data

      setState((state) => ({
        ...state,
        menuConfig,
      }))
      state.setLoading()
      state.setMenuConfigLoading(false)
    },
    setMenuConfigLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        menuConfigLoading:
          typeof value === 'boolean' ? value : !state.menuConfigLoading,
      }))
    },
    loadCityStates: async (warehouseId?, deliveryType?) => {
      state.setLoading()
      state.setCityStateLoading(true)
      let url = `/cityState/shop/states?platform=1`
      if (warehouseId) {
        url += `&warehouseId=${warehouseId}`
      }
      if (deliveryType) {
        const type = deliveryType == 'delivery' ? 1 : 2
        url += `&deliveryType=${type}`
      }
      const res = await RequestHelper.kari.get(url)
      const cityState = res.data.data

      setState((state) => ({
        ...state,
        cityState,
      }))
      state.setLoading()
      state.setCityStateLoading(false)
    },
    setCityStateLoading: (value?: boolean) => {
      setState((state) => ({
        ...state,
        cityStateLoading:
          typeof value === 'boolean' ? value : !state.cityStateLoading,
      }))
    },
    setSearchValue: (searchValue) => {
      setState((state) => {
        return {
          ...state,
          searchValue,
        }
      })
    },
    setAdditionalSum: (additionalSum) => {
      setState((state) => {
        return {
          ...state,
          additionalSum,
        }
      })
    },
    setOrderRateIsActive: (orderRateIsActive) => {
      setState((state) => {
        return {
          ...state,
          orderRateIsActive,
        }
      })
    },
    setPrimeMembershipIsActive: (primeMembershipIsActive) => {
      setState((state) => {
        return {
          ...state,
          primeMembershipIsActive,
        }
      })
    },
    setWalletPaymentIsActive: (walletPaymentIsActive) => {
      setState((state) => {
        return {
          ...state,
          walletPaymentIsActive,
        }
      })
    },
    setReferalIsActive: (referalIsActive) => {
      setState((state) => {
        return {
          ...state,
          referalIsActive,
        }
      })
    },
    setRecipeIsActive: (recipeIsActive) => {
      setState((state) => {
        return {
          ...state,
          recipeIsActive,
        }
      })
    },
    isInactiveAddress: (
      address: Address,
      deliveryServiceConfig: Record<
        string,
        Record<string, DeliveryServiceConfig>
      >,
      modal = false
    ): { inactive: boolean; msg: string; details?: string } => {
      if (!address) return { msg: '', inactive: false }
      const inactive =
        address &&
        address.type == 'pickup' &&
        (!address.isWarehouseActive ||
          (deliveryServiceConfig &&
            deliveryServiceConfig.pickup &&
            !deliveryServiceConfig.pickup?.retail?.config?.isPublished))

      if (address.type == 'delivery' && !address.isWarehouseActive) {
        if (modal) {
          return {
            inactive: address.deliveryServiceIsPaused,
            msg: 'ecommerce.address.inactiveWarehouse.paused.modal.warning',
            details:
              'ecommerce.address.inactiveWarehouse.paused.modal.warning.details',
          }
        }
        return {
          inactive: address.deliveryServiceIsPaused,
          msg: 'ecommerce.address.inactiveWarehouse.paused.warning',
          details: 'ecommerce.address.inactiveWarehouse.paused.details',
        }
      } else if (inactive) {
        if (modal)
          return {
            inactive,
            msg: 'ecommerce.address.inactiveWarehouse.pickup.modal.warning',
            details:
              'ecommerce.address.inactiveWarehouse.pickup.modal.warning.details',
          }
        return {
          inactive,
          msg: 'ecommerce.address.inactiveWarehouse.pickup.warning',
          details: '',
        }
      }
      return { msg: '', inactive: false }
    },

    setShowInactiveAddressModal: (
      showInactiveAddressModal: boolean,
      msg?: string,
      details?: string
    ) => {
      setState((state) => {
        return {
          ...state,
          inactiveAddressModal: {
            show: showInactiveAddressModal,
            msg,
            details,
          },
        }
      })
    },
  })

  return (
    <AppContext.Provider value={state}> {props.children}</AppContext.Provider>
  )
})

export default AppContext
