import React, { FC, useEffect } from 'react'
import cn from 'classnames'

import { FiX } from 'react-icons/fi'
import Backdrop from '../Backdrop/Backdrop'
import { hasScrollbar, paddingInlineEnd } from '@utils/padding-inline-end'

import styles from './SideBar.module.scss'

interface SideBarProps {
  visible: boolean
  backdrop?: boolean
  onClose: () => void
  title: string | JSX.Element
  content: string | JSX.Element
  footer?: string | JSX.Element
}

const SideBar: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SideBarProps>>>> = (props: SideBarProps) => {
  useEffect(() => {
    const scroll = hasScrollbar()
    document.body.style.overflow = props.visible ? 'hidden' : 'auto'
    paddingInlineEnd(props.visible, scroll)
  }, [props.visible])

  const className = cn({
    [styles.sidebar]: true,
    [styles.showSidebar]: props.visible,
  })

  return (
    <>
      <Backdrop
        onClick={props.onClose}
        visible={Boolean(props.backdrop) && props.visible}
      />
      <div className={className}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title} style={{ textAlign: 'left' }}>
              {props.title}
            </div>
            <div className={styles.close} onClick={() => props.onClose()}>
              <FiX />
            </div>
          </div>
          <div className={styles.content}>{props.content}</div>
          {props.footer ? (
            <div className={styles.footer}>{props.footer}</div>
          ) : null}
        </div>
      </div>
    </>
  )
}

SideBar.defaultProps = {
  visible: false,
  backdrop: true,
}

export default SideBar
