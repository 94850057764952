import classNames from 'classnames'
import React, { FC, useEffect, useRef } from 'react'

import styles from './Radio.module.scss'

interface RadioProps {
  disabled?: boolean
  id: string
  value?: string | number
  onChange?: (value: string) => void
  checked?: boolean
  defaultChecked?: boolean
  name: string
  label: string
}

const Radio: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<RadioProps>>>> = (props: RadioProps) => {
  const radioRef = useRef<HTMLInputElement>(null)

  const disabledClass = classNames({
    disabled: props.disabled,
  })

  useEffect(() => {
    if (radioRef.current) {
      radioRef.current.checked = props.checked as boolean
    }
  }, [props.checked])

  return (
    <div className={styles.container}>
      <input
        className={styles.radio}
        ref={radioRef}
        name={props.name}
        value={props.value}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={(e) =>
          props.onChange ? props.onChange(e.target.value) : null
        }
        type="radio"
        id={props.id}
        disabled={props.disabled}
      />
      <label htmlFor={props.id} className={disabledClass}>
        {props.label}
      </label>
    </div>
  )
}

export default Radio
