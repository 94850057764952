import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react'

import { useKeenSlider } from 'keen-slider/react'
import { LeftArrow, RightArrow } from '../Icon/Icon'

import cn from 'classnames'
import styles from './Slider.module.scss'

interface SliderProps {
  arrows?: boolean
  dots?: boolean
  animate?: boolean
  slidesPerView?: number
  leftArrow?: JSX.Element
  rightArrow?: JSX.Element
  children: ReactNode
}

const Slider: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SliderProps>>>> = (props: SliderProps) => {
  const [pause, setPause] = useState<boolean>(false)
  const [stopAutoPlay, setStopAutoPlay] = useState<boolean>(false)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const timer = useRef<NodeJS.Timer>()
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slidesPerView: props.slidesPerView,
    duration: props.animate ? 1000 : 0,
    initial: 0,
    slides: '.slide',
    dragStart: () => {
      setPause(true)
      changeAutoPlay()
    },
    dragEnd: () => {
      setPause(false)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener('mouseover', () => {
        //changeAutoPlay()
        setPause(true)
      })
      sliderRef.current.addEventListener('mouseout', () => {
        setPause(false)
      })
    }
  }, [sliderRef])

  useEffect(() => {
    if (!stopAutoPlay) {
      timer.current = setInterval(() => {
        if (!pause && slider) {
          slider.next()
        }
      }, 5000)
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [pause, slider])

  const changeAutoPlay = () => {
    if (timer.current) {
      clearInterval(timer.current)
      setStopAutoPlay(true)
    }
  }

  return (
    <>
      <div className={styles.sliderWrapper}>
        <div ref={sliderRef} className={styles.keenSlider}>
          {props.children}
        </div>
        {props.arrows && slider && slider.details().size > 1 ? (
          <>
            <div
              className={`${styles.arrow} ${styles.arrow__left} ${styles.visible}`}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                slider.prev()
                changeAutoPlay()
              }}
            >
              {props.leftArrow}
            </div>
            <div
              className={`${styles.arrow} ${styles.arrow__right} ${styles.visible}`}
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                slider.next()
                changeAutoPlay()
              }}
            >
              {props.rightArrow}
            </div>
          </>
        ) : null}
      </div>
      {props.dots && slider && slider.details().size > 1 ? (
        <div className={styles.dots}>
          {[...Array(slider.details().size).keys()].map((idx) => {
            const dots = cn({
              [styles.dot]: true,
              [styles.active]: currentSlide === idx,
            })

            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx)
                  changeAutoPlay()
                }}
                className={dots}
              />
            )
          })}
        </div>
      ) : null}
    </>
  )
}

Slider.defaultProps = {
  arrows: true,
  dots: true,
  animate: true,
  slidesPerView: 1,
  leftArrow: <LeftArrow />,
  rightArrow: <RightArrow />,
}

export default Slider
