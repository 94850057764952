import React from 'react'
import { FiLoader } from 'react-icons/fi'

import { UseTranslate } from 'hooks/Translate'

import styles from './Spinner.module.scss'

interface Props {
  keyword?: string;
}

export default function Spinner(props: Props) {
  const [translate] = UseTranslate()

  return (
    <div className={styles.spinner}>
      <FiLoader className={styles.loader} />
      {translate(props.keyword || 'loading')}
    </div>
  )
}
