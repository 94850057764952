import { UseTranslate } from 'hooks/Translate'
import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './GoogleMaps.module.scss'

interface GoogleMapsProps {
  zoom: number
  class: string
  geoCodeByPinLocation: boolean
  location?: number[],
  autoZoom: boolean;
  showMarker?: boolean
  onPrediction?: (prediction: Record<string, combined>) => void
  centerPosition: {
    lat: number
    lng: number
  }
  zoomControl?: boolean
  mapTypeControl?: boolean
}

const GoogleMaps: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<GoogleMapsProps>>>> = (props: GoogleMapsProps) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const maps = window.google.maps
  const [map, setMap] = useState<google.maps.Map>()
  const [marker, setMarker] = useState<google.maps.Marker>()
  const [translate] = UseTranslate()

  useEffect(() => {
    const map = new maps.Map(mapRef.current as HTMLElement, {
      zoomControl: props.zoomControl && !isMobile(),
      mapTypeControl: props.mapTypeControl && !isMobile(),
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
        mapTypeIds: ['satellite', 'roadmap'],
      },
      streetViewControl: false,
      keyboardShortcuts: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT,
      },
      center: props.centerPosition,
      zoom: props.zoom
    })

    if (props.showMarker) {
      const marker = new maps.Marker({
        position: props.centerPosition,
        draggable: false,
        map: map,
      })

      function HTMLMarker() {}

      HTMLMarker.prototype = new maps.OverlayView()

      HTMLMarker.prototype.onAdd = function () {
        const button = document.createElement('button')
        button.type = 'button'
        button.className = styles.selectAddress
        button.innerText = translate('selectAddress')
        this.getPanes().overlayImage.appendChild(button)
        button.onclick = function () {
          if (props.onPrediction) {
            const geocoder = new maps.Geocoder()
            const location = map.getCenter()
            geocoder.geocode({ location }, (result) => {
              if (result && result.length) {
                const prediction = result[0]
                const formatterPrediction = {
                  title: prediction.formatted_address,
                  subTitle: prediction.address_components[2].long_name,
                  geolocation: {
                    coordinates: [location.lat(), location.lng()],
                  },
                }

                props.onPrediction(formatterPrediction)
              }
            })
          }
        }
      }

      HTMLMarker.prototype.draw = function () {
        const { overlayImage } = this.getPanes()
        const position = this.getProjection().fromLatLngToDivPixel(
          map.getCenter()
        )
        overlayImage.style.left = position.x + 'px'
        overlayImage.style.top = position.y - 45 + 'px'
        overlayImage.style.transform = 'translate(-50%, -100%)'
        overlayImage.style.width = 'auto'
      }

      const htmlMarker = new HTMLMarker()
      htmlMarker.setMap(map)

      map.addListener('drag', () => {
        marker.setPosition(map.getCenter())
        htmlMarker.getPanes().overlayImage.style.display = 'none'
      })

      map.addListener('dragend', () => {
        window.setTimeout(function () {
          marker.setPosition(map.getCenter())
          htmlMarker.getPanes().overlayImage.style.display = 'block'
        }, 200)
      })
      setMarker(marker)
    }
    setMap(map)
    map.setZoom(16);
  }, [])

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  useEffect(() => {
    if (props.location) {
      const [lat, lng] = props.location;
      map?.setCenter({ lat, lng })
      marker?.setPosition({ lat, lng })
      if (props.autoZoom) map?.setZoom(16);
    }
  }, [props.location])

  return <div className={props.class} ref={mapRef}></div>
}

GoogleMaps.defaultProps = {
  showMarker: false,
  zoomControl: true,
  mapTypeControl: true,
}

export default GoogleMaps
