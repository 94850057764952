import React, { FC } from 'react'

import styles from './Action.module.scss'

interface ActionProps {
  icon: JSX.Element
  text: string
  color: string
  onClick?: () => void
}

const Action: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<ActionProps>>>> = (props: ActionProps) => {
  return (
    <div
      className={styles.container}
      style={{ color: props.color }}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <div className={styles.icon}>{props.icon}</div>
      <div className={styles.text}>{props.text}</div>
    </div>
  )
}

export default Action
