import { FC, useEffect, useState } from 'react'

import cn from 'classnames'

import { RiEdit2Line } from 'react-icons/ri'

import styles from './CourierTip.module.scss'
import { UseTranslate } from 'hooks/Translate'

interface ICourierTip {
  value: number
  active: boolean
  custom?: boolean
  onClick?: (value: number) => void
}

const CourierTip: FC<ICourierTip> = ({ active, value, onClick, custom }) => {
  const [translate] = UseTranslate()
  const containerStyle = cn({
    [styles.container]: true,
    [styles.active]: active,
  })

  const [customValue, setCustomValue] = useState<number>()

  useEffect(() => {
    if (custom) {
      setCustomValue(value);
    }
  }, [value])

  const getCustomTipTitle = () => {
    if (customValue && customValue > -1) {
      if (customValue >= 10) return customValue.toFixed(1) + ' ₾'
        return customValue.toFixed(2) + ' ₾'
    }
    return translate('ecommerce.rateOrder.leaveTip.other')
  }
  return (
    <div className={containerStyle} onClick={() => onClick(value)}>
      {custom ? (
        <>
          <div className={styles.tipValue} >
            <div className={styles.icon}>
              <RiEdit2Line width={20} height={20} />
            </div>
            <span>{getCustomTipTitle()}</span>
          </div>
        </>
      ) : (
        <div className={styles.tipValue}>{value.toFixed(2)} ₾</div>
      )}
    </div>
  )
}

export default CourierTip
