import React, { FC, useContext, useEffect, useState } from 'react'

import { Radio, TextArea, TextInput } from '@components/ui'
import {
  GoogleMaps,
  BranchGoogleMaps,
  PlacesAutocomplete,
} from '@components/common'

import cn from 'classnames'

import styles from './AddressEditor.module.scss'
import AddressTabs from './AddressTabs'
import { UseTranslate } from 'hooks/Translate'
import AppContext from '../../contexts/AppContext'

interface AddressEditorProps {
  form: Record<string, combined>
  statusChange: (value: Form) => void
  showTabs?: boolean
  errorText?: string
  onReady?: () => void
}

interface Form {
  valid: boolean
  values: AddressForm
}

interface AddressForm {
  address?: Record<string, combined>
  place: string
  comment?: string
  addressName?: string
  type: 'delivery' | 'pickup'
  warehouseId?: string
}

const AddressEditor: FC<
  React.PropsWithChildren<
    React.PropsWithChildren<React.PropsWithChildren<AddressEditorProps>>
  >
> = (props: AddressEditorProps) => {
  const [translate] = UseTranslate()

  const maps = window.google.maps
  const geocoder = new maps.Geocoder()
  const { addressType } = useContext(AppContext)
  const [form, setForm] = useState<AddressForm>({
    place: translate('ecommerce.homeValue'),
    addressName: translate('ecommerce.homeValue'),
    type: addressType,
  })
  const [autoZoom, setAutoZoom] = useState(false)

  useEffect(() => {
    if (props.form.address) {
      setForm(props.form as AddressForm)
    }
  }, [props.form])

  useEffect(() => {
    props.statusChange({
      valid: form.address ? true : false,
      values: form,
    })
  }, [form])

  useEffect(() => {
    if (props.onReady) {
      setTimeout(props.onReady, 10)
    }
  }, [])

  const valueChange = (name: string, value: combined | null) => {
    setForm((state) => ({
      ...state,
      [name]: value,
    }))
  }

  const map = cn({
    [styles.mapWrapper]: true,
  })

  const branchMapWrapper = cn({
    [styles.hasMargin]: props.showTabs,
    [styles.branchMapWrapper]: true,
  })

  const formatAddress = (addr: google.maps.GeocoderResult) => {
    return {
      id: addr.place_id,
      title: `${addr.address_components[0]?.long_name} ${addr.address_components[1]?.long_name}`,
      subTitle: addr.address_components[3]?.long_name,
      geolocation: {
        coordinates: [
          addr.geometry.location.lat(),
          addr.geometry.location.lng(),
        ],
      },
    }
  }

  const onPlaceChange = async (value: Record<string, combined> | null) => {
    setAutoZoom(true)
    if (value) {
      if (value.id && value.geolocation) {
        valueChange('address', value)
        return value.title
      } else {
        return new Promise((resolve, reject) => {
          const getGeoCode = (query: Record<string, combined>) => {
            geocoder.geocode(query, (result) => {
              if (result) {
                const addr = result.find((x) =>
                  x.types.includes('street_address')
                )

                const address = addr
                  ? formatAddress(addr)
                  : formatAddress(result[0])

                valueChange('address', address)
                resolve(address.title)
              }
            })
          }

          if (value.id) {
            return getGeoCode({
              placeId: value.id,
            })
          }

          navigator.geolocation.getCurrentPosition((position) => {
            getGeoCode({
              location: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
          }, reject)
        })
      }
    }

    valueChange('address', '')
    return ''
  }

  return (
    <>
      <AddressTabs
        type={form.type}
        showTabs={props.showTabs}
        hasMargin={!!form.address && form.type === 'delivery'}
        typeChanged={(type) => {
          valueChange('type', type)
          valueChange('address', null);
        }}
      />
      <div className={styles.container}>
        <form className={styles.addressForm}>
          {form.type === 'delivery' && (
            <>
              <GoogleMaps
                class={map}
                showMarker={true}
                geoCodeByPinLocation={true}
                onPrediction={(address) => {
                  setAutoZoom(false)
                  valueChange('address', address)
                }}
                autoZoom={autoZoom}
                location={form.address?.geolocation?.coordinates}
                zoom={12}
                centerPosition={{ lat: 41.732438, lng: 44.7688134 }}
              />
              <div className={styles.formControl}>
                <PlacesAutocomplete
                  id="address"
                  label={translate('ecommerce.address')}
                  value={form.address?.title}
                  name="address"
                  onChange={onPlaceChange}
                  placeholder={translate('ecommerce.addressPlaceholder')}
                  errorText={props.errorText}
                  minSymbols={3}
                />
              </div>
              <div className={styles.formControl}>
                <div className={styles.radioGroup}>
                  <Radio
                    id="home"
                    name="place"
                    onChange={(value) => {
                      valueChange('place', value)
                      valueChange('addressName', value)
                    }}
                    label={translate('ecommerce.homeValue')}
                    value={translate('ecommerce.homeValue')}
                    checked={form.place === translate('ecommerce.homeValue')}
                  />
                  <Radio
                    id="work"
                    name="place"
                    onChange={(value) => {
                      valueChange('place', value)
                      valueChange('addressName', value)
                    }}
                    value={translate('ecommerce.workValue')}
                    label={translate('ecommerce.workValue')}
                    checked={form.place === translate('ecommerce.workValue')}
                  />
                  <Radio
                    id="other"
                    name="place"
                    onChange={(value) => {
                      valueChange('place', value)
                      valueChange('addressName', value)
                    }}
                    value={translate('ecommerce.otherValue')}
                    label={translate('ecommerce.otherValue')}
                    checked={form.place === translate('ecommerce.otherValue')}
                  />
                </div>
              </div>
              <div className={styles.formControl}>
                <TextInput
                  id="addressName"
                  name="addressName"
                  label={translate('ecommerce.addressName')}
                  value={form.addressName}
                  onChange={(value) => valueChange('addressName', value)}
                  placeholder={translate('ecommerce.addressNamePlaceholder')}
                />
              </div>
              <div className={styles.formControl}>
                <TextArea
                  id="comment"
                  name="comment"
                  label={translate('ecommerce.commentForCourier')}
                  value={form.comment}
                  onChange={(value) => valueChange('comment', value)}
                  placeholder={translate(
                    'ecommerce.additionalCommentPlaceholder'
                  )}
                />
              </div>
            </>
          )}
          {form.type === 'pickup' && (
            <>
              <BranchGoogleMaps
                className={branchMapWrapper}
                zoom={10}
                pickupBranches
                centerPosition={{ lat: 41.715138, lng: 44.827096 }}
                onMarkerClick={(value) => valueChange('address', value)}
              />
              <div className={styles.formControl}>
                <TextInput
                  id="address"
                  name="address"
                  label={translate('ecommerce.branchAddress_pickup')}
                  value={form.address?.title}
                  onChange={(value) => valueChange('__', value)}
                  placeholder={translate('ecommerce.addressPlaceholder_pickup')}
                  readOnly={true}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </>
  )
}

export default AddressEditor
