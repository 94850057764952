import RequestHelper from '@utils/RequestHelper'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef, useState, useContext } from 'react'

import styles from './SearchField.module.scss'

import cn from 'classnames'

import { SearchSuggestion } from '..'
import { Button, TextInput } from '@components/ui'
import { UseTranslate } from 'hooks/Translate'
import useDebounce from 'hooks/Debounce'
import { BiSearch } from 'react-icons/bi'
import AppContext from 'contexts/AppContext'

interface SearchFieldProps {
  suggestionsOpen: (value: boolean) => void
  warehouseId: string
}

const SearchField: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SearchFieldProps>>>> = (props: SearchFieldProps) => {
  const router = useRouter()
  let { lang } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const inputRef = useRef<HTMLFormElement>(null);

  const { searchValue, setSearchValue } = useContext(AppContext)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestions, setSuggestions] = useState<Product[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [translate] = UseTranslate()
  const searchDebounce = useDebounce(searchValue, 200)

  useEffect(() => {
    getSuggestions(searchDebounce)
  }, [searchDebounce])

  const getSuggestions = async (searchText: string) => {
    if (!searchText || searchValue.length < 2) {
      return
    }
    let url = `products/suggestions?lang=${lang}&searchText=${searchText ? encodeURIComponent(searchText) : ''}&limit=5&sortField=isInStock&sortDirection=-1`

    if (props.warehouseId) {
      url += `&warehouseId=${props.warehouseId}`
    }
    const suggestions = await RequestHelper.catalog(url)

    setSuggestions(suggestions.data?.data?.products)
    setTotalCount(suggestions.data?.data?.totalCount)
  }

  useEffect(() => {
    props.suggestionsOpen(showSuggestions)
  }, [showSuggestions])

  const resetValues = () => {
    setSuggestions([])
    setTotalCount(0)
    document.getElementsByTagName('body')[0].style.cssText = 'overflow: auto; padding-inline-end: 0px;';
  }

  useEffect(() => {
    if (!searchValue) {
      inputRef.current?.reset();
      resetValues();
    } else {
      document.getElementsByTagName('body')[0].style.cssText = 'overflow-y: hidden !important; padding-inline-end: 0px;';
    }
  }, [searchValue])

  const container = cn({
    [styles.container]: true,
    [styles.focused]: showSuggestions,
  })

  function handleChange(value: any) {
    setSearchValue(value)
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    goToSearch()
  }

  function goToSearch() {
    document.getElementsByTagName('body')[0].style.cssText = 'overflow: auto; padding-inline-end: 0px;';
    if (searchValue) {
      document.getElementById('search')?.blur()
      router.push('/' + lang + '/search?searchText=' + searchValue)
      setShowSuggestions(false)
    }
  }

  return (
    <div className={container}>
      <SearchSuggestion
        totalCount={totalCount}
        onClose={() => {
          setShowSuggestions(false)
          inputRef.current?.reset()
          setSuggestions([])
          resetValues();
        }}
        suggestions={suggestions}
        visible={showSuggestions}
      />
      <form
        action={`/${lang}/search`}
        ref={inputRef}
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextInput
          id="search"
          name="searchText"
          style={{
            position: showSuggestions ? 'relative' : 'initial',
            zIndex: showSuggestions ? 102 : 0,
            height: '44px',
          }}
          placeholder={translate('ecommerce.search.placeholder')}
          onChange={(value) => (value ? handleChange(value) : resetValues())}
          onFocus={() => {
            setShowSuggestions(true)
          }}
        />
        <Button
          size={44}
          style={{
            height: '44px',
            marginLeft: '-44px',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            zIndex: showSuggestions ? 102 : 0,
          }}
          iconLeft={<BiSearch />}
          onClick={goToSearch}
        />
      </form>
    </div>
  )
}

export default SearchField
