import Backdrop from '@components/ui/Backdrop/Backdrop'
import React, { FC } from 'react'

import styles from './SearchSuggestion.module.scss'
import SuggestionItem from './SuggestionItem'
import { UseTranslate } from 'hooks/Translate'

interface SearchSuggestionProps {
  suggestions: Product[]
  onClose: () => void
  totalCount: number
  visible: boolean
}

const SearchSuggestion: FC<React.PropsWithChildren<React.PropsWithChildren<React.PropsWithChildren<SearchSuggestionProps>>>> = (
  props: SearchSuggestionProps
) => {

  const [translate] = UseTranslate()

  return (
    <>
      <Backdrop onClick={() => props.onClose()} visible={props.visible} />
      {props.visible && props.suggestions.length ? (
        <ul className={styles.SearchSuggestion__result}>
          {props.suggestions.map((suggestion) => (
            <li key={suggestion._id}>
              <SuggestionItem
                suggestion={suggestion}
                onNavigate={() => props.onClose()}
              />
            </li>
          ))}
          {props.totalCount ? (
            <li>
              <span className={styles.totalCount}>
                {translate('searchResult')}: <strong>{props.totalCount} {translate('productKeyword')}</strong>
              </span>
            </li>
          ) : null}
        </ul>
      ) : null}
    </>
  )
}

export default SearchSuggestion
