import React, { useState } from 'react'
import style from './MobileProductCategory.module.scss'
import { ListNode } from 'utils/listToTree'
import { UseTranslate } from 'hooks/Translate'
import { CategoryGrid } from '@components/ui/Icon/Icon'
import SideBar from '@components/ui/SideBar/SideBar'
import { ProductCategory } from '@components/common'
import { UniCat } from '../../../pages/[lang]/category/[category-slug]'

type Props = Category &
  ListNode & {
    expanded: Category & ListNode
    selected: Category & ListNode
    categories: UniCat[]
  }

export default function MobileProductCategory(props: Props) {
  const [translate] = UseTranslate()
  const [showSideBar, setShowSideBar] = useState(false)

  const onSideBarClose = () => {
    setShowSideBar(!showSideBar)
  }

  const isExpanded = ({ _id }: Category) => {
    return _id === props.expanded?._id
  }

  const GetCategories = () => {
    return (
      <>
        <div className={`col-md-12 ${style.mobileProductCategory__wrapper}`}>
          {props.categories.map((c, i) => (
            <ProductCategory
              {...c}
              expanded={isExpanded(c)}
              selected={props.selected}
              key={i}
              onCateGoryClick={() => {
                setShowSideBar(false)
              }}
            />
          ))}
        </div>
      </>
    )
  }

  return (
    <>
      <div className={style.mobileProductCategory}>
        <div className={style.mobileProductCategory__categoryButton}>
          <div
            className={style.mobileProductCategory__gridButton}
            onClick={() => {
              setShowSideBar(true)
            }}
          >
            <div>
              {translate('category')} {':'}{' '}
              <span className={style.mobileProductCategory__greenText}>
                {props.expanded.name}
              </span>
            </div>
            <CategoryGrid />
          </div>
        </div>
        <SideBar
          visible={showSideBar}
          onClose={onSideBarClose}
          content={<GetCategories />}
          title={translate('ecommerce.categories')}
        />
      </div>
    </>
  )
}
