import { Lari, ProductCardImagePlaceholder } from '@components/ui/Icon/Icon'
import Quantity from '@components/ui/Quantity/Quantity'
import AppContext from 'contexts/AppContext'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import styles from './ProductCard.module.scss'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { UseTranslate } from '../../../hooks/Translate'
import { Button, Label } from '@components/ui'
import ToastContext from 'contexts/ToastContext'
import RequestHelper from '@utils/RequestHelper'
import { getBrokenImage } from '@utils/getBrokenImage'
import { useUser } from '@auth0/nextjs-auth0'
import UserContext from 'contexts/UserContext'
import tagManagerUtil from '@utils/tagManagerUtil'
import { getProductPrimaryImageUrl } from '@utils/getImage'
import { UniCat, getCategoryLevels } from '@utils/categoryUtils'
import CategoryContext from 'contexts/CategoryContext'
import Link from 'next/link'

interface ProductCardProps {
  product: Product
  hasDelete?: boolean
  wishList?: Wishlist
  reload?(): void
}

const ProductCard = (props: ProductCardProps) => {
  const router = useRouter()
  const [translate] = UseTranslate()
  let { lang } = router.query
  const { categorySlug, tab } = router.query
  if (!lang) {
    lang = 'ge'
  }
  const { selectedLanguage, cartTotals, addProductToCart, cart, isInactiveAddress, setShowInactiveAddressModal, deliveryServiceConfig } = useContext(AppContext)
  const { selectedAddress, customerInfo } = useContext(UserContext)
  const { categoriesData } = useContext(CategoryContext)
  const { user } = useUser()
  const [showQuantity, setShowQuantity] = useState(false)
  const { addToast } = useContext(ToastContext)
  const [categories, setCategories] = useState<UniCat[]>([])

  useEffect(() => {
    setCategories(categoriesData as UniCat[])
  }, [categoriesData])

  const parentRef = useRef<HTMLDivElement>(null)

  const generatedText = useMemo(() => {
    const loyaltyDiscount = props.product.loyaltyDiscount

    if (loyaltyDiscount?.isSpecial) {
      return {
        text: [
          loyaltyDiscount?.discountName ? loyaltyDiscount?.discountName[selectedLanguage ? selectedLanguage.key : 'ge'] : '',
          loyaltyDiscount?.discountSubName ? loyaltyDiscount?.discountSubName[selectedLanguage ? selectedLanguage.key : 'ge'] : '',
        ]
          .filter((f) => !!f)
          .join(' = '),
        color: '#A4D442',
      }
    }

    if (
      props.product.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      //loyaltyDiscount
      return {
        text: props.product.loyaltyDiscount?.discountName ? props.product.loyaltyDiscount?.discountName[selectedLanguage ? selectedLanguage.key : 'ge'] : ''
      }
    }
    if (
      customerInfo?.cards.length > 0 &&
      props.product.loyaltyDiscount &&
      !props.product.loyaltyDiscount?.isClub &&
      !props.product.loyaltyDiscount?.isSpecial
    ) {
      return {
        text: props.product.loyaltyDiscount?.discountName ? props.product.loyaltyDiscount?.discountName[selectedLanguage ? selectedLanguage.key : 'ge'] : ''
      }
    }
    if (
      props.product?.discount?.price &&
      props.product?.discount?.discountName
    ) {
      return {
        text: props.product?.discount?.discountName[selectedLanguage ? selectedLanguage.key : 'ge']
      }
    }
  }, [customerInfo])

  const isInStock = () => {
    return (
      props.product.stock &&
      !(props.product.stock?.stock - props.product.stockReservationAmount < 1)
    )
  }

  const navigateToDetails = () => {
    if (!isMobile) {
      const basePath = router.query.returnTo || router.asPath
      router.replace(
        `?lang=${lang}&id=${props.product.nameSlug
        }&returnTo=${basePath}&category-slug=${categorySlug}&tab=${tab}&uuid=${Math.floor(
          Math.random() * 100000
        )}`,
        `/${lang}/product/${props.product.nameSlug}`,
        {
          shallow: true,
          scroll: false,
        }
      )
    }
  }

  const addToCart = () => {
    return (

      <div ref={parentRef}>
        <button


          // ref={parentRef}
          className={styles.addToCart}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            window.setTimeout(async function () {
              const { inactive, msg, details } = isInactiveAddress(selectedAddress, deliveryServiceConfig, true);
              if (inactive) {
                setShowInactiveAddressModal(inactive, msg, details)
                return;
              }

              const qnt = cartTotals.productToCountMap[props.product.productId]

              const categoryLevels = getCategoryLevels(props.product.categoryId, categories, lang.toString());
              tagManagerUtil.AddtoCart({
                ProductName: props.product.title,
                ProductID: props.product.productId,
                UserID: user?.sub,
                ProductPrice: props.product.discount ? props.product.discount.price : props.product.stock.price,
                ProductCategory: props.product.categoryId,
                Quantity: 1,
                categoryLevels
              })

              const result = await addProductToCart(
                cart?._id,
                props.product.productId,
                props.product._id,
                'ADD',
                lang,
                qnt,
                user?.sub,
                selectedAddress?.warehouseId,
                customerInfo?.clubMembership?.current?.isMember,
                props.product.countStep,
                cartTotals
              )


              if (!result && parentRef.current) {
                parentRef.current.classList.add('shake')
                addToast({
                  id: cart?._id?.toString() || 'addproducttocart',
                  description: translate('shop.notInStockMessage'),
                  type: 'info',
                })
                window.setTimeout(function () {
                  if (parentRef.current) {
                    parentRef.current.classList.remove('shake')
                  }
                }, 820)
              }
            })
          }}
        >
          <>
            <span>
              <img
                width={24}
                height={24}
                src={`/svgs/add-to-basket.svg`}
                className={styles.quantityAddToCart}
                alt={translate('shop.addToCart')}
              />
            </span>
          </>
        </button>
      </div>
    )
  }

  const removeItem = async () => {
    if (props.reload) {
      const wish = props.wishList
      const updatedList: string[] = []
      props?.wishList?.products.forEach((item) => {
        if (item !== props.product.productId) {
          updatedList.push(item)
        }
      })
      if (wish) {
        wish.products = updatedList

        const body = {
          lang: lang,
          products: updatedList,
          _id: wish._id,
        }

        await RequestHelper.catalog.post(`wishlists/saveByERPIds`, body)

        addToast({
          id: wish._id?.toString() || 'wishlistssaveByERPIds',
          description: translate('shop.whishlist.favoriteRemoved'),
          type: 'success',
        })
        props.reload()
      }
    }
  }

  const getColor = () => {
    if (
      props.product.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return '#F6DD4B'
    }
    if (
      customerInfo?.cards.length > 0 &&
      props.product.loyaltyDiscount &&
      !props.product.loyaltyDiscount?.isClub &&
      !props.product.loyaltyDiscount?.isSpecial
    ) {
      return '#347745'
    }
    if (props.product.discount) {
      return '#EA5C29'
    }
    // if (product.loyaltyDiscount?.isSpecial && isCustomer) {
    //   return '#A4D442';
    // }
  }

  const generatePrice = (product) => {
    if (
      product.loyaltyDiscount?.isClub &&
      customerInfo?.clubMembership?.current?.isMember
    ) {
      return product.loyaltyDiscount.price
    }
    if (
      product.loyaltyDiscount &&
      customerInfo?.cards.length > 0 &&
      !product.loyaltyDiscount.isSpecial &&
      !product.loyaltyDiscount.isClub
    ) {
      return product.loyaltyDiscount.price
    }
    if (product.discount) {
      return product.discount?.price
    }
    return product.stock?.price || 0
  }

  const wrapper = classNames({
    [styles.quantityWrapper]: true,
    noselect: true,
  })

  return (
    <div>
      <div
        className={styles.container}
        onMouseEnter={() => setShowQuantity(true)}
        onMouseLeave={() => setShowQuantity(false)}
      >
        <a href={isMobile ? `${location.origin}/${lang}/product/${props.product.nameSlug}` : '#'}>
          <figure className={styles.image} onClick={navigateToDetails}>
            {props.product?.images?.length ? (
              <img
                src={`${getProductPrimaryImageUrl(props.product)}`}
                alt={props.product?.title}
                onError={getBrokenImage}
                width={200}
                height={200}
              />
            ) : (
              <ProductCardImagePlaceholder />
            )}
          </figure>
        </a>
        {isInStock() ? (
          <>
            {generatedText?.text ? (
              <Label
                color={generatedText?.color || getColor()}
                type="bubble"
                text={generatedText?.text}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          <Label
            color="#99AEBB"
            type="bubble"
            size={'stretch'}
            text={translate('ecommerce.notInStock')}
          />
        )}
        <div className={styles.productInfo}>
          <div className={styles.productInfo__unit}>
            <span>
              {props.product.unitType &&
                props.product.unitValue &&
                `${props.product.unitValue || ''} ${props.product.unitType || ''
                }`}
            </span>
            &nbsp;
          </div>
          <Link href={isMobile ? `${location.origin}/${lang}/product/${props.product.nameSlug}` : '#'}>
            <a className={styles.title} onClick={navigateToDetails}>
              <span title={props.product?.title}>{props.product?.title}</span>
            </a>

          </Link>
        </div>

        <div className={styles.productInfo_priceDetails}>
          <Link href={isMobile ? `${location.origin}/${lang}/product/${props.product.nameSlug}` : '#'}>
            <a className={styles.productInfo__price}>
              <span>{generatePrice(props.product).toFixed(2)}</span>
              &nbsp;
              <Lari />{' '}
              {process.env.NEXT_PUBLIC_SHOW_STOCKS == 'show' ? (
                <>
                  {props.product.stock?.stock} -{' '}
                  {props.product.stockReservationAmount}
                </>
              ) : (
                ''
              )}
            </a>
          </Link>
          {props.product.discount?.price ||
            (props.product.loyaltyDiscount?.isClub &&
              customerInfo?.clubMembership?.current?.isMember) ||
            (customerInfo?.cards.length > 0 &&
              props.product.loyaltyDiscount &&
              !props.product.loyaltyDiscount?.isClub &&
              !props.product.loyaltyDiscount?.isSpecial) ? (
            <Link href={isMobile ? `${location.origin}/${lang}/product/${props.product.nameSlug}` : '#'}>
              <a className={styles.productInfo__price_discount}>
                <span>
                  {props.product.stock?.price ? props.product.stock.price : 0}
                </span>
                &nbsp;
                <Lari />
              </a>
            </Link>
          ) : null}

          <div className={wrapper}>
            {isInStock() ? (
              <>
                {cartTotals.productToCountMap[props.product.productId] > 0 ? (
                  <>
                    {showQuantity && !isMobile ? (
                      <Quantity
                        shake={parentRef.current}
                        productId={props.product.productId}
                        id={props.product._id}
                        title={props.product.title}
                        countStep={props.product.countStep}
                        price={props.product.discount ? props.product.discount.price : props.product.stock.price}
                        category={props.product.categoryId}
                      />
                    ) : (
                      <Quantity
                        shake={parentRef.current}
                        productId={props.product.productId}
                        id={props.product._id}
                        title={props.product.title}
                        countStep={props.product.countStep}
                        price={props.product.discount ? props.product.discount.price : props.product.stock.price}
                        category={props.product.categoryId}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {isMobile ? (
                      addToCart()
                    ) : (
                      <>{showQuantity ? addToCart() : ''}</>
                    )}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
        {props.hasDelete && (
          <div className={styles.deleteWrapper}>
            <Button
              type="additional"
              text={translate('shop.whoshlist.removeItem')}
              iconLeft={<FiTrash size={20} />}
              onClick={removeItem}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductCard
