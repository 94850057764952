import { Modal } from '@components/ui'
import { UseTranslate } from 'hooks/Translate'
import { FC, useContext, useEffect } from 'react'
import RateCourier from './RateCourier'
import UserContext from 'contexts/UserContext'
import SocketService from 'services/socketService'
import { UserProfile } from '@auth0/nextjs-auth0'
import RateOrder from './RateOrder'
import { useRouter } from 'next/router'
import AppContext from 'contexts/AppContext'

const RateOrderModal: FC = () => {
  const [translate] = UseTranslate()
  const router = useRouter()

  const {
    rateOrderModalVisible,
    setRateOrderModalVisible,
    rateOrderTotalAmount,
    user,
    rateScreen,
    rateOrderDeliveryType,
    setRateScreen,
    rateFromDetails,
    setCourierTipVisible,
    loadUnreadOrders,
    markOrderAsRead,
    setPaymentModalVisibility,
    setOrderIsAlreadyReviewed,
  } = useContext(UserContext)

  const { selectedLanguage, orderRateIsActive } = useContext(AppContext)

  useEffect(() => {
    if (user && orderRateIsActive) {
      const u = user as UserProfile
      SocketService.init(u?.sub)
      SocketService.listen('orderStatus', (prop) => {
        if (+prop.orderStatus === 5) {
          if (window.location.pathname.includes('checkout')) {
            router.push(`/${selectedLanguage.key}`)
          }
          setPaymentModalVisibility(false)
          markOrderAsRead(prop.orderId)
          setCourierTipVisible(true)
          setOrderIsAlreadyReviewed(false)
          if (prop.deliveryType === 'pickup') {
            setRateScreen('RateOrder')
          }
          setRateOrderModalVisible(prop.orderId, true, prop.deliveryType, false, prop.totalAmount)
        }
      });
      loadUnreadOrders()
    }
  }, [user, orderRateIsActive])

  const onModalClose = () => {
    setRateOrderModalVisible(null, false, rateOrderDeliveryType, false)

    if (rateOrderDeliveryType == 'delivery') {
      setRateScreen('RateCourier')
      setCourierTipVisible(false)
    }

    if (rateFromDetails) {
      router.reload()
    }
  }

  return (
    <Modal
      visible={rateOrderModalVisible}
      onClose={onModalClose}
      title={
        rateScreen === 'RateCourier'
          ? translate('ecommerce.rateOrder.rateCourier')
          : translate('ecommerce.rateOrder.rateOrder')
      }
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      content={
        rateScreen === 'RateCourier' && rateOrderDeliveryType == 'delivery' ? (
          <RateCourier />
        ) : (
          <RateOrder />
        )
      }
    />
  )
}

export default RateOrderModal
