import { UseTranslate } from 'hooks/Translate'
import { FC, useContext, useEffect, useState } from 'react'
import styles from './RateOrder.module.scss'
import { Button, TextArea } from '@components/ui'
import OrderActions from './OrderActions'
import UserContext from 'contexts/UserContext'
import { useRouter } from 'next/router'
import { logBrazeRateOrder } from '@utils/BrazeDefaultUserDetails'
import { useUser } from '@auth0/nextjs-auth0'

const RateOrder: FC = () => {
  const [translate] = UseTranslate()
  const {
    rateOrderId,
    rateOrderDeliveryType,
    rateOrderTotalAmount,
    rateOrder,
    setRateOrderModalVisible,
    setRateScreen,
    rateFromDetails,
    markOrderReviewRead
  } = useContext(UserContext)
  const router = useRouter()
  const { user } = useUser()
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState(null)

  const onRateOrder = async () => {
    setLoading(true)
    if (!loading) {
      const rateResponse = await rateOrder(rateOrderId, {
        order: {
          comment: formValues.comment,
          value: formValues.value,
        },
      })
      setLoading(false)
      if (rateResponse) {
        logBrazeRateOrder(user.userId as string, rateOrderTotalAmount, formValues.value)
        setRateOrderModalVisible(null, false, rateOrderDeliveryType)
        if (rateOrderDeliveryType == 'delivery') {
          setRateScreen('RateCourier')
        }
        if (rateFromDetails) {
          router.reload()
        }
      }
    }
  }

  const cancel = () => {
    setRateOrderModalVisible(null, false, rateOrderDeliveryType)
  }

  useEffect(() => {
    if (rateOrderDeliveryType == 'pickup') {
      markOrderReviewRead(rateOrderId)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={`/images/bag.png`} />
      </div>
      <OrderActions
        onChange={(value) =>
          setFormValues({
            ...formValues,
            value,
          })
        }
      />
      <div className={styles.inputContainer}>
        <TextArea
          id="comment"
          name="comment"
          onChange={(value) =>
            setFormValues({
              ...formValues,
              comment: value,
            })
          }
          placeholder={translate('ecommerce.rateOrder.comment')}
        />
      </div>
      <div className={styles.footer}>
        <Button
          type="secondary"
          text={translate('ecommerce.rateOrder.cancel')}
          size={'full'}
          showBorder={true}
          style={{ marginLeft: 20, width: '30%' }}
          onClick={cancel}
        />
        <Button
          disabled={formValues && !formValues.value}
          size={'full'}
          text={translate('ecommerce.rateOrder.rate')}
          style={{ marginLeft: 10, width: '60%' }}
          loading={loading}
          onClick={onRateOrder}
        />
      </div>
    </div>
  )
}

export default RateOrder
